CODE_OF_CONDUCT_API = '/users/codeofconduct?uid=';
LOGIN_REDIRECT_API = '/api/login/redirect?uid=';
GET_USER_QUESTIONS = '/api/getuserquestions?uid=';
REGISTER_DEVICE_API = '/api/register/device' + '?auth=true&uid=';
OBJECT_RESOURCE_FOLDER = Platform.getResourceFolder();
OBJECT_DATA_FOLDER = Platform.getDataFolder();
OBJECT_APPLICATION_FOLDER = Platform.getApplicationFolder();

function ContentUtilsService(){};


ContentUtilsService.prototype.downloadImage = function(nid, filename, preset, filedownloadsync )
{

    if (filename && filename.length) {
        if(typeof CATEGORY_PRESET != "undefined" && CATEGORY_PRESET != ""){
            var preset = CATEGORY_PRESET;
        }
        else if(typeof preset == "undefined"){
            preset = OBJECT_PRESET;
        }
        var imagedownloadurl = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + nid + '&preset=' + preset + '&uid=' + OBJECT_USER_ID;
        var folderpath = filename.split("/").slice(3).join("/");
        folderpath = folderpath.substring(0, folderpath.indexOf('?'));
        var filenamesplit = folderpath.split("/");
        var laststr = filenamesplit[filenamesplit.length - 1];
        var decodedfilename = decodeURIComponent(laststr);
        folderpath = folderpath.replace(laststr,decodedfilename);
        var filephypath = "/" + folderpath;
        var imagepath = OBJECT_DATA_FOLDER + filephypath;
        try{
            var imagefileAvailable = Platform.isFilePresentInDocRoot(filephypath);
            if(imagefileAvailable != true){
                var paramsJson = {'objectid':nid,'objecttype':'content',
                'serverurl':imagedownloadurl,'localurl':imagepath, 'message':'',
                'sync':FILE_DOWNLOAD_ASYNC,'youtubeid':'','type':'none','format':''};
                POSTER_PATHS.push(paramsJson);
            }
        }
        catch(e){
            console.log("function : downloadImage"
                + "url" + imagedownloadurl + " message" + e.message);
        }
    }
};

ContentUtilsService.prototype.deleteMetaImage = function(type, nid){
    try{
        var obj = DataManager.getObjectProperty(type,nid,"content");
        if(!$.isEmptyObject(obj)){
            if (obj.thumbnail_filename && obj.thumbnail_filename) {
                var folderpath = obj.thumbnail_filename.split("/").slice(3).join("/");
                var filepath = folderpath.substring(0, folderpath.indexOf('?'));
                var fileAvailable = Platform.isFilePresentInDocRoot(filepath);
                if(fileAvailable == true && filepath != OBJECT_DATA_FOLDER && filepath != OBJECT_RESOURCE_FOLDER){
                    Platform.deleteFileFromDocRoot(filepath);
                }
            }
        }
    }
    catch(e){
        console.log("function : deleteMetaImage"
            + " message" + e.message);
    }
};

ContentUtilsService.prototype.getPosterPath = function(nid, filename, preset)
{
    try{
        if(typeof preset == "undefined"){
            preset = OBJECT_PRESET;
        }
        if(EXEC_MODE == "test"){
            var theUrl = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + nid + '&preset=' + preset + '&uid=' + OBJECT_USER_ID;
            return theUrl;
        }
        else
        {
            var folderpath = filename.split("/").slice(3).join("/");
            folderpath = folderpath.substring(0, folderpath.indexOf('?'));
            var filenamesplit = folderpath.split("/");
            var laststr = filenamesplit[filenamesplit.length - 1];
            var decodedfilename = decodeURIComponent(laststr);
            folderpath = folderpath.replace(laststr,decodedfilename);
            var filephypath = "/" + folderpath;
            var filepath = Platform.getLocalServerPath() + filephypath;
            var fileAvailable = Platform.isFilePresentInDocRoot(filephypath);


            if( fileAvailable == false)
            {

                try{
                    this.downloadImage(nid, filename, preset, BOOTSTRAP_IMAGES_SYNC);
                }
                catch(e){
                    console.log(" message" + e.message);
                }
                if(BOOTSTRAP_IMAGES_SYNC == 'true' || (BOOTSTRAP_IMAGES_SYNC == 'false' && Platform.getNetworkState() == false)){
                    filepath = "/platform/images/noimageavailable.c3a74d2f.jpg";
                }else if(typeof CATEGORY_PRESET != "undefined" && CATEGORY_PRESET != ""){
                    filepath = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + nid + '&preset=' + preset + '&uid=' + OBJECT_USER_ID;
                }
                else if(BOOTSTRAP_IMAGES_SYNC == 'false' && Platform.getNetworkState() == true)
                {
                    filepath = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + nid + '&preset=' + preset + '&uid=' + OBJECT_USER_ID;
                }
            }
        }

        return filepath;
    }
    catch(e){
        console.log( "function : getPosterPath"
            + "\n message:" + e.message);
    }

};

ContentUtilsService.prototype.downloadResourcePosterImage = function(resourceid, preset, filedownloadsync, thumbnail_id, filename, filetype, type)
{
    try{
        var resource = {};
        if (type) {
            resource = $.parseJSON(Platform.getObjectProperty(type,resourceid,"content"));
        } else {
            resource = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO,resourceid,"content"));
        }
        var thumbnail_id = resource.thumbnail_id;
        var filename = resource.thumbnail_filename;
        var imagedownloadurl = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + resourceid + '&preset=' + RESOURCE_PRESET + '&uid=' + OBJECT_USER_ID;
        if(thumbnail_id == null  || thumbnail_id == "null"){
            var filephypath = "/video/" + resourceid + "/" + POSTER_IMAGE_NAME + ".jpg"
            var imagepath = OBJECT_RESOURCE_FOLDER + filephypath;
        }
        else
        {
            filephypath = "/video/" + resourceid + "/" + filename
            imagepath = OBJECT_RESOURCE_FOLDER + filephypath;
        }
        var resourceImagefileAvailable = Platform.isFilePresentInDocRoot(filephypath);
        if(!resourceImagefileAvailable){
            if(typeof filetype == "undefined" || filetype == "" || filetype.toLowerCase() != 'pdf'){
                var paramsJson = {'objectid':resourceid,'objecttype':'resource',
                'serverurl':imagedownloadurl,'localurl':imagepath, 'message':'',
                'sync':filedownloadsync, 'youtubeid':'','format':'','type':'poster'};
                Platform.downloadFile(JSON.stringify(paramsJson));
            }
        }
    }
    catch(e){
        console.log( "function : downloadResourcePosterImage"
            + "\n message:" + e.message);
    }

};

//function to download resource
ContentUtilsService.prototype.fileDownload = function(resourcedata,preference,download_complete_url,downloadmessage,courseid,type,categoryid,container_id){
    try {
        var cat_tag = CONTROLLER.getVariable("category_tagname")
        var resource = CONTROLLER.getService("Resource");
        var quizService = CONTROLLER.getService("Quiz");
        var downloadType = "";
        var contentType = "";
        if (resourcedata == "error") {
            console.log("function : fileDownload"
                + "\n message : Unable to download file");
            return;
        }
        var posterimageid = resourcedata.nid;
        var resourcedata_youtube_id = resourcedata.youtube_id;
        var THIS = this;
        if (resourcedata.type == PRACTICE || resourcedata.type == CERTIFICATE || resourcedata.type == OBJECT_QUIZ || resourcedata.type == RESOURCE_TYPE_ASSESSMENT || resourcedata.type == RESOURCE_TYPE_FLASHCARD) {
            downloadType = OBJECT_QUIZ;
            contentType = OBJECT_QUIZ;
            if (resourcedata.type == RESOURCE_TYPE_ASSESSMENT) {
                resourcedata = quizService.getQuiz(resourcedata.url);
            }
            resourcedata_youtube_id = resourcedata.changed;
            if (resourcedata.type == RESOURCE_TYPE_FLASHCARD && ((typeof cat_tag == "undefined") || (typeof cat_tag != "undefined" && cat_tag == ""))) {
                resourcedata_youtube_id = resourcedata.link_changed;
            }
        } else if (resourcedata.type == "Video") {
            downloadType = "video";
            if (type == OBJECT_SUPPORT_DOCS || CONTROLLER.activeViewName == "SupportDocsList" ) {
                contentType = OBJECT_SUPPORT_DOCS;
            } else {
                contentType = OBJECT_VIDEO;
            }
            resource.downloadAllQuizBreaks(resourcedata);
        } else if (resourcedata.type == "Document") {
            downloadType = "ebook";
            if (type == OBJECT_SUPPORT_DOCS || CONTROLLER.activeViewName == "SupportDocsList" ) {
                contentType = OBJECT_SUPPORT_DOCS;
            } else if (type == OBJECT_VIDEO) {
                contentType = OBJECT_VIDEO;
            } else {
                contentType = OBJECT_BOOK;
            }
        } else if (resourcedata.type == RESOURCE_TYPE_HTML || resourcedata.type == RESOURCE_TYPE_ASSIGNMENT || resourcedata.type.toLowerCase().indexOf('scorm') >= 0 || resourcedata.type.toLowerCase() == "html") {
            if (type == OBJECT_SUPPORT_DOCS || CONTROLLER.activeViewName == "SupportDocsList" ) {
                contentType = OBJECT_SUPPORT_DOCS;
            } else if (type == OBJECT_VIDEO) {
                contentType = OBJECT_VIDEO;
            }
            downloadType = ISAVAILABLE_HTML_TYPE;
        }
        else if(resourcedata.type == RESOURCE_TYPE_WEBINAR){
            var webinarRecordObject = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR,resourcedata.webinar_record_id,"content"));
            resourcedata_youtube_id = webinarRecordObject.youtube_id;
            downloadType = "video";
            if (type == OBJECT_SUPPORT_DOCS || CONTROLLER.activeViewName == "SupportDocsList" ) {
                contentType = OBJECT_SUPPORT_DOCS;
            } else {
                contentType = OBJECT_VIDEO;
            }
        }
        var isAvail = this.isFileAvailable(resourcedata.nid, resourcedata_youtube_id, courseid, downloadType);

        if (isAvail.filestatus != "In Progress") {

            if (downloadmessage != "") {
                var downloadStartedMessage = "";
                if (downloadType == "ebook") {
                    downloadStartedMessage = t("Download_InProgress",true);
                } else if (downloadType == OBJECT_QUIZ) {
                    posterimageid = resourcedata.contentid ? resourcedata.contentid : posterimageid;
                    downloadStartedMessage = t("Quiz_Download_InProgress",true);
                    if(resourcedata.type == RESOURCE_TYPE_FLASHCARD){
                        downloadStartedMessage = Flashcard_Download_InProgress;
                        container_id = resourcedata.url;
                        contentType = ''
                        if(typeof cat_tag != "undefined" && cat_tag == "flash_card"){
                            container_id = resourcedata.nid;
                            contentType = OBJECT_QUIZ
                        }
                    } 
                } else {
                    downloadStartedMessage = t("VIDEO_DOWNLOAD_INPROGRESS",true);
                }
                if (typeof TRIAL_DOWNLOAD_MESSAGE != "undefined" && TRIAL_USER == "true") {
                    downloadStartedMessage = downloadStartedMessage + ". " + TRIAL_DOWNLOAD_MESSAGE;
                }
                Platform.showMessage(downloadStartedMessage);
            }

            resource.downloadResourceAsync(resourcedata.nid, resourcedata_youtube_id, "created", downloadmessage, DOWNLOAD_EXPIRY, download_complete_url, courseid, contentType, categoryid, container_id);
            this.downloadResourcePosterImage(posterimageid, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC, resourcedata.thumbnail_id, resourcedata.thumbnail_filename, DOWNLOAD_EXPIRY, type);
            this.showProgressBar(resourcedata.nid);
            this.showInProgressMessage(resourcedata);
        }
    }
    catch (e) {
        console.log("function : fileDownload"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.isFileAvailable = function(nid,youtubeid,courseid,type,catid){
    try{
       var contentInToc = "";
       var contentAvailable = "";
       var tocService = CONTROLLER.getService("Toc");
       if(USER_PRODUCT_TYPE == "online" || EXEC_MODE == "online"){
           return {"filestatus":"Not Available"};
       }
       else if(USER_PRODUCT_TYPE == "hybrid"){
           return this.contentAvailable(nid,youtubeid,type,USER_PRODUCT_TYPE);
       }
       else if(USER_PRODUCT_TYPE == "offline"){
           if(CONTENT_IN_MULTIPLE_SDCARDS == true){
               contentInToc = this.checkContentAvailableInToc(courseid,catid);
               var sdcard = tocService.getTocSdCardInfo(courseid);
               if(contentInToc == true || sdcard === false){
                    return this.contentAvailable(nid,youtubeid,type,USER_PRODUCT_TYPE);
               }
               else{
                   return {"filestatus":"Not Available"};
               }
           }
           else{
                return this.contentAvailable(nid,youtubeid,type,USER_PRODUCT_TYPE);
           }
       }
    }
    catch(e){
       console.log( "function : isFileAvailable"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.contentAvailable = function(nid,youtubeid,type,USER_PRODUCT_TYPE){
    try{
        if (typeof CHECK_SDCARD_CONTENT != "undefined" && CHECK_SDCARD_CONTENT == "offline") {
            var content_access_type = CHECK_SDCARD_CONTENT;
        } else {
            var content_access_type = USER_PRODUCT_TYPE;
        }
        if (type == OBJECT_QUIZ || type == "quiz" || type == RESOURCE_TYPE_ASSESSMENT || type == RESOURCE_TYPE_FLASHCARD) {
            var contentAvailable = Platform.isContentAvailable(nid, youtubeid, RESOURCE_FORMAT, content_access_type, OBJECT_QUIZ);
            contentAvailable = $.parseJSON(contentAvailable);
            if(contentAvailable.filestatus == "Not Available"){
               var contentAvailable = Platform.isContentAvailable(nid, '', RESOURCE_FORMAT, content_access_type, OBJECT_QUIZ);
                contentAvailable = $.parseJSON(contentAvailable);
                 return contentAvailable;
            }else{
               return contentAvailable;
            }

        }

        var resourceObj = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO,nid,"content"));
        
        if(type == "video"){
            if(resourceObj == null){
                resourceObj = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR,nid,"content"));
            }
            if(resourceObj == null){
                resourceObj = $.parseJSON(Platform.getObjectProperty(OBJECT_SUPPORT_DOCS,nid,"content"));
            }
            if(resourceObj && resourceObj.type == RESOURCE_TYPE_WEBINAR && resourceObj.webinar_record_id){
                var webinarRecordObject = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR,resourceObj.webinar_record_id,"content"));
                if (!webinarRecordObject) {
                    webinarRecordObject = $.parseJSON(Platform.getObjectProperty(OBJECT_SUPPORT_DOCS,nid,"content"));
                }
                clipping_murl = webinarRecordObject["clipping_murl"] || null;
            }
            else{
                clipping_murl = resourceObj["clipping_murl"] || null;
            }
            if(DOWNLOAD_STREAM == "m3u8" && (typeof clipping_murl == "undefined" || (typeof clipping_murl != "undefined" && clipping_murl != "mp4" ) ) ){
                type = "m3u8";
            }
        }

        if(resourceObj && resourceObj.type == RESOURCE_TYPE_ROLEPLAY){
            type = "roleplay";
            var contentAvailable = Platform.isContentAvailable(resourceObj.roleplay_nid, youtubeid, RESOURCE_FORMAT, content_access_type, type);
            contentAvailable = $.parseJSON(contentAvailable);
            return contentAvailable;
        }

        if (type == OBJECT_QUIZ || type == "quiz" || type == RESOURCE_TYPE_ASSESSMENT || type == RESOURCE_TYPE_FLASHCARD) {
            var contentAvailable = Platform.isContentAvailable(nid, youtubeid, RESOURCE_FORMAT, content_access_type, OBJECT_QUIZ);
            contentAvailable = $.parseJSON(contentAvailable);
            if(contentAvailable.filestatus == "Not Available"){
               var contentAvailable = Platform.isContentAvailable(nid, '', RESOURCE_FORMAT, content_access_type, OBJECT_QUIZ);
                contentAvailable = $.parseJSON(contentAvailable);
                 return contentAvailable;
            }else{
               return contentAvailable;
            }

        } else {
            // checking for type document
            if(resourceObj && resourceObj.filetype == "pdf") type = "pdf";
            
            if(resourceObj && resourceObj.type == RESOURCE_TYPE_WEBINAR){
                nid = resourceObj.webinar_record_id;
            }
            
            var contentAvailable = Platform.isContentAvailable(nid, youtubeid, RESOURCE_FORMAT, content_access_type, type);
            contentAvailable = $.parseJSON(contentAvailable);
            return contentAvailable;
        }
    }
    catch(e){
       console.log( "function : contentAvailable"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.getFilePauseState = function(nid){
    try{
        var pause_state = this.getFilePauseState(nid);
        return pause_state;
    }
    catch(e){
       console.log( "function : getFilePauseState"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.getResourcePosterPath = function(resourceid,thumbnail_id,thumbnail_filename,filetype)
{
    try{
        var fileAvailable = OBJECT_RESOURCE_FOLDER;
        var objtype = Platform.getObjectType(resourceid);
        var resource = $.parseJSON(Platform.getObjectProperty(objtype,resourceid,"content"));
        var thumbnail_id = resource.thumbnail_id;
        var thumbnail_filename = resource.thumbnail_filename;
        var contentUtilsService = CONTROLLER.getService("ContentUtils");
        if(thumbnail_id == null || thumbnail_id == "null"){
            var filephypath = "video/" + resourceid + "/" + POSTER_IMAGE_NAME + ".jpg";
            var filepath = Platform.getLocalServerPath() + "/" + filephypath
        } else {
            filephypath = "video/" + resourceid + "/" + encodeURI(thumbnail_filename);
            filepath = Platform.getLocalServerPath() + "/" + filephypath;
        }
        var fileAvailable = Platform.isFilePresentInDocRoot(filephypath);
        if( fileAvailable == false)
        {
            contentUtilsService.downloadResourcePosterImage(resourceid, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC, thumbnail_id, thumbnail_filename, filetype);

            if(typeof filetype != "undefined" && filetype.toLowerCase() == 'pdf'){
                filepath = "/platform/images/document.png";
            } else {
                if(BOOTSTRAP_IMAGES_SYNC == 'true' || (BOOTSTRAP_IMAGES_SYNC == 'false' && Platform.getNetworkState() == false)){
                    filepath = "/platform/images/noimageavailable.c3a74d2f.jpg";
                }
                else if(BOOTSTRAP_IMAGES_SYNC == 'false' && Platform.getNetworkState() == true)
                {
                    filepath = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + resourceid + '&preset=' + RESOURCE_PRESET + '&uid=' + OBJECT_USER_ID;
                }
            }
        }
        return filepath;
    }
    catch(e){
        console.log( "function : getResourcePosterPath"
            + "\n message:" + e.message);
    }
};

ContentUtilsService.prototype.checkContentAvailableInToc = function(courseid,catid)
{
    try{
    var chaptersubject = "";
    var tocService = CONTROLLER.getService("Toc");
    var chaptersofsdcard = tocService.getChaptersOfSdCard();
    if(!catid){
        catid = CONTROLLER.getVariable("activecatid");
    }
    var sdcard = tocService.getTocSdCardInfo(courseid);
    if(sdcard === false){
       return true;
    }
    var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content"));
    if (chaptersofsdcard != "") {
        if(typeof chaptersofsdcard[category.title] != "undefined"){
            for (j = 0; j < chaptersofsdcard[category.title].length; j++) {
                if (chaptersofsdcard[category.title][j].nid == courseid) {
                    chaptersubject = true;
                }
            }
        }
        else{
            chaptersubject = false;
        }
    }
    if (chaptersubject == false) {
        return false;
    }
    else{
        return true;
    }
    }
    catch (e) {
        Client.closeProgressBar();
        console.log("function : checkContentAvailableInToc"
                + "\n message:" + e.message);
    }
};

ContentUtilsService.prototype.getResourcePathFromUrl = function(nid,type,courseid){
    try {
        if(type.toLowerCase() == 'pdf' || type == 'Document'){
            var format = '&format=original';
        }
        else{
            if(typeof ENABLE_DOWNLOAD_RESOLUTION_SWITCH != 'undefined' && ENABLE_DOWNLOAD_RESOLUTION_SWITCH == true){
                if(typeof DOWNLOAD_RESOURCE_FORMAT != "undefined" && DOWNLOAD_RESOURCE_FORMAT != ""){
                    format = '&format=' + DOWNLOAD_RESOURCE_FORMAT;
                }else{
                    format = '&format=' + RESOURCE_FORMAT;
                }
            }else{
                format = '&format=' + RESOURCE_FORMAT;
            }
        }
        var resource = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO + '?nid=' + nid + format + '&courseid='+courseid+'&uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED);
        resource = $.parseJSON(resource);
        var status = resource['status'];
        if (status == 200)
        {
            var resourcedata = resource['data'];
            resourcedata = $.parseJSON(resourcedata);
            var resourceurl = resourcedata[0].videourl;
            if(type.toLowerCase() == 'pdf' || type == 'Document'){
                resourceurl = resourceurl["original"];
            }
            else{
                resourceurl = resourceurl[RESOURCE_FORMAT];
            }
            return resourceurl;

        }
    }
    catch (e)
    {
        var error = "function : getResourcePathFromUrl\n\
                    url :" + OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO +
                    '?nid=' + nid + format + '&uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED
        console.log(error);
    };
};

ContentUtilsService.prototype.getResourceStatus = function(nid,type,courseid,download,cat_tid, resObj, params){
    try {
        var format = "";
        var downloadparam = "";
        var linkedObjectParam = "";
        if(typeof type != 'undefined' &&  (type.toLowerCase() == 'pdf' || type == 'Document')){
            format = '&format=original';
            stream = "";
        }
        else{
            var videoFormats = RESOURCE_ONLINE_FORMAT;
            if (resObj && resObj["format-type"]) {
                videoFormats = resObj["format-type"];
            }
            format = '&format=' + videoFormats;
            stream = '&stream=' + STREAM_TYPE;
        }
        if(typeof download != "undefined" && download == "true"){
            downloadparam = "&download=true";
        }
        else{
            downloadparam = "";
        }
        if (typeof params != "undefined" && params['isLinkedObject'] == true) {
            linkedObjectParam = "&item_nid=" + params.linkedobject_item;
        }
        else{
            linkedObjectParam = "";
        }

        var resource = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO + '?nid=' + nid + format+stream+downloadparam+'&uid=' + OBJECT_USER_ID + "&courseid=" + courseid + "&cat_id=" + cat_tid + linkedObjectParam);
        resource = $.parseJSON(resource);
        var status = resource['status'];
        if(status == 200){
            var data = $.parseJSON(resource['data']);
            var resourceurl = data[0]['videourl'];
            CONTROLLER.setVariable("activeresourcepath",resourceurl);
            CONTROLLER.setVariable("showpopup_for_unsubscribed_video",'');
        }
        return status;
    }
    catch (e)
    {
        var error = "function : getResourceStatus\n\
                    url :" + OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO +
                    '?nid=' + nid + format + '&uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED
        console.log(error);
    };
};

ContentUtilsService.prototype.sortByRank = function(json){
    try{
        json.sort(function(a, b) {
            return ((a.rank < b.rank) ? -1 : ((a.rank > b.rank) ? 1 : 0));
        });
    }
    catch(e){
        console.log( "function : sortByRank"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.checkDownloadStatus = function(resource,type,courseid)
{
    try{
        var cat_tag = CONTROLLER.getVariable("category_tagname")
        var type;
        var nid = "";
        var quiz = "";
        var listallquizzesview = CONTROLLER.getVariable("listallquizzes");
        var upcomingQuizview = CONTROLLER.getVariable("upcomingQuizzes");
        var quizService = CONTROLLER.getService("Quiz");
        var resourcesjsonarray = [];
        var resourcesjson = "";
        for(var i=0;i<resource.length;i++){
           var youtubeid;
           var filetype = resource[i].filetype;
            if (resource[i].type == "Document" && resource[i].source == "Upload" && resource[i].download == "1" && typeof GALLERY_DOWNLOAD_TYPES != "undefined" && GALLERY_DOWNLOAD_TYPES.indexOf(filetype) > -1) {
                this.showDownloadMessage(resource[i].nid);
                continue;
            }
            // if resource type epub hide download button
            if(resource[i].type == RESOURCE_TYPE_EPUB) continue;

            if( (resource[i].source !=  RESOURCE_TYPE_LINK
                && (resource[i].type != "Document" || filetype.toLowerCase() == "pdf"))
                || (resource[i].source == RESOURCE_TYPE_LINK
                &&((typeof RESOURCE_TYPE_HTML != "undefined"
                && resource[i].type == RESOURCE_TYPE_HTML
                && ((resource[i].url).indexOf(OBJECT_SERVER_DOMAIN) >= 0))
                || resource[i].type == RESOURCE_TYPE_ASSESSMENT)
                || (resource[i].type == RESOURCE_TYPE_WEBINAR
                    && typeof resource[i].webinar_record_id != "undefined" && resource[i].webinar_record_id))
                ||    resource[i].type == RESOURCE_TYPE_FLASHCARD) {
                    if(resource[i].type == RESOURCE_TYPE_FLASHCARD &&
                         typeof cat_tag != "undefined" && cat_tag == 'flash_card'){
                            var quiz = $.parseJSON(Platform.getObjectProperty(OBJECT_QUIZ,resource[i].flashcard_collection_nid,"content"));
                            if(!quiz){
                                continue;
                            }
                           else{
                            if(typeof quiz == "undefined" || quiz == "" || quiz == INTERNET_ERROR || quiz.type == "feedback"){
                                continue;
                            }
                            nid = quiz.nid;
                            type = OBJECT_QUIZ;
                            if (resource[i].type == RESOURCE_TYPE_FLASHCARD) {
                                type = RESOURCE_TYPE_FLASHCARD;
                            }
                            youtubeid = quiz.changed;
                           }
                         }
                else if(resource[i].type == RESOURCE_TYPE_ASSESSMENT || resource[i].type == RESOURCE_TYPE_FLASHCARD){
                    quiz = quizService.getQuiz(resource[i].url);
                    if(typeof quiz == "undefined" || quiz == "" || quiz == INTERNET_ERROR || quiz.type == "feedback"){
                        continue;
                    }
                    nid = quiz.nid;
                    type = OBJECT_QUIZ;
                    if (resource[i].type == RESOURCE_TYPE_FLASHCARD) {
                        type = RESOURCE_TYPE_FLASHCARD;
                    }
                    youtubeid = quiz.changed;
                } else if(resource[i].type == RESOURCE_TYPE_WEBINAR){
                    var webinarRecordObject = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR,resource[i].webinar_record_id,"content"));
                    if(!webinarRecordObject){
                        continue;
                    }
                    youtubeid = webinarRecordObject.youtube_id;
                    type = webinarRecordObject.type;
                    nid = webinarRecordObject.nid;
                }else if((typeof listallquizzesview !== 'undefined' && listallquizzesview === "true")
                        || (typeof upcomingQuizview !== 'undefined' && upcomingQuizview === "true")
                        || (resource[i].type == "certificate" || resource[i].type == "practice") ){
                    quiz = quizService.getQuiz(resource[i].nid);
                    if(typeof quiz == "undefined" || quiz == ""){
                        continue;
                    }
                    nid = quiz.nid;
                    type = OBJECT_QUIZ;
                    youtubeid = quiz.changed;
                }
                else{
                    youtubeid = resource[i].youtube_id;
                    type = resource[i].type;
                    nid = resource[i].nid;
                }
                resourcesjson = {"nid":nid,"type":type,"youtubeid":youtubeid,"resource_id":resource[i].nid};
                resourcesjsonarray.push(resourcesjson);
            }
            if(resource[i].source ==  RESOURCE_TYPE_LINK && resource[i].type == RESOURCE_TYPE_ROLEPLAY){
                youtubeid = resource[i].youtube_id;
                type = resource[i].type;
                nid = resource[i].roleplay_nid;
                resourcesjson = {"nid":nid,"type":type,"youtubeid":youtubeid,"resource_id":resource[i].nid};
                resourcesjsonarray.push(resourcesjson);
            }
        }
        var THIS = this;
        this.checkMultipleResourceDownloadStatus(resourcesjsonarray, function(status){
            for(var i=0;i<status.length;i++){
                var index = findIndexByKeyValue(resourcesjsonarray,'nid',status[i].nid);
                if(status[i]["status"] == 0)
                {
                    THIS.showDownloadMessage(resourcesjsonarray[index].resource_id);
                }
                else if(status[i]["status"] == 1)
                {
                    THIS.showRemoveMessage(resourcesjsonarray[index].resource_id);
                }
                else if(status[i]["status"] == 2)
                {
                    var resource_index = findIndexByKeyValue(resource,'nid',resourcesjsonarray[index].resource_id);
                    THIS.showInProgressMessage(resource[resource_index]);
                }
                else if(status[i]["status"] == 3){
                    THIS.showNewFileAvailable(resourcesjsonarray[index].resource_id);
	            }
				else {
	                THIS.hideDownloadMessage(resourcesjsonarray[index].resource_id);
	            }
			}
        });
    }
    catch(e){
       console.log( "function : checkDownloadStatus"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.checkResourceDownloadStatus = function(nid,type,courseid,youtubeid) {
    if(type==RESOURCE_TYPE_ASSESSMENT || type == OBJECT_QUIZ || type == RESOURCE_TYPE_FLASHCARD) {
        youtubeid = youtubeid;
    } else {
        var resource = $.parseJSON(Platform.getObjectProperty(type, nid, "content"));
        var youtubeid = resource.youtube_id;

        if (resource.type == "Document") {
            type = "ebook";
        }
        else if (resource.type == RESOURCE_TYPE_ASSESSMENT || resource.type == RESOURCE_TYPE_FLASHCARD) {
            type = 'quiz';
            if (resource.type == RESOURCE_TYPE_FLASHCARD) {
                nid = resource.url;
            }
        }else if(resource.type != null && (resource.type == RESOURCE_TYPE_HTML || resource.type == RESOURCE_TYPE_ASSIGNMENT || resource.type.toLowerCase().indexOf('scorm') >= 0 || resource.type.toLowerCase() == "html")){
           type = ISAVAILABLE_HTML_TYPE;
        }
        else if(resource.type == RESOURCE_TYPE_ROLEPLAY){
            type = 'roleplay';
        }
        else {
            type = "video";
        }
    }

    var downloadStatus = this.contentAvailable(nid,youtubeid,type,USER_PRODUCT_TYPE);

    if(downloadStatus.filestatus == "In Progress"){
        return 2; //Download in progress and part file exists
    }
    else if(downloadStatus.filestatus == "Available" && downloadStatus.version == "new")
    {
        return 1; //Download complete and file exists or not put for download
    }
    else if(downloadStatus.filestatus == "Not Available"){
        return 0; //File is not available and not put for download
    }
    else if(downloadStatus.filestatus == "Available" && downloadStatus.version == "old"){
        return 3; //old file exists and new file has not been put for download
    }
};

ContentUtilsService.prototype.checkMultipleResourceDownloadStatus = function(jsonarr, callback) {
try {
    var type = "";
    var nid = "";
    var multiplejsonarray = [];
    var statusjson = {};
    for (var i in jsonarr) {
        type = jsonarr[i].type;
        nid = jsonarr[i].nid;
        var youtubeid = jsonarr[i].youtubeid;

        if (jsonarr[i].type == "Document") {
            type = "ebook";
        } else if (jsonarr[i].type == RESOURCE_TYPE_HTML || jsonarr[i].type == RESOURCE_TYPE_ASSIGNMENT || jsonarr[i].type.toLowerCase().indexOf('scorm') >= 0 || jsonarr[i].type.toLowerCase() == "html") {
            type = ISAVAILABLE_HTML_TYPE;
        } else if (type == RESOURCE_TYPE_ASSESSMENT || type == OBJECT_QUIZ) {
            type = 'quiz';
        }
        else if(jsonarr[i].type == RESOURCE_TYPE_ROLEPLAY){
            type = 'roleplay';
        } 
        else if(jsonarr[i].type == RESOURCE_TYPE_FLASHCARD){
            type = OBJECT_QUIZ;
        } 
        else {
            type = "video";
        }
        if (typeof CHECK_SDCARD_CONTENT != "undefined" && CHECK_SDCARD_CONTENT == "offline") {
            var content_access_type = CHECK_SDCARD_CONTENT;
        } else {
            var content_access_type = USER_PRODUCT_TYPE;
        }

        if(type == 'video'){
            var objType;
            objType = Platform.getObjectType(nid);
            var resourceObj = $.parseJSON(Platform.getObjectProperty(objType, nid, "content"));
            if(resourceObj == null){
                resourceObj = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR, nid, "content"));
            }
            clipping_murl = resourceObj["clipping_murl"] || null;
            if (type == "video") {
                if (DOWNLOAD_STREAM == "m3u8" && (typeof clipping_murl == "undefined" || (typeof clipping_murl != "undefined" && clipping_murl != "mp4"))) {
                    type = "m3u8";
                }
            }
        }

        statusjson = {
            "nid": nid,
            "youtubeid": youtubeid,
            "format": RESOURCE_FORMAT.toString(),
            "usertype": content_access_type,
            "filetype": type
        };
        multiplejsonarray.push(statusjson);
    }

    checkMultipleResourceDownloadStatusComplete = function (downloadStatus) {
        for (var i in downloadStatus) {
            if (downloadStatus[i].filestatus == "In Progress") {
                downloadStatus[i]["status"] = 2; // Download in progress and part file exists
            } else if (downloadStatus[i].filestatus == "Available" && downloadStatus[i].version == "new") {
                downloadStatus[i]["status"] = 1; // Download complete and file exists or not put for download
            } else if (downloadStatus[i].filestatus == "Not Available") {
                downloadStatus[i]["status"] = 0; // File is not available and not put for download
            } else if (downloadStatus[i].filestatus == "Available" && downloadStatus[i].version == "old") {
                downloadStatus[i]["status"] = 3; // old file exists and new file has not been put for download
            }
        }
        return callback(downloadStatus);
    }
    var content = JSON.stringify([multiplejsonarray]);
    var str = JSON.stringify(content);
    str = str.substring(1, str.length - 1);
    makeAsyncTaskCall("", str, 'isMultipleContentAvailable', 'internalapi', "false", 'checkMultipleResourceDownloadStatusComplete', 'Platform');
} catch (e) {
    console.log("Function checkMultipleResourceDownloadStatus :- " + e.message);
}

};

ContentUtilsService.prototype.showDownloadMessage = function(nid)
{
    try{
        var index = findIndexByKeyValue(DOWNLOAD_FILES_IN_PROGRESS,'nid',nid);
        if(index != null){
           DOWNLOAD_FILES_IN_PROGRESS.splice(index,1);
        }
        if ($('.download_button[nid='+nid+']').hasClass("delete")) {
            $('.download_button[nid='+nid+']').removeClass("delete");
        }
        $('.download_button[nid='+nid+']').removeAttr("style");
        $('.download_button[nid='+nid+']').addClass('download');

        //Adaptive Content download
        if($('#adpt_download_'+nid).hasClass("adpt_delete")){
            $('#adpt_download_'+nid).removeClass("adpt_delete");
        }
        $('#adpt_download_'+nid).addClass("adpt_download");
    }
    catch(e){
       console.log( "function : showDownloadMessage"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.showRemoveMessage = function(nid)
{
    try{
        if ($('.download_button[nid='+nid+']').hasClass("download")) {
            $('.download_button[nid='+nid+']').removeClass("download");
        }
        if ($('.download_button[nid='+nid+']').hasClass("inprogress")) {
            $('.download_button[nid='+nid+']').removeClass("inprogress");
        }
        $('.download_button[nid='+nid+']').addClass("delete");

        if (ENABLE_NEW_UI) {
            $('.download_button[nid=' + nid + ']').css({ 'background': "url('/platform/images/thumb-dl_new.2ddc81f6.png')" });
        } else {
            $('.download_button[nid=' + nid + ']').css({ 'background': "url('/platform/images/thumb-dl.0d21b28a.png')" });
        }

        //Adaptive Content delete
        if($('#adpt_download_'+nid).hasClass("adpt_download")){
            $('#adpt_download_'+nid).removeClass("adpt_download");
        }
        if($('#adpt_download_'+nid).hasClass("adpt_inprogress")){
            $('#adpt_download_'+nid).removeClass("adpt_inprogress");
        }
        $('#adpt_download_'+nid).addClass('adpt_delete');

        $('.start_download_button[nid='+nid+']').html('Test Downloaded');
    }
    catch(e){
       console.log( "function : showRemoveMessage"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.showNewFileAvailable = function(nid)
{
    try{
        if ($('.download_button[nid='+nid+']').hasClass("delete")) {
            $('.download_button[nid='+nid+']').removeClass("delete");
        }
        if ($('.download_button[nid='+nid+']').hasClass("inprogress")) {
            $('.download_button[nid='+nid+']').removeClass("inprogress");
        }
        $('.download_button[nid='+nid+']').addClass('download');
        $('.new_version_text[nid='+nid+']').removeClass("hidden");

        $('.start_download_button[nid='+nid+']').html('Download Test');
        $('.new_version_text[nid='+nid+']').removeClass("hidden");
    }
    catch(e){
       console.log( "function : showRemoveMessage"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.showInProgressMessage = function(resource)
{
    try{
        if ($('.download_button[nid='+resource.nid+']').hasClass("delete")) {
            $('.download_button[nid='+resource.nid+']').removeClass("delete");
        }
        if ($('.download_button[nid='+resource.nid+']').hasClass("download")) {
            $('.download_button[nid='+resource.nid+']').removeClass("download");
        }
        if(!$('.new_version_text[nid='+resource.nid+']').hasClass("hidden")){
            $('.new_version_text[nid='+resource.nid+']').addClass("hidden");
            $('.new_version_text[nid='+resource.nid+']').addClass("new_version_inprogress");
        }
        $('.download_button[nid='+resource.nid+']').addClass("inprogress");

        if(resource.type != "undefined" && resource.type != "" && resource.type == RESOURCE_TYPE_ASSESSMENT){
            var resourceid  = resource.url
        }else{
            var resourceid  = resource.nid
        }

        var pause_state = this.getFilePauseState(resourceid);
        if(pause_state == false){
            if (ENABLE_NEW_UI) {
                $('.download_button[nid=' + resource.nid + ']').css({ 'background-image': "url('/platform/images/thumb-dl-duel_new.bdcb4cc6.png')" });
            } else {
                $('.download_button[nid=' + resource.nid + ']').css({ 'background-image': "url('/platform/images/thumb-dl-duel.e3e99388.png')" });
            }

        }else{
            if (ENABLE_NEW_UI) {
                $('.download_button[nid=' + resource.nid + ']').css({ 'background-image': "url('/platform/images/thumb-dl-blue_new.b55c8337.png')" });
            } else {
                $('.download_button[nid=' + resource.nid + ']').css({ 'background-image': "url('/platform/images/thumb-dl-blue.6e4750f0.png')" });
            }
        }
        this.displayProgress(resource);
        var index = findIndexByKeyValue(DOWNLOAD_FILES_IN_PROGRESS,'nid',resource.nid);
        if(index == null){
               DOWNLOAD_FILES_IN_PROGRESS.push(resource);
        }

        //Adaptive content inprogress
        if($('#adpt_download_'+resource.nid).hasClass("adpt_delete")){
            $('#adpt_download_'+resource.nid).removeClass("adpt_delete");
        }
        if($('#adpt_download_'+resource.nid).hasClass("adpt_download")){
            $('#adpt_download_'+resource.nid).removeClass("adpt_download");
        }
        $('#adpt_download_'+resource.nid).addClass("adpt_inprogress");

        $('.start_download_button[nid='+resource.nid+']').html('Download In-Progress');
        
    }
    catch(e){
       console.log( "function : showInProgressMessage"
            + "\n message:" + e.message);
    }
}
ContentUtilsService.prototype.hideDownloadMessage = function(nid)
{
    try{
        $('.download_wrapper[nid='+nid+']').addClass('hidden');
    }
    catch(e){
       console.log( "function : hideDownloadMessage"
            + "\n message:" + e.message);
    }
}
ContentUtilsService.prototype.getFilePauseState = function(nid){
    try{
       var pause_state = Platform.getFilePauseState(nid);
       return pause_state;
    }
    catch(e){
       console.log( "function : getFilePauseState"
            + "\n message:" + e.message);
}
}

ContentUtilsService.prototype.filedownloadprogresstimer = function() {
    var THIS = this;
    setInterval(function() {
        if (DOWNLOAD_FILES_IN_PROGRESS.length > 0) {
            for (var i = 0; i < DOWNLOAD_FILES_IN_PROGRESS.length; i++) {
                THIS.displayProgress(DOWNLOAD_FILES_IN_PROGRESS[i]);
            }
        }
        if (typeof DOWNLOAD_FILES_UNDER_CATEGORY_IN_PROGRESS != 'undefined') {
            THIS.filedownloadProgressTimerForCategory();
        }
        if (typeof DOWNLOAD_FILES_UNDER_COURSE_IN_PROGRESS != 'undefined') {
            THIS.filedownloadProgressTimerForCourse();
        }
    }, 10000);
}

ContentUtilsService.prototype.displayProgress = function(resource){
    try{
        var resourceService = CONTROLLER.getService("Resource");
        var downloadedResourceForWebinar = resourceService.getDownloadedContentForWebinar(resource.nid);
        if(downloadedResourceForWebinar[OBJECT_VIDEO].length > 0){
            resource.nid = downloadedResourceForWebinar[OBJECT_VIDEO][0].nid;
        }
        if($('.download_wrapper[nid='+resource.nid+']').length >= 1){
            var download_status = 0;
            var filepath;
            var download_progress = 0;
            var current_status = 0;
            var nid = "";
            var youtube_id = "";
             var quizService = CONTROLLER.getService("Quiz");
            var resource_nid=resource.nid;
            if(resource.type=="practice" || resource.type=="certificate" || resource.type == RESOURCE_TYPE_ASSESSMENT
            || resource.type == RESOURCE_TYPE_FLASHCARD ){
                // checking if resource url present for flashcard if present use that for showing download progress for flashcard
               if(resource.type == RESOURCE_TYPE_ASSESSMENT || (resource.type == OBJECT_FLASHCARD && typeof resource.url !== "undefined")){
                  nid = resource.url;
                    var quiz = quizService.getQuiz(nid);
                    filepath = "/video/"+quiz.nid +"/"+quiz.nid+'_'+quiz.changed+".zip";
                    youtube_id = quiz.changed;
                    resource_nid = quiz.nid;
               }
               else{
                   nid = resource.nid;
                   filepath = "/video/"+resource.nid+"/"+resource.nid+'_'+resource.changed+".zip";
                    youtube_id=resource.changed;
               }
            }else{
                nid = resource.nid;
                if(resource.type == RESOURCE_TYPE_ROLEPLAY){
                    var resource_nid = resource.roleplay_nid;
                }
                if(resource.type == RESOURCE_TYPE_WEBINAR){
                    var resourceObj = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR,resource.webinar_record_id,"content"));
                    youtube_id = resourceObj.youtube_id;
                }
                else{
                    youtube_id = resource.youtube_id;
                }


               filepath = getResourceLocalPath(resource);
            }
            var fileProgressNid = resource_nid;
            if(resource.type == RESOURCE_TYPE_WEBINAR){
                fileProgressNid = resource.webinar_record_id;
            }
            if(filepath){
                download_status = Platform.getDownloadFileProgress(filepath,fileProgressNid,youtube_id);
                download_status = download_status.split("-");
                if(download_status[0] == 0){
                    download_progress = 0;
                }else{
                    download_progress = Math.round((download_status[0] / download_status[1]) * 100);
                }
                if(download_progress > 100){
                    download_progress = 99;
                }
                current_status = $('.download_wrapper[nid=' + resource_nid + ']').find('.download_status').html();
                if (current_status) {
                    current_status = current_status.split('%');
                    if (current_status[0] < 90 || (current_status[0] >= 1 && download_progress != 0)) {
                        $('.download_wrapper[nid=' + resource.nid + ']').find('.download_status').html(download_progress + '%');
                    }
                    else if ((current_status[0] >= 1 && download_progress == 0)) {
                        $('.download_wrapper[nid=' + resource.nid + ']').find('.download_status').html('99%');
                    }
                } else {
                    if (download_progress != 0) {
                        $('.download_wrapper[nid=' + resource.nid + ']').find('.download_status').html(download_progress + '%');
                    }
                    else if (download_progress == 0) {
                        $('.download_wrapper[nid=' + resource.nid + ']').find('.download_status').html('0%');
                    }
                }
                this.showProgressBar(resource.nid);
                if (download_progress == 100) {
                    this.showRemoveMessage(resource.nid);
                     $('.download_wrapper[nid='+resource.nid+']').find('.download_status').html('0' + '%');
                    $('.download_wrapper[nid='+resource.nid+']').find('.download_status_wrapper').addClass("hidden");
                    
                    $('.start_download_button[nid='+resource.nid+']').html('Test Downloaded');
                    if($('.new_version_text[nid='+resource.nid+']').hasClass("new_version_inprogress")){
                        $('.new_version_text[nid='+resource.nid+']').removeClass("new_version_inprogress");
                        $('.new_version_text[nid='+resource.nid+']').addClass("hidden");
                    }
                    var index = findIndexByKeyValue(DOWNLOAD_FILES_IN_PROGRESS,'nid',resource.nid);
                    if(index != null){
                       DOWNLOAD_FILES_IN_PROGRESS.splice(index,1);
                    }
                }
            }
        }
    }
    catch(e){
       console.log( "function : displayProgress"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.showProgressBar = function(nid){
    if($('.download_wrapper[nid='+nid+']').find('.download_status_wrapper').hasClass("hidden")){
        $('.download_wrapper[nid='+nid+']').find('.download_status_wrapper').removeClass("hidden");
    }
}

ContentUtilsService.prototype.uploadFiletoServer= function(qsn_data){
   try{
        if (Platform.getNetworkState() == true) {
            var user = Platform.makeAsyncTaskCall(qsn_data);
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
       console.log( "function : uploadFiletoServer"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.showDownloadProgressForCategory = function() {
    if ($('.cat_downloaded_content_status').length > 0) {
        var groupid = CONTROLLER.getVariable("activegroupid")
        if(typeof HIDE_GROUPS_FILTER != "undefined" && HIDE_GROUPS_FILTER == 'true'){
            var dataarray = `['${OBJECT_USER_GROUPS.toString()}', '${DOWNLOADABLE_CONTENTS}' ]`
        }else{
            var dataarray = `['${groupid}', '${DOWNLOADABLE_CONTENTS}' ]`
        }
        makeAsyncTaskCall("", dataarray , 'getDownloadStatusByGroup', 'internalapi', "false", 'checkCategoryDownloadStatus', 'Platform');
        checkCategoryDownloadStatus = function (cat_content_status) {
            if (cat_content_status) {
                DOWNLOAD_FILES_UNDER_CATEGORY_IN_PROGRESS = cat_content_status;
                for (var i = 0; i < cat_content_status.length; i++) {
                    var catid = cat_content_status[i]['categoryid'];
                   if (catid){
                        var totalfileAddedforDownload = 0;
                        var tot_contents_count = 0;
                        for (var j = 0; j < cat_content_status.length; j++) {
                            if (cat_content_status[j].categoryid == catid) {
                                totalfileAddedforDownload = cat_content_status[j].totalfileAdded;
                                tot_contents_count = cat_content_status[j].totalfiledownloaded;
                                break;
                            }
                        }
                        if (tot_contents_count != 0 || totalfileAddedforDownload != 0) {
                            $('#content_status_' + catid).html(tot_contents_count + '/' + totalfileAddedforDownload);
                            $('#dl_category_' + catid).removeClass("delete");
                            $('#dl_category_' + catid).removeClass("inprogress");
                            $('#dl_category_' + catid).removeClass("download");
                            if (tot_contents_count == totalfileAddedforDownload) {
                                $('#dl_category_' + catid).addClass('delete');
                            } else if (tot_contents_count <  totalfileAddedforDownload) {
                                $('#dl_category_' + catid).addClass('inprogress');
                            } else {
                                $('#dl_category_' + catid).addClass('download');
                            }
                        }
                    }
                }
            }
        }
    }

};

ContentUtilsService.prototype.filedownloadProgressTimerForCategory = function() {
    var THIS = this;
    for (var i = 0; i < DOWNLOAD_FILES_UNDER_CATEGORY_IN_PROGRESS.length; i++) {
        if (DOWNLOAD_FILES_UNDER_CATEGORY_IN_PROGRESS[i]['totalfiledownloaded'] != DOWNLOAD_FILES_UNDER_CATEGORY_IN_PROGRESS[i]['totalfileAdded']) {
            THIS.showDownloadProgressForCategory();
        }
    }
}

ContentUtilsService.prototype.showDownloadProgressForCourse = function() {
    var catService = CONTROLLER.getService("Category");
    var courseService = CONTROLLER.getService("Course");
    var totalCourseContents = 0;
    if ($('.downloaded_content_status').length > 0) {
        var course_content_status = Platform.getAllDownloadStatusByCourse();
        if (course_content_status) {
            course_content_status = $.parseJSON(course_content_status);
            DOWNLOAD_FILES_UNDER_COURSE_IN_PROGRESS = course_content_status;
            for (var i = 0; i < course_content_status.length; i++) {
                var courseid = course_content_status[i]['courseid'];
                var catid = CONTROLLER.getVariable("activecatid");
                if (courseid) {
                    var courselevel_downloaded_contents = Platform.getCourseDownloadedFileCount(courseid);
                    if(typeof HIDE_GROUPS_FILTER != "undefined" && HIDE_GROUPS_FILTER == "true"){
                            totalCourseContents = catService.getResourcesCount(catid, courseid,OBJECT_USER_GROUPS.join(","))
                        + catService.getSupportdocsCount(catid, courseid, OBJECT_USER_GROUPS.join(","))
                        + catService.getEbooksCount(catid, courseid, OBJECT_USER_GROUPS.join(","))
                        + courseService.getQuizzesCount(catid, courseid, OBJECT_USER_GROUPS.join(","));
                    }
                    else{
                        totalCourseContents = catService.getResourcesCount(catid, courseid,CONTROLLER.getVariable("activegroupid"))
                        + catService.getSupportdocsCount(catid, courseid, CONTROLLER.getVariable("activegroupid"))
                        + catService.getEbooksCount(catid, courseid, CONTROLLER.getVariable("activegroupid"))
                        + courseService.getQuizzesCount(catid, courseid, CONTROLLER.getVariable("activegroupid"));
                    }

                    var totalfileAddedforDownload = course_content_status[i]['totalfileAdded'];
                    $('#content_status_' + courseid).html(courselevel_downloaded_contents + '/' + totalCourseContents);

                    if (totalCourseContents != 0 && totalfileAddedforDownload != 0) {
                        $('#content_status_' + courseid).html(courselevel_downloaded_contents + '/' + totalCourseContents);
                        $('#dl_course_' + courseid).removeClass("delete");
                        $('#dl_course_' + courseid).removeClass("inprogress");
                        $('#dl_course_' + courseid).removeClass("download");
                        if (totalCourseContents == courselevel_downloaded_contents) {
                            $('#dl_course_' + courseid).addClass('delete');
                        } else if (courselevel_downloaded_contents < totalCourseContents) {
                            $('#dl_course_' + courseid).addClass('inprogress');
                        } else {
                            $('#dl_course_' + courseid).addClass('download');
                        }
                    }
                }
            }
        }
    }
};
ContentUtilsService.prototype.filedownloadProgressTimerForCourse = function() {
    var THIS = this;inProgressCount=0;
    var course_content_status = Platform.getAllDownloadStatusByCourse();
    if (course_content_status) {
        course_content_status = $.parseJSON(course_content_status);
        DOWNLOAD_FILES_UNDER_COURSE_IN_PROGRESS = course_content_status;
    }
    for (var i = 0; i < DOWNLOAD_FILES_UNDER_COURSE_IN_PROGRESS.length; i++) {
        if (DOWNLOAD_FILES_UNDER_COURSE_IN_PROGRESS[i]['totalfiledownloaded'] != DOWNLOAD_FILES_UNDER_COURSE_IN_PROGRESS[i]['totalfileAdded']) {
            inProgressCount++;
        }
    }
    if(inProgressCount > 0){
        THIS.showDownloadProgressForCourse();
    }
}

ContentUtilsService.prototype.checkContentAvailableInSdcard = function(courseid, catid) {
    try{
    var tocService = CONTROLLER.getService("Toc");
    var chaptersubject = false;
    var filedata = Array();
    var sdcard = tocService.getTocSdCardInfo(courseid);
    if (sdcard !== false) {
        var chaptersofsdcard = tocService.getChaptersOfSdCard();
        var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content"));
        if (typeof chaptersofsdcard[category.title] == "undefined" || chaptersofsdcard[category.title] == "") {
            chaptersofsdcard = "";
        }
        if (chaptersofsdcard != "") {
            for (j = 0; j < chaptersofsdcard[category.title].length; j++) {
                if (chaptersofsdcard[category.title][j].nid == courseid) {
                    chaptersubject = true;
                }
            }
        }
        if (chaptersubject == false) {
            var sdcard = tocService.getTocSdCardInfo(courseid);
            filedata[0] = 'error';
            if (typeof sdcard == "undefined" || sdcard == "") {
                filedata[1] = "Please insert SD card and try again";
            }
            else {
                filedata[1] = "Please insert SD card " + sdcard + " to view this.";
            }
            return filedata;
        }
    }
    }catch(e){
        Client.closeProgressBar();
        console.log("function checkContentAvailableInSdcard := " + e.message)
    }
}

ContentUtilsService.prototype.getUnsubscribedResourceurl = function(nid,type,courseid,download,cat_tid, resObj){
    try {
        var format = "";
        var downloadparam = "";
        if(typeof type != 'undefined' &&  (type.toLowerCase() == 'pdf' || type == 'Document')){
            format = '&format=original';
            stream = "";
        }
        else {
            var videoFormats = RESOURCE_ONLINE_FORMAT;
            if (resObj && resObj["format-type"]) {
                videoFormats = resObj["format-type"];
            }
            format = '&format=' + videoFormats;
            stream = '&stream=' + STREAM_TYPE;
        }
        if(typeof download != "undefined" && download == "true"){
            downloadparam = "&download=true";
        }
        else{
            downloadparam = "";
        }
        var resource = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get/'+OBJECT_VIDEO+'/publicurl'+ '?nid=' + nid +format+stream+downloadparam +"&auth=" + AUTH_ENABLED );
        resource = $.parseJSON(resource);
        var status = resource['status'];
        if(status == 200){
            var data = $.parseJSON(resource['data']);
            var resourceurl = data['videourl'];
            CONTROLLER.setVariable("activeresourcepath",resourceurl);
            CONTROLLER.setVariable("showpopup_for_unsubscribed_video",'true');
        }
        return status;
    }
    catch (e)
    {
        var error = "function : getUnsubscribedResourceurl\n\
                    url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO +
                    '?nid=' + nid + "&auth=" + AUTH_ENABLED
        console.log(error);
    };
};

ContentUtilsService.prototype.getContentFromId = function(nid){
    try {
        if (Platform.getNetworkState() == true) {
            var resource = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getcontentfromid?nid=' + nid);
            resource = $.parseJSON(resource);
            var status = resource['status'];
            if (status == 200)
            {
                var resourcedata = resource['data'];
                return resourcedata;
            }else{
                return ERROR_MESSAGE;
            }
        }else{
            return INTERNET_ERROR;
        }
    }
    catch (e)
    {
        var error = "function : getContentFromId\n\
                    url :" + OBJECT_SERVER_DOMAIN + '/api/getcontentfromid' +
                    '?nid=' + nid;
        console.log(error);
    };
};

ContentUtilsService.prototype.getQuizFromId = function(nid){
    try {
        if (Platform.getNetworkState() == true) {
            var resource = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get'+ OBJECT_QUIZ +'fromid?nid=' + nid);
            resource = $.parseJSON(resource);
            var status = resource['status'];
            if (status == 200)
            {
                var resourcedata = resource['data'];
                return resourcedata;
            }else{
                return ERROR_MESSAGE;
            }
        }else{
            return INTERNET_ERROR;
        }
    }
    catch (e)
    {
        var error = "function : getQuizFromId\n\
                    url :" + OBJECT_SERVER_DOMAIN + '/api/getquizfromid' +
                    '?nid=' + nid;
        console.log(error);
    };
};

ContentUtilsService.prototype.getInteractiveSnippet = function(snippent_id){
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + "/api/getoverlayhtml?nids=" + snippent_id;
            var result = JSON.parse(Platform.makeHttpGetCall(url));
            var status = result['status'];
            if (status == 200) {
                return JSON.parse(result["data"]);
            } else if (status == 401 || status == 403) {
                return status;
            } else {
                return ERROR_MESSAGE;
            }  
        }else{
            alert(INTERNET_ERROR);
        }
        
    } catch (e) {
        console.log("getInteractiveSnippet" + e.message);
    }
};
function SettingsService() {
    this.setLastLoggedinUser();
};

SettingsService.prototype.getLastLoggedinUser = function(){
    try{
        if(EXEC_MODE != "mobile" && OBJECT_USER_DATA != ""){
            return OBJECT_USER_DATA;
        }
        else{
            var user = Platform.getObjectProperty("currentuser", 1, "content");
            if(user == ""){
                return "";
            }
            else{
                return $.parseJSON(user);
            }
        }
    }
    catch(e){
        console.log("function : getLastLoggedinUser"
            + " message" + e.message);
    }
};

SettingsService.prototype.isFunctionalityExist = function(object)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/isfunctionalityexist?object='+object;
            var fexists = Platform.makeHttpGetCall(url);
            fexists = $.parseJSON(fexists);
            var status = fexists['status'];
            if (status == 200) {
                var data = $.parseJSON(fexists["data"]);
                if(data["status"] == "true"){
                    return "true";
                }
                else{
                    return data["message"];
                }
            }
            else {
                return ERROR_MESSAGE;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : getForumTopics"
                + "\n message:" + e.message);
    }
};

SettingsService.prototype.setLastLoggedinUser = function(){

    /* Sets the last logged in user automatically whenever the app is killed and
    re-opened */

    try{
        var user = Platform.getObjectProperty("currentuser", 1, "content");
        if(user != ''){
            var userService = CONTROLLER.getService('User');
            user = $.parseJSON(user);
            OBJECT_USER_ID = user.uid;
            OBJECT_USER_NAME = user.username;
            OBJECT_USER_PASSWORD = user.password;
            OBJECT_USER_DATA = user;
            if(typeof OBJECT_USER_DATA != "undefined" && typeof OBJECT_USER_DATA["user_details"] != "undefined"){
                user["user_details"] = OBJECT_USER_DATA["user_details"];
            }
            userService.getUserInfo(OBJECT_USER_DATA.username, OBJECT_USER_DATA.email, function (userdata) {
                if(userdata == GET_USER_INFO_FAILURE){
	                OBJECT_USER_DATA['user_details'] = "";
	            }else{
                    OBJECT_USER_DATA['user_details'] = userdata;
                    if(typeof userdata[0].roles != "undefined" && userdata[0].roles != ""){
                        OBJECT_USER_DATA['roles'] = userdata[0].roles;
                    }
	            }
                return
            });
            OBJECT_USER_GROUPS = user.groups;
            OBJECT_USER_GROUPS_JSON = [];
            for(var i=0;i<OBJECT_USER_GROUPS.length;i++){
                var group_json = $.parseJSON(Platform.getObjectProperty(OBJECT_GROUP, OBJECT_USER_GROUPS[i], "content"));
                if(group_json)
                    OBJECT_USER_GROUPS_JSON.push(group_json);
            }
        }
    }
    catch(e){
        OBJECT_USER_ID = '';
        OBJECT_USER_NAME = '';
        OBJECT_USER_DATA = '';
        OBJECT_USER_GROUPS = [];
        console.log("function : setLastLoggedinUser"
            + " message" + e.message);
    }
};

SettingsService.prototype.getUserProducts = function(userid){
    try{
        if(EXEC_MODE != "mobile" && typeof OBJECT_USER_PRODUCTS != "undefined"){
            return OBJECT_USER_PRODUCTS;
        }
        var products = Platform.getObjectProperty("userproducts",userid,"content");
        if(products == ""){
            return "";
        }
        else{
            return $.parseJSON(products);
        }
    }
    catch(e){
        console.log("function : getUserProducts"
            + " message" + e.message);
    }
};

SettingsService.prototype.getUserProfile = function(userid){
    try{
        var userProfile = Platform.getObjectProperty("userProfile",userid,"content");
        if(userProfile == ""){
            if(Platform.getNetworkState() == true){
                return MobileUserService.prototype.getUserInfo(OBJECT_USER_NAME);
            }
            return "";
        }
        else{
            return $.parseJSON(userProfile);
        }
    }
    catch(e){
        console.log("function : getUserProfile"
            + " message" + e.message);
    }
};

SettingsService.prototype.getUserSubscriptions = function(userid,catid,courseid,groupid){
    try{
        var data = "{";
        var temp = "";
        temp = ' "uid" : "' + userid + '", ';
        if(typeof COURSE_SUBSCRIPTION == "undefined"){
            temp = temp + ' "category_id" : "' + catid + '", ';
        }
        if (typeof GROUP_SUBSCRIPTION != 'undefined' && GROUP_SUBSCRIPTION == 'true' && typeof groupid != 'undefined' && groupid != '') {
            temp = temp + ' "type" : "' + OBJECT_GROUP + '", ';
            temp = temp + ' "group_id" : "' + groupid + '", ';
        }
        if (typeof courseid != 'undefined' && courseid != '') {
            temp = temp + ' "type" : "collection", ';
            temp = temp + ' "collection_id" :"' + courseid + '"';
        }
        data = data + temp + "}";
        var subscription = Platform.getUserSubscription(data);
        if(subscription == "" || subscription == "{}"){
            return "";
        }
        else if(subscription == ACCESS_DENIED){
            return ACCESS_DENIED;
        }
        else{
            return $.parseJSON(subscription);
        }
    }
    catch(e){
        console.log("function : getUserSubscriptions"
            + " message" + e.message);
    }
};

SettingsService.prototype.getAllLanguages= function(){
   try {
       var languages = Platform.getObjectsOfType("lang");
       return $.parseJSON(languages);
    }
    catch (e) {
        console.log("function : getAllLanguages"
                + "\n message:" + e.message);
    }
};

SettingsService.prototype.isFunctionalityExist = function(object)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/isfunctionalityexist?object='+object;
            var fexists = Platform.makeHttpGetCall(url);
            fexists = $.parseJSON(fexists);
            var status = fexists['status'];
            if (status == 200) {
                var data = $.parseJSON(fexists["data"]);
                if(data["status"] == "true"){
                    return "true";
                }
                else{
                    return data["message"];
                }
            }
            else {
                return t("ERROR_MESSAGE",true);
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : getForumTopics"
                + "\n message:" + e.message);
    }
};

SettingsService.prototype.getLeaderboardData = function(){
    try{
        if (Platform.getNetworkState() == true) {
           var url = OBJECT_SERVER_DOMAIN + '/api/getleaderboarddata?uid='+OBJECT_USER_ID+
                   '&limit='+LEADERBOARD_LIMIT+'&auth=' + AUTH_ENABLED;
           var leaderData = Platform.makeHttpGetCall(url);
            leaderData = $.parseJSON(leaderData);
            var status = leaderData['status'];
            if (status == 200) {
                var data = $.parseJSON(leaderData["data"]);
                return data;
            }else if(status == 403){
                onAccessDenied();
            }else if(status == 404){
                return status;
            }
            else {
                return t("ERROR_MESSAGE",true);
            }
        }else{
            return t("INTERNET_ERROR",true);
        }
    } catch(e){

    }
}

SettingsService.prototype.checkChapterSubscribed = function(uid, chapter_id){
    try {
        var chapterSubscribed = Platform.isChapterSubscribed(chapter_id, uid);
        return chapterSubscribed;
    }
    catch (e) {
        console.log("function : checkChapterSubscribed"
                + "\n message:" + e.message);
    }
};
SettingsService.prototype.applyCoupon =  function(uid, coupon){
    try{
        if (Platform.getNetworkState() == true) {
            let url = OBJECT_SERVER_DOMAIN + '/api/coupon/subscription?auth=' + AUTH_ENABLED;
            let data = "{";
            let temp = "";
            temp = ' "uid" : "' + uid + '", ';
            temp = temp + ' "coupon" : "' + coupon + '"';
            data = data + temp + "}";
            
            let postdata = encodeURIComponent(data);
            let coupon_applied = Platform.makeHttpPostCall(url, postdata);
            coupon_applied =  $.parseJSON(coupon_applied);
            if(coupon_applied.status == 200){
                let subscription = coupon_applied['data'];
                subscription = $.parseJSON(subscription);
                return coupon_applied.status;
            }else{
                return coupon_applied.status;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/coupon/subscription '+ uid + '&group=' + groupid
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}
function MobileUserService(){};

MobileUserService.prototype.createUser = function(userjson,externalid)
{
    try{
        if(Platform.getNetworkState() == true){
            userjson = $.parseJSON(userjson);
            if(EMAIL_VERIFIED && EMAIL_VERIFIED == true){
                userjson.email_verified = true;
            }
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/createuser?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            if (status == 201 || status == 200){
                var userdata = user['data'];
                if(userdata.trim() != ""){
                    userdata = $.parseJSON(userdata);
                    userdata = userdata[0];
                    this.saveUserData(userjson,userdata,externalid);
                }
                return "true";
            }
            else if(status == 409 || status == 205){
                return t("USER_ALREADY_REGISTERED",true);
            }
            else if(status == 406 ){
                return COUPON_CODE_ERROR;
            }
            else if(status == 405 ){
                return t("MOBILE_NO_ALREADY_EXIST_ERROR_MESSAGE",true);
            }
            else{
                return t("USER_CREATION_FAILURE",true);
            }
        }
        else{
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : createUser"
            + "\n message:" + e.message);
    }
};
MobileUserService.prototype.changeClassStream = function(userjson)
{
    try{
        if(Platform.getNetworkState() == true){
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/change/class_stream?uid='+ OBJECT_USER_ID +'&auth='+ AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            if (status == 201 || status == 200){
                return "true";
            }
            else if(status == "401"){
                return CHANGE_CLASS_SUBSCRIPTION_ERROR;
            }
            else{
                return OPERATION_ERROR;
            }
        }
        else{
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : changeClassStream"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.verifyUser = function(userjson){
    try{
        var temp = $.parseJSON(userjson);
        if(Platform.getNetworkState() == true){
            var url = OBJECT_SERVER_DOMAIN + '/api/verify/user';
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200 || status == 201){
                return "true";
            }
            else if(status == 401){
                return t("LOGIN_FAILURE",true);
            }
            else if(status == 404){
                return t("USER_NOT_REGISTERED",true);
            }
            else{
                return t("USER_LOGIN_FAILURE",true);
            }
        }
        else{
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : verifyUser"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.retagUser = function(userjson)
{
    try{
        if(Platform.getNetworkState() == true){
            userjson = $.parseJSON(userjson);
            if(EMAIL_VERIFIED && EMAIL_VERIFIED == true){
                userjson.email_verified = true;
            }
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/retag/usergroups?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            if (status == 201 || status == 200){
                var userdata = user['data'];
                if(userdata.trim() != ""){
                    userdata = $.parseJSON(userdata);
                    userdata = userdata[0];
                    this.saveUserData(userjson,userdata,externalid);
                }
                return "true";
            }
            else if(status == "401"){
                return CHANGE_CLASS_SUBSCRIPTION_ERROR;
            }
            else{
                return OPERATION_ERROR;
            }
        }
        else{
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : createUser"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.loginUserAsync = function(){
    var loginsource = Platform.getPreference("loginsource");
    var backfrombuynow = CONTROLLER.getVariable("backfrombuynow");
    var login_history_update ='true';
    if (typeof loginsource == "undefined" || loginsource == "") {
        loginsource = "";
    }
    if (typeof backfrombuynow != "undefined" && backfrombuynow != "" && backfrombuynow == 'true') {
        login_history_update = 'false';
        CONTROLLER.setVariable("backfrombuynow", '');
    }
    var loginurl = OBJECT_SERVER_DOMAIN + "/api/login/redirect?uid=" + OBJECT_USER_ID +'&login_history_update='+login_history_update+'&login_source='+ loginsource+"&auth=true&useractive=true&path=/loginsucess.html";
    var data = "{";
    var temp = "";
    temp = temp + ' "message" : "",';
    temp = temp + ' "data" : "",';
    temp = temp + ' "url" : "'+loginurl+'",';
    temp = temp + ' "method" : "get",';
    temp = temp + ' "showdialog" : "false",';
    temp = temp + ' "callback" : "MobileUserService.prototype.onAsyncLoginComplete"';
    data = data + temp + "}";
    Platform.makeAsyncTaskCall(data);
}

MobileUserService.prototype.onAsyncLoginComplete = function(result){
    var THIS = this;
    if(typeof result["status"] != "undefined" && (result["status"] != "200" && result["status"] != "302")){
        setTimeout(function(){
            THIS.loginUserAsync();
        },5000);
        ASYNC_LOGIN_REQUIRED = "true";
    }
    else{
        ASYNC_LOGIN_REQUIRED = "false";
    }
}

MobileUserService.prototype.loginUserSync = function(){
    var loginurl = OBJECT_SERVER_DOMAIN + "/api/login/redirect?uid=" + OBJECT_USER_ID + "&auth=true&useractive=true&path=";

    var result = Platform.makeHttpPostCall(loginurl);

    if(typeof result["status"] != "undefined" && (result["status"] != "200" && result["status"] != "302")){
        return false;
    }
    return true;
}

MobileUserService.prototype.saveUserData = function(userjson,userdata,externalid){
    try{
        userjson.uid = userdata.uid;
        Platform.setUserID(userjson.uid);
        userjson.groups = userdata.groups;
        if(typeof OBJECT_USER_PASSWORD != "undefined" && OBJECT_USER_PASSWORD != "" && OBJECT_USER_PASSWORD == "true"){
            userjson.password = OBJECT_USER_PASSWORD;
        }
        else if(typeof userjson.password != "undefined" && typeof USE_CRYPTOJS != 'undefined' && USE_CRYPTOJS != 'false'){
            userjson.password = CryptoJS.MD5(userjson.password).toString();
        }
        userjson = JSON.stringify(userjson);
        Platform.saveObject("currentuser", 1, "content", $.trim(userjson));
        Platform.saveObject("user", userdata.uid, "content", $.trim(userjson));
        // save user data in preference
        Platform.setPreference("userDetailsJson", userjson);
        if(externalid){
            var mappingJson = {"nid": userdata.uid, "externalid": externalid};
            Platform.saveObject("usermapping", externalid, "content", JSON.stringify(mappingJson));
        }
        OBJECT_USER_DATA = $.parseJSON(userjson);
        OBJECT_USER_ID = OBJECT_USER_DATA.uid;
        OBJECT_USER_NAME = OBJECT_USER_DATA.username;
    }
    catch(e){
        console.log( "function : saveUserData"
            + "\n message:" + e.message);
    }
}

MobileUserService.prototype.saveUser = function(userjson)
{
    Platform.saveObject("user",userjson.uid,"content",$.trim(JSON.stringify(userjson)));
    Platform.saveObject("currentuser",1,"content",$.trim(JSON.stringify(userjson)));
}

MobileUserService.prototype.updateUser = function(userjson)
{
    try{
        if(Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/edituser?uid=' + OBJECT_USER_ID;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                var userdata = user['data'];
                if(userdata.trim() != ""){
                    userdata = $.parseJSON(userdata);
                }
                Platform.saveObject("currentuser", 1, "content", $.trim(userjson));
                Platform.saveObject("user", OBJECT_USER_ID, "content", $.trim(userjson));
                return "true";
            }
            else {
                return t("EDIT_USER_FAILURE",true);
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : updateUser"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getPassword = function(userjson,uid)
{
    try{
        if(Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/get/password?auth=true';
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                var currentuserjson = DataManager.getObjectProperty("user", uid, "content");
                currentuserjson.password = userdata[0].password;
                var json = JSON.stringify(currentuserjson);
                Platform.saveObject("user", uid, "content", $.trim(json));
                return "true";
            }
            else if (status == 404){
                return USER_NOT_EXISTS;
            }
            else {
                return t("EDIT_USER_FAILURE",true);;
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : getPassword"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getUserInfo = function(username,email,callback,uid)
{
    var params = "";
    try{
        if(Platform.getNetworkState() == true) {
            if (typeof uid != "undefined" && uid != "") {
                params = '&uid='+uid;
            }
            else if(typeof email !== "undefined" && email != ""){
                params = '&email=' + email;
            }
            else if(typeof username !== "undefined" && username != ""){
                params = '&username='+username;
            } 
            var url = OBJECT_SERVER_DOMAIN + '/api/getuserinfo?auth=true'+params;
            if(typeof callback != 'undefined' && typeof callback == 'function' ){
                onAsyncGetuserinfoComplete = function (result)
                {
                    var status = result['status'];
                    if (status == 200) {
                        var userdata = result['data'];
                        Platform.saveObject("userProfile", OBJECT_USER_ID, "content", $.trim(userdata));
                        userdata = $.parseJSON(userdata);
                        if(!checkIsEmpty(userdata[0].groups) && typeof userdata[0].groups === 'string'){
                            var groupsArray = userdata[0].groups.split(",");
                            OBJECT_USER_DATA.groups = groupsArray;
                            OBJECT_USER_GROUPS = groupsArray;
                        }
                        OBJECT_USER_DATA['user_details'] = userdata;
                        OBJECT_USER_PROFILE = userdata;
                        Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
                        Platform.saveObject("user", OBJECT_USER_ID, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
                        return callback(userdata);
                    } else if (status == 403) {
                        return onAccessDenied();
                    } else {
                        return callback(t("GET_USER_INFO_FAILURE",true));
                    }
                }
                makeAsyncTaskCall("","",url,"get","false","onAsyncGetuserinfoComplete");
            }else{
                var postdata = "";
                var user = Platform.makeHttpPostCall(url, postdata);
                user = $.parseJSON(user);
                var status = user['status'];
                if (status == 200) {
                    var userdata = user['data'];
                    Platform.saveObject("userProfile", OBJECT_USER_ID, "content", $.trim(userdata));
                    userdata = $.parseJSON(userdata);
                    return userdata;
                } else if (status == 403) {
                    return onAccessDenied();
                } else {
                    return t("GET_USER_INFO_FAILURE",true);;
                }
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : getUserInfo"
                + "\n message:" + e.message);
    }
};
MobileUserService.prototype.getUserBadge = function()
{
    var params = "";
    try{
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getuser'+USER_BADGE+'?auth=true';
            var postdata = "";
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                return userdata;
            }
            else {
                return t("GET_USER_INFO_FAILURE",true);
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : getUserBadge"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getUserAttributes = function()
{
    var params = "";
    try{
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/user/attributes?auth=true';
            var postdata = "";
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                var userdata = user['data'];
                Platform.saveObject("userAttributes", OBJECT_USER_ID, "content", $.trim(userdata));
                userdata = $.parseJSON(userdata);
                return userdata;
            }
            else {
                return t("GET_USER_INFO_FAILURE",true);
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : getUserAttributes"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getAvailableBadges = function()
{
    var params = "";
    try{
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getuseravailable'+USER_BADGE+'?auth=true';
            var postdata = "";
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                return userdata;
            }
            else {
                return t("GET_USER_INFO_FAILURE",true);
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : getAvailableBadges"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getGroupsForUser = function(uid)
{
    if (typeof OBJECT_USER_GROUPS_JSON != 'undefined' && OBJECT_USER_GROUPS_JSON != null && OBJECT_USER_GROUPS_JSON != "" && OBJECT_USER_GROUPS_JSON.length > 0) {
        return OBJECT_USER_GROUPS_JSON;
    }
    var user_groups = [];
    try{
        if(Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getgroupsforuser?uid='+uid;
            var postdata = "";
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                OBJECT_USER_GROUPS_JSON = userdata;
                for (var i = 0; i < userdata.length; i++) {
                    user_groups.push(userdata[i].nid);
                }
                OBJECT_USER_DATA.groups = user_groups;
                OBJECT_USER_GROUPS = user_groups;
                return userdata;
            }
            else {
                return t("GET_USER_GROUPS_FAILURE",true);
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : getUserInfo"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getAllgroups = function()
{
    try{
        if(Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getallgroups';
            var postdata = "";
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                return userdata;
            }
            else {
                return t("GET_USER_GROUPS_FAILURE",true);
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : getUserInfo"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getAllgroupsFromDatabase = function()
{
    try{
        var groups = Platform.getObjectsOfType("group");
        return $.parseJSON(groups);
    }
    catch(e){
        console.log( "function : getAllgroupsFromDatabase"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.downloadUserImage = function(username,imagedownloadurl)
{
    var imagepath = OBJECT_DATA_FOLDER + "/sites/default/files/users/" + username + ".png";
    var filepath_to_check = "/sites/default/files/users/" + username + ".png";
    try{
        var imagefileAvailable = Platform.isFilePresentInDocRoot(filepath_to_check);
        if(imagefileAvailable != true){
            var paramsJson = {'objectid':"",'objecttype':'content',
            'serverurl':imagedownloadurl,'localurl':imagepath,
            'message':'', 'sync':FILE_DOWNLOAD_ASYNC};
            Platform.downloadFile(JSON.stringify(paramsJson));
        }
    }
    catch(e){
        console.log("function : downloadUserImage"
            + "url" + imagedownloadurl + " message" + e.message);
    }
};

MobileUserService.prototype.getAllUsers = function()
{
    try{
        var users = Platform.getObjectsOfType("user");
        if(users != ""){
            return $.parseJSON(users);
        }
        else
        {
            return "";
        }
    }
    catch(e){
        console.log( "function : getAllUsers"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getUseridFromExternalId = function(externalid)
{
    try{
        var mapping = Platform.getObjectProperty("usermapping", externalid, "content");
        if(mapping != "" && mapping != null){
            mapping = $.parseJSON(mapping);
            if(!$.isEmptyObject(mapping)){
                return mapping.nid;
            }
            else{
                return "";
            }
        }
        else
        {
            return "";
        }
    }
    catch(e){
        console.log( "function : getUseridFromExternalId"
            + "\n message:" + e.message);
    }

};

MobileUserService.prototype.doesUserExist = function(userjson)
{
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/isuserexist?auth=' + AUTH_ENABLED;
        var postdata = encodeURIComponent(userjson);
        var user = Platform.makeHttpPostCall(url, postdata);
        user = $.parseJSON(user);
        var status = user['status'];
        if (status == 200){
            var data = user['data'];
            data = $.parseJSON(data);
            var save_user_id = CONTROLLER.getVariable("save_user_id");
            if(typeof save_user_id != "undefined" && save_user_id != null && save_user_id == "true"){
                CONTROLLER.setVariable("object_user_id",data[0]["uid"]);
            }
            var userstatus = data[0]["status"];
            if(typeof userstatus != 'undefined' && userstatus == true){
                OBJECT_USER_ID = data[0]["uid"];
                var status_code = data[0]["status_code"];
            }
            if(userstatus == true){
                if(status_code == 409 || status_code == 405){
                    return status_code; // status_code for mobile,email exist in Aakash
                }else{
                    return "true";
                }
            }
            else if(userstatus == false){
                return "false";
            }
        }
        else {
            return t("USER_EXIST_CHECK_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : doesUserExist"
            + "\n message:" + e.message);
    }
};
MobileUserService.prototype.isUserLoggedin = function()
{
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/user/isloggedin';
        var user = Platform.makeHttpPostCall(url,'');
        user = $.parseJSON(user);
        var status = user['status'];
        if (status == 200){
            var data = user['data'];
            data = $.parseJSON(data);
            var userstatus = data[0]["status"];
            if(userstatus == 200){
                return data;
            }
        }else {
            return 'false';
        }
    }
    catch(e){
        console.log( "function : isUserLoggedin"
            + "\n message:" + e.message);
    }
};


MobileUserService.prototype.logout = function (reload) {
    try {
        if (!reload) {
            reload = true;
        }
        if (EXEC_MODE == 'online' && typeof REDIRECT_INTERNAL_LOGOUT != "undefined" && REDIRECT_INTERNAL_LOGOUT == true) {
            Platform.deleteDatabase();
            Platform.setPreference("helpsliderviewed", "true")
            window.location.href = OBJECT_SERVER_DOMAIN + '/api/logout' + '&api_version=' + API_VERSION;
            return;
        }
        var url = OBJECT_SERVER_DOMAIN + '/api/logout';
        var logoutdata = Platform.makeHttpPostCall(url, '');
        logoutdata = $.parseJSON(logoutdata);
        var status = logoutdata['status'];
        if (status == 200) {
            if (EXEC_MODE == 'online') {
                Platform.deleteDatabase();
                setTimeout(function () {
                    Platform.setPreference("helpsliderviewed", "true");
                    if (reload) {
                        setTimeout(function () {
                            if (EXTERNAL_LOGOUT_URL) {
                                window.location.href = EXTERNAL_LOGOUT_URL;
                            } else {
                                window.location.reload();
                            }
                        }, 200);
                    }
                }, 200);
            }
            return "true";
        }
        else {
            return LOGOUT_FAILURE;
        }
    } catch (e) {
        console.log("function : logout"
            + " message" + e.message);
    }
};

MobileUserService.prototype.browserLogout = function(){
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/logout';
        var resourcepath = Platform.getAuthenticatedURL(url, 'GET', 'cloud');
        $.ajax({
            url: url,
            cache: false,
            success: function(msg) {
                console.log(msg);
            },
            error: function() {
                console.log(LOGOUT_FAILURE);
            }
        });

    }catch(e){
        console.log("function : browser logout"
             + " message" + e.message);
    }
}

MobileUserService.prototype.isLoggedin = function (){
    try{
         var userdata = OBJECT_USER_DATA;
         if(!$.isEmptyObject(userdata)){
            if(userdata[0].hasOwnProperty('isloggedin')){
                var loggedin = userdata[0].isloggedin;
                return loggedin;

            }else{
                return false;
            }
         }else{
            return false;
         }
    } catch (e){

    }
}

MobileUserService.prototype.getLastViewedVideoOfSubject = function(userid, groupid, catid){
    try{
        if(typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0){
            if (typeof VIDEOVIEW_HISTORY_VERSION != "undefined" && VIDEOVIEW_HISTORY_VERSION == "1.0") {
                var videosViewed = Platform.getVideoViewHistoryByType(OBJECT_USER_ID, groupid, catid, "subject", OBJECT_VIDEO);
            }
            else {
                 var videosViewed = Platform.getVideoViewHistoryByType(userid, groupid, catid, "subject");
            }
        }
        else{
            videosViewed = Platform.getVideoViewHistory(userid, groupid, catid, "subject");
        }

        videosViewed = $.parseJSON(videosViewed);
        var videos_length = videosViewed["videoviewed"].length;
        if(videos_length > 0){
            return videosViewed["videoviewed"][videos_length - 1];
        }
        else
        {
            return "";
        }
    }
    catch(e){
        console.log("function : getLastViewedVideoOfSubject"
            + " message" + e.message);
    }
};

MobileUserService.prototype.getVideoViewHistoryCountByType = function(uid,groupid,objid,objtype){
    try{
        groupid = "";
        return Platform.getVideoViewHistoryCountByType(uid,groupid,objid,objtype);
    }
    catch(e){
        console.log( "function : getVideoViewHistoryCountByType"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getVideoViewHistoryCount = function(uid,groupid,objid,objtype){
    try {
        groupid = "";
        if (typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0) {
            return Platform.getVideoViewHistoryCountByType(OBJECT_USER_ID, groupid, objid, objtype);
        }
        else {
            return Platform.getVideoViewHistoryCount(uid, groupid, objid, objtype);
        }
    }
    catch(e){
        console.log( "function : getVideoViewHistoryCount"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.downloadUserProducts = function(refreshProducts){
    var highest_product_expiry = "";
    var userProductsUrl = OBJECT_SERVER_DOMAIN + '/api/getuserproducts?uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED;
    var products = Platform.makeHttpGetCall(userProductsUrl);
    try
    {
        if (!$.isEmptyObject(products))
        {
            products = $.parseJSON(products);
            var status = products['status'];
            if (status == 200)
            {
                var data = products['data'];
                if(data != '' && data.trim() != "null"){
                    var userproducts = $.parseJSON(products['data']);
                    if(userproducts.length == 0 || userproducts == "" || userproducts == null){
                        return "";
                    }
                    if(typeof userproducts[0]["server_time"] != "undefined"){
                        var curTime = new Date().getTime()/1000;
                        timeDiff = (curTime - userproducts[0]["server_time"])/3600;
                        if(timeDiff >= 0.5 || timeDiff <= -0.5){
                            window.location.href = "../html/invaliddate.html";
                            return false;
                        }
                    }
                    OBJECT_USER_PRODUCTS = userproducts;
                    for(var i = 0; i < userproducts.length; i++){
                        var product_expiry = userproducts[i].expiry;
                        if (highest_product_expiry == "") {
                            highest_product_expiry = product_expiry;
                        }
                        else if (highest_product_expiry < product_expiry) {
                            highest_product_expiry = product_expiry;
                        }
                    }

                    if(DATABASE_DOWNLOADED == true || (typeof refreshProducts != 'undefined' && refreshProducts == 'true')){
                        if(typeof highest_product_expiry == "undefined" || highest_product_expiry == ""){
                            var curDate = new Date();
                            var dd = curDate.getDate();
                            var mm = curDate.getMonth();
                            var y = curDate.getFullYear() + 2;
                            var twoyrsdate = y + '-' + ('0' + mm).slice(-2) + '-' + ('0' + dd).slice(-2);
                            Platform.addOrUpdateProductExpiry(twoyrsdate);
                            userproducts[0].expiry = twoyrsdate;
                            Platform.addOrUpdateProductExpiry(twoyrsdate);
                        }
                        else{
                            Platform.addOrUpdateProductExpiry(highest_product_expiry);
                        }
                        Platform.saveObject("userproducts",OBJECT_USER_ID,"content",JSON.stringify(userproducts));
                    }
                    return userproducts;
                }
                else
                {
                    OBJECT_USER_PRODUCTS = "";
                    return '';
                }
            }
            else if (status == 403){
                console.log("error status = " + status + "function = " + downloadUserProducts);
                return 'access forbidden';
            }
            else if (status == 401){
               Platform.addOrUpdateProductExpiry("");
               console.log("error status = " + status + "function = " + downloadUserProducts);
               return 'subscription error';
            }
            else
            {
                console.log("error status = " + status + "function = " + downloadUserProducts);
                return 'error';
            }
        } else {
            console.log("Empty JSON data");
        }
    }
    catch(e){
        console.log("function : downloadUserProducts"
            + " message" + e.message);
    }
};


MobileUserService.prototype.loginUser = function(userjson){
    try{
        var temp = $.parseJSON(userjson);
        if(Platform.getNetworkState() == true){
            var url = "";
            url = OBJECT_SERVER_DOMAIN + '/api/login/user?useractive=true&auth=' + AUTH_ENABLED;
            if (EXEC_MODE == "mobile") {
                userjson = encodeURIComponent(userjson);
            }
            var postdata = userjson;
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200 || status == 201){
                var userdata = user['data'];
                if (APP_TYPE == "ios") {
                    Platform.setPreference("udata", userdata);
                    Platform.setPreference("loginRequired", "false");
                }
                userdata = $.parseJSON(userdata);
                userdata[0].isloggedin = true;
                if(typeof PASSWORD_ENCRYPT != "undefined" && PASSWORD_ENCRYPT != "" && PASSWORD_ENCRYPT == "true"
                        && (typeof(temp.hash) == "undefined" || (typeof(temp.hash) != "undefined" && temp.hash == false)) ){
                    userdata[0].password = Platform.getMD5String(OBJECT_USER_PASSWORD);
                }
                else{
                    userdata[0].password = OBJECT_USER_PASSWORD;
                }
                var uid = userdata[0].uid;
                userdata[0].loginRequiredOnline = false;
                userdata = JSON.stringify(userdata[0]);
                if(typeof DOWNLOAD_DATABASE == "undefined" || DOWNLOAD_DATABASE != true){
                    Platform.saveObject("currentuser", 1, "content", $.trim(userdata));
                    Platform.saveObject("user", uid, "content", $.trim(userdata));
                }
                OBJECT_USER_DATA = $.parseJSON(userdata);
                OBJECT_USER_ID = OBJECT_USER_DATA.uid;
                OBJECT_USER_NAME = OBJECT_USER_DATA.username;
                Platform.setUserID(OBJECT_USER_ID);
                return "true";
            }
            else if(status == 401){
                return t("LOGIN_FAILURE",true);;
            }
            else if(status == 404){
                return t("USER_NOT_REGISTERED",true);
            } else if (status == 405){
                return EMAIL_VERIFY_ERROR_MSG;
            } else if (status == 406) {
                return t("DEVICE_LIMIT_ERROR",true);
            }else if(status == 403){
                return USER_ACCESS_RESTRICTED;
            }
            else if(status == 409){
                return t("USER_LOGGED_IN_DIFF_DEVICE",true);
            }
            else{
                return t("USER_LOGIN_FAILURE",true);
            }
        }
        else{
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : loginUser"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.loginUserWithEncryptedPassword = function(userjson){
    try{
        userjson = $.parseJSON(userjson);
        userjson.hash = true;
        userjson = JSON.stringify(userjson);
        if(Platform.getNetworkState() == true){
            var url = OBJECT_SERVER_DOMAIN + '/api/login/user';
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200 || status == 201){
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                userdata[0].isloggedin = true;
                if(typeof PASSWORD_ENCRYPT != "undefined" && PASSWORD_ENCRYPT != "" && PASSWORD_ENCRYPT == "true"){
                    userdata[0].password = Platform.getMD5String(OBJECT_USER_PASSWORD);
                }
                else{
                    userdata[0].password = OBJECT_USER_PASSWORD;
                }
                var uid = userdata[0].uid;
                userdata = JSON.stringify(userdata[0]);
                if(typeof DOWNLOAD_DATABASE == "undefined" || DOWNLOAD_DATABASE != true){
                    Platform.saveObject("currentuser", 1, "content", $.trim(userdata));
                    Platform.saveObject("user", uid, "content", $.trim(userdata));
                }
                OBJECT_USER_DATA = $.parseJSON(userdata);
                OBJECT_USER_ID = OBJECT_USER_DATA.uid;
                OBJECT_USER_NAME = OBJECT_USER_DATA.username;
                Platform.setUserID(OBJECT_USER_ID);
                return "true";
            }
            else if(status == 401){
                return t("LOGIN_FAILURE",true);;
            }
            else if(status == 404){
                return t("USER_NOT_REGISTERED",true);
            }
            else{
                return t("USER_LOGIN_FAILURE",true);;
            }
        }
        else{
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : loginUserWithEncryptedPassword"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.resetPassword = function(userjson){
    try{
        if(Platform.getNetworkState() == true){
            var url = OBJECT_SERVER_DOMAIN + '/api/forgot/password?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200 || status == 201){
                var data = $.parseJSON(user['data']);
                if(data[0].password){
                    OBJECT_USER_DATA.password = data[0].password;
                    OBJECT_USER_DATA.loginRequiredOffline = true;
                }
                Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
                Platform.saveObject("user", OBJECT_USER_DATA.uid, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
                return "true";
            }
            else if(status == 404){
                return t("USER_NOT_REGISTERED",true);
            }
            else{
                return t("FORGOT_PASSWORD_ERROR",true);
            }
        }
        else{
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : resetPassword"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.loginUserOffline = function(password,userjson){
    try{
        if(Platform.getMD5String(password) != userjson.password){
           return t("LOGIN_FAILURE",true);;
        }
        else
        {
            userjson.loginRequiredOffline = false;
            var userjsonstring = JSON.stringify(userjson);
            Platform.saveObject("currentuser", 1, "content", $.trim(userjsonstring));
            Platform.saveObject("user", userjsonstring.uid, "content", $.trim(userjsonstring));
            OBJECT_USER_DATA = userjson;
            OBJECT_USER_ID = OBJECT_USER_DATA.uid;
            OBJECT_USER_NAME = OBJECT_USER_DATA.username;
            return "true";
        }
    }
    catch(e){
        console.log( "function : loginUserOffline"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.loginThroughBrowser = function(userjson) {
    try{
        if(Platform.getNetworkState() == true){
            if (USE_PORTAL_SERVER_DOMAIN && USE_PORTAL_SERVER_DOMAIN == true) {
                SERVER_DOMAIN = PORTAL_SERVER_DOMAIN;
            }
            else {
                SERVER_DOMAIN = OBJECT_SERVER_DOMAIN;
            }
            var url = SERVER_DOMAIN + '/api/login/user';
            var loginStatus = "";
            $.ajax({
                type: "POST",
                url: url,
                data: userjson,
                xhrFields: {
                    withCredentials: true
                },
                async:false,
                crossDomain: true,
                success: function(data,status) {
                    loginStatus = "true";
                },
                error: function() {
                    loginStatus = t("USER_LOGIN_FAILURE",true);;
                }
            });
        }
        else{
            loginStatus = t("INTERNET_ERROR",true);
        }
        return loginStatus;
    }
    catch(e){
        console.log( "function : loginThroughBrowser"
            + "\n message:" + e.message);
        if(e.message.toLowerCase().indexOf("a network error") >= 0){
            return "true";
        }
    }
};

MobileUserService.prototype.onAccesDenied = function() {
    try{
        if(Platform.getNetworkState() == true){
            Client.showProgressBar(t("Loading") + "");
            setTimeout(function() {
            Client.closeProgressBar();
             $('#wrapper').remove();
             $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
            <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
                OBJECT_USER_DATA.loginRequiredOffline = true;
                Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
                Platform.saveObject("user", OBJECT_USER_DATA.uid, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
                CONTROLLER.loadView("Signin");
            }, 400);
        }
    }
    catch(e){
        console.log( "function : onAccesDenied"
            + "\n message:" + e.message);
        if(e.message.toLowerCase().indexOf("a network error") >= 0){
            return "true";
        }
    }
};

MobileUserService.prototype.setUserAttributes = function(json) {
    try{
        if(Platform.getNetworkState() == true){
            Client.showProgressBar(t("Loading") + "");
            var url = OBJECT_SERVER_DOMAIN + '/api/setuser/attributes?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(json);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            Client.closeProgressBar();
            if (status == 200){
                var newEmail = $.parseJSON(json);
                newEmail = newEmail.mail;
                OBJECT_USER_DATA.email = newEmail
                userjson = JSON.stringify(OBJECT_USER_DATA);
                Platform.saveObject("currentuser", 1, "content", $.trim(userjson));
                Platform.saveObject("user", OBJECT_USER_DATA.uid, "content", $.trim(userjson));
                return true;
            }
            else if(status == 403){
                return t("UNAUTHORIZED_DEVICE_ERROR",true);
            }
            else if(status == 401){
                return t("USER_NOT_REGISTERED",true);
            }
            else if(status == 205){
                return t("USER_ALREADY_EXIST",true);
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : setUserAttributes"
            + "\n message:" + e.message);

    }
};

MobileUserService.prototype.loginRedirect = function(email, path) {
    try {
         var loginsource =Platform.getPreference("loginsource")
        if (Platform.getNetworkState() == true) {
            var emailparam = "";
            if (typeof path == "undefined" || path == "") {
                path = "/loginsucess.html";
            }
            if(typeof email != "undefined"){
                emailparam = '&email=' + email;
            }
            if(typeof loginsource == "undefined" || loginsource == "" ){
                loginsource = "";
            }
            var url = OBJECT_SERVER_DOMAIN + '/api/login/redirect?uid='+OBJECT_USER_ID+ emailparam + '&path=' + path +'&login_source='+ loginsource +'&useractive=true&auth=' + AUTH_ENABLED;
            var user = Platform.makeHttpGetCall(url);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : loginRedirect"
                + "\n message:" + e.message);

    }
};

MobileUserService.prototype.getOffers = function() {
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/get/promo?auth=' + AUTH_ENABLED;
            var useroffers = Platform.makeHttpGetCall(url);
            useroffers = $.parseJSON(useroffers);
            var status = useroffers['status'];
            var offeres = {};
            if (status == 200) {
                offeres['offers'] = $.parseJSON(useroffers['data']);
                return offeres;
            }
            else {
                return false;
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : getOffers"
                + "\n message:" + e.message);

    }
};

MobileUserService.prototype.loginOnline = function() {
    try {
         Client.closeProgressBar();
        if (Platform.getNetworkState() == true) {
            OBJECT_USER_DATA.loginRequiredOnline = true;
            Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
            Platform.saveObject("user", OBJECT_USER_DATA.uid, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
            Platform.showMessage(t("USER_LOGOUT_ERROR",true));
            $('#wrapper').remove();
            $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
             <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
            if($(window).width() > $(window).height() && IS_APP_SHELL){
                Platform.setDeviceOrientation("portrait");
                CONTROLLER.loadView("Signin");
            }
            else{
                CONTROLLER.loadView("Signin");
            }
            return false;
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : loginOnline"
                + "\n message:" + e.message);

    }
};



MobileUserService.prototype.registerProduct = function(userjson){
    try{
        var temp = $.parseJSON(userjson);
        if(Platform.getNetworkState() == true){
            var url = "";
            url = OBJECT_SERVER_DOMAIN + '/api/coupon/subscription';
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200 || status == 201){
                return "true";
            }
             else if (status == 406) {
                return LICENCE_KEY_LIMIT_ERROR_MESSAGE;
            }else{
              return t("ERROR_MESSAGE",true);
            }
        }
        else{
            return t("INTERNET_ERROR",true);
        }
    }
    catch(e){
        console.log( "function : showCouponCodeDialog"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.downloadUserGroups = function(check_group) {
    var settingService = CONTROLLER.getService("Settings");
    var currentuser = settingService.getLastLoggedinUser();
    var user_groups = [];
    ADD_RELATION_ARRAY = [];
    var categoryService = CONTROLLER.getService("Category");
    var groups = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getgroupsforuser?uid=' + OBJECT_USER_ID);
    try {
        groups = $.parseJSON(groups);
        var status = groups['status'];
        if (status == 200)
        {
            var data = $.parseJSON(groups['data']);
            for (var i = 0; i < data.length; i++) {
                user_groups.push(data[i].nid);
                if (typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP) {
                    delete data[i].description;
                    delete data[i].parent;
                }
                var group = Platform.getObjectProperty("group", data[i].nid, "content");
                 Platform.saveObject("group", data[i].nid, "content", JSON.stringify(data[i]));
                if (group == "" || (check_group && check_group == "false") && data[i].type == "user") {
                    categoryService.addCategoriestoUserGroups(data[i].nid);
                }
            }
            if (ADD_RELATION_ARRAY.length > 0) {
                var json = JSON.stringify(ADD_RELATION_ARRAY);
                Platform.addMultipleRelation(json);
                ADD_RELATION_ARRAY = [];
            }
            if (isArray(OBJECT_USER_DATA.groups)) {
                var userGroups = OBJECT_USER_DATA.groups;
            }else{
                var userGroups = [];
                userGroups.push(OBJECT_USER_DATA.groups);
            }
                OBJECT_USER_DATA.groups = user_groups;
                OBJECT_USER_GROUPS = user_groups;
                if (currentuser != "") {
                    currentuser.groups = user_groups;
                    Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(currentuser)));
                    Platform.saveObject("user", currentuser.uid, "content", $.trim(JSON.stringify(currentuser)));
                }
                this.getUserGroupsData();
        }
    }
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getgroupsforuser?uid=' + OBJECT_USER_ID
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    };
};

MobileUserService.prototype.getUserGroupsData = function() {
    try {
        var userService = CONTROLLER.getService("User");
        var batches = {}; batches['batches'] = []; var usergroups = [];
        var userbatches = []; var allUserBatches = {}; var contentGroups = [];
        var parentGroups = []; allUserBatches['batches'] = [];
          for (var i = 0; i < OBJECT_USER_GROUPS.length; i++) {
            var usergroups = $.parseJSON(Platform.getObjectProperty(OBJECT_GROUP, OBJECT_USER_GROUPS[i], "content"));
            if(usergroups.type != "content" && usergroups.status != '0'){
                 batches['batches'].push(usergroups);
                 userbatches.push(usergroups.nid);
                 parentGroups.push(usergroups.parent);
            }
            else if(usergroups.type == "content"){
                contentGroups.push(usergroups);
            }
        }
          for (var i = 0; i < batches['batches'].length; i++) {
            if ($.inArray(batches['batches'][i].nid, parentGroups) < 0) {
                allUserBatches['batches'].push(batches['batches'][i]);
            }
        }
        CONTROLLER.setVariable("activebatches", allUserBatches);
        CONTROLLER.setVariable("contentGroups", contentGroups);
        return allUserBatches;
    }
    catch (e) {
        console.log("function :- getUserGroupsData " + e.message);
    }
}
MobileUserService.prototype.getBannersInfo = function (url,callback)
{
    try {
        if(typeof HIDE_GROUPS_FILTER != 'undefined' && HIDE_GROUPS_FILTER == "false" && typeof GROUP_SPECIFIC_BANNERS != 'undefined' && GROUP_SPECIFIC_BANNERS == true){
            var resourceService = CONTROLLER.getService("Resource");
            var url = OBJECT_SERVER_DOMAIN + '/api/get/groupbanners?groups='+OBJECT_USER_GROUPS.join(',');
            if (typeof BANNERSINFO[OBJECT_USER_ID] == "undefined" || (typeof BANNERSINFO[OBJECT_USER_ID] !== "undefined" && $.isEmptyObject(BANNERSINFO[OBJECT_USER_ID]))){
                var data = resourceService.getObjectContentProperties(BANNERS_INFO, OBJECT_USER_ID);
                data = $.parseJSON(data);
                if (data == null || data == "" || typeof data == "undefined") {
                    if (Platform.getNetworkState() == true) {
                        onAsyncGetBannersInfoComplete = function (result)
                        {
                            if (typeof result["status"] != 'undefined' && result["status"] == '200') {
                                var userdata = result['data'];
                                userdata = $.parseJSON(userdata);
                                BANNERSINFO[OBJECT_USER_ID] = userdata;
                                Platform.saveObject(BANNERS_INFO, OBJECT_USER_ID, 'content', JSON.stringify(userdata));
                                return callback(userdata);
                            }
                            else {
                                return callback(ERROR_MESSAGE);
                            }
                        }
                        makeAsyncTaskCall("", "", url, "get", "false", "onAsyncGetBannersInfoComplete");
                    } else {
                        return INTERNET_ERROR;
                    }
                }else{
                    if (typeof callback != 'undefined' && typeof callback == 'function') {
                        setTimeout(function(){
                            if (Platform.getNetworkState() == true) {
                                onAsyncGetBannersInfoComplete = function (result)
                                {
                                    if (typeof result["status"] != 'undefined' && result["status"] == '200') {
                                        var userdata = result['data'];
                                        userdata = $.parseJSON(userdata);
                                        BANNERSINFO[OBJECT_USER_ID] = userdata;
                                        Platform.saveObject(BANNERS_INFO, OBJECT_USER_ID, 'content', JSON.stringify(userdata));
                                    }
                                }
                                makeAsyncTaskCall("", "", url, "get", "false", "onAsyncGetBannersInfoComplete");
                            }
                        },200);
                        return callback(data);
                    } else {
                        return data;
                    }
                }
            }else{
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(BANNERSINFO[OBJECT_USER_ID]);
                } else {
                    return BANNERSINFO[OBJECT_USER_ID];
                }
            }
        }else{
            if (Platform.getNetworkState() == true) {
                onAsyncGetBannersInfoComplete = function (result)
                {
                    if (typeof result["status"] != 'undefined' && result["status"] == '200') {
                        var userdata = result['data'];
                        userdata = $.parseJSON(userdata);
                        return callback(userdata);
                    }
                    else {
                        return callback(ERROR_MESSAGE);
                    }
                }
                makeAsyncTaskCall("", "", url, "get", "false", "onAsyncGetBannersInfoComplete");
            } else {
                return INTERNET_ERROR;
            }
        }
    }
    catch (e) {
        console.log("function : getBannersInfo"
                + "\n message:" + e.message);
    }
};
MobileUserService.prototype.getAiatsSolutionInfo = function (url)
{
    try {
        if (Platform.getNetworkState() == true) {
            var result = Platform.makeHttpGetCall(url);
            result = $.parseJSON(result);
            var status = result['status'];
            if (status == 200 || status == 201){
                result = $.parseJSON(result['data']);
                return result;
            }else{
              return t("ERROR_MESSAGE",true);
            }

        }else{
           return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : getAiatsSolutionInfo"
                + "\n message:" + e.message);
    }
};
MobileUserService.prototype.generateOtp = function (userjson)
{
    try {
        var response = {};
        if (Platform.getNetworkState() == true) {
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/generate/otp?uid=' + OBJECT_USER_ID;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            response['status'] = status;
            if (status == 200) {
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                response['data'] = userdata;
                return response;
            } else if (status == 405) {
                response['data'] = MOBILE_NO_NOT_VERIFIED_MESSAGE;
                return response;
            } else if (status == 403) {
                response['data'] = USER_HAS_MORE_THEN_ONE_EMAIL_ID_MESSAGE;
                return response;
            } else if (status == 401) {
                response['data'] = t("USER_HAS_BLOCKED_MESSAGE",true);
                return response;
            } else if (status == 404) {
                response['data'] = EMAIL_ID_DOES_NOT_EXIST_MESSAGE;
                return response;
            } else if (status == 411) {
                response['data'] = INVALID_MOBILE_NUMBER_MESSAGE;
                return response;
            } else if (status == 409) {
                response['data'] = MOBILE_NO_ASSOCIATED_WITH_MULTIPLE_ACC_MESSAGE;
                return response;
            } else if (status == 406) {
                response['data'] = MOBILE_NO_ALREADY_EXIST;
                return response;
            } else {
                response['data'] = t("ERROR_MESSAGE",true);
                return response;
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : generateOtp"
                + "\n message:" + e.message);
    }
};

MobileUserService.prototype.validateOtp = function (userjson)
{
    try {
        var response = {};
        if (Platform.getNetworkState() == true) {
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/verify/otp?uid=' + OBJECT_USER_ID +'&otp=' + USER_OTP +'&auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            response['status'] = status;
            if (status == 200) {
                return response;
            }
            else if (status == 403) {
                response['data'] = OTP_EXPIRED_MESSAGE;
                return response;
            } else if (status == 401) {
                response['data'] = OTP_DOES_NOT_MATCH_MESSAGE;
                return response;
            } else if (status == 406) {
                response['data'] = MOBILE_NO_ALREADY_EXIST;
                return response;
            } else if (status == 409) {
                response['data'] = MOBILE_NO_ASSOCIATED_WITH_MULTIPLE_ACC_MESSAGE;
                return response;
            } else {
                response['data'] = t("ERROR_MESSAGE",true);
                return response;
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : validateOtp"
                + "\n message:" + e.message);
    }
};
MobileUserService.prototype.forgotPassword = function (userjson)
{
    try {
        var response = {};
        if (Platform.getNetworkState() == true) {
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/forgot/password?uid=' + OBJECT_USER_ID;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            response['status'] = status;
            if (status == 200) {
                return response;
            }
            else if (status == 404) {
                response['data'] = t("USER_NOT_REGISTERED",true);
                return response;
            } else if (status == 401) {
                response['data'] = t("USER_HAS_BLOCKED_MESSAGE",true);
                return response;
            } else {
                response['data'] = t("ERROR_MESSAGE",true);
                return response;
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : forgotPassword"
                + "\n message:" + e.message);
    }
};

MobileUserService.prototype.sendMail = function(to ,subject, mailcontent){
    if(Platform.getNetworkState()){
        var data = "{";
        var temp = "";
        temp = ' "to" : "' + to + '", ';
        temp = temp + ' "subject" : "' + subject + '", ';
        temp = temp + ' "body" : "' + mailcontent + '" ';
        data = data + temp + "}";

        var url = OBJECT_SERVER_DOMAIN + '/api/send/custom/mail?auth='+ AUTH_ENABLED;
        var postdata = encodeURIComponent(data);
        var status = Platform.makeHttpPostCall(url, postdata);
        status = $.parseJSON(status);
        if (status.status == 200) {
            return true;
        }
        else if (status.status == 400) {
            return "error while sending mail";
        }
        else if (status.status == 403) {
            return "Not Authorized to access callback.";
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }else{
        return t("INTERNET_ERROR",true);
    }
}


MobileUserService.prototype.changePassword = function(currentpassword,newpassword){
    try{
//made it auth call only for mobile since the user will not be logged in to change password as per the new flow/design
         var url = OBJECT_SERVER_DOMAIN + '/api/chgpwd?auth=' + AUTH_ENABLED;
         var data = "{";
         var temp ="";
         temp = ' "uid" : "' + OBJECT_USER_ID + '", ';
         temp = temp + ' "currentpassword" : "' + currentpassword + '", ';
         temp = temp + ' "newpassword" : "' + newpassword + '" ';
         data = data + temp + "}";
         var user = Platform.makeHttpPostCall(url, data);
         user = $.parseJSON(user);
         var status = user['status'];
         if (status == 200){
            var userdata = $.parseJSON(Platform.getObjectProperty("currentuser",1,"content"));
            if(typeof userdata != 'undefined' && userdata != "" && userdata != null){
                if(typeof PASSWORD_ENCRYPT != "undefined" && PASSWORD_ENCRYPT != "" && PASSWORD_ENCRYPT == "true" ){
                    userdata.password = Platform.getMD5String(newpassword);
                }
                else{
                    userdata.password = newpassword;
                }
                var uid = userdata.uid;
                userdata = JSON.stringify(userdata);
                Platform.saveObject("currentuser", 1, "content", $.trim(userdata));
                Platform.saveObject("user", uid, "content", $.trim(userdata));
            }
             
             return "true";
         }
         else if(status == 422){
             return WRONG_CURRENT_PASSWORD;
         }
         else{
             return ERROR_MESSAGE;
         }
     }
     catch(e){
         console.log( "function : changePassword"
             + "\n message:" + e.message);
     }
 };

MobileUserService.prototype.getUserRole = function (callback) {
    try {
        var url = OBJECT_SERVER_DOMAIN + '/api/get/userrole';
        onAsyncGetUserRoleComplete = function (role) {
            var result = '';
            var status = role['status'];
            if (status == 200) {
                var roleData = $.parseJSON(role["data"]);
                result = roleData;
            } else if (status == 403) {
                onAccesDenied();
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetUserRoleComplete");
        } else {
            var role = Platform.makeHttpGetCall(url);
            return onAsyncGetUserRoleComplete($.parseJSON(role));
        }
    } catch (e) {
        console.log("getUserRole : " + e.message);
    }
};

MobileUserService.prototype.getGroupsForRegistration = function(){
    try{
        if(Platform.getNetworkState()){
        var url = OBJECT_SERVER_DOMAIN + '/api/get/groupsforregistration?auth='+ AUTH_ENABLED;
        var status = Platform.makeHttpGetCall(url);
        status = $.parseJSON(status);
        var data = $.parseJSON(status['data']);
        return data;
    }else{
        return INTERNET_ERROR;
    }
    }catch(e){
        console.log("function : getGroupsForRegistration"
                + "\n message:" + e.message);
    }
}
 
 MobileUserService.prototype.sendOtpSms = function (userjson)
{
    try {
        var response = {};
        if (Platform.getNetworkState() == true) {
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/user/sms/otpsend?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            response['status'] = status;
            if (status == 200) {
                return response;
            } 
            else if(status == 404){
                response['data'] = USER_DOES_NOT_EXIST;
                return response;
            }else if(status == 403){
                onAccessDenied();
                return false;
            }
            else {
                response['data'] = ERROR_MESSAGE;
                return response;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : sendOtpSms"
                + "\n message:" + e.message);
    }
};

MobileUserService.prototype.validateSmsOtp = function (userjson)
{
    try {
        var response = {};
        if (Platform.getNetworkState() == true) {
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/user/sms/otpverify?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            response['status'] = status;
            if (status == 200) {
                return response;
            } else if(status == 401){
                response['data'] = OTP_DOES_NOT_MATCH_MESSAGE;
                return response;
            } else if (status == 403) {
                onAccessDenied();
                return false;
            } else if (status == 404) {
                response['data'] = USER_DOES_NOT_EXIST;
                return response;
            } else {
                response['data'] = ERROR_MESSAGE;
                return response;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : validateOtp"
                + "\n message:" + e.message);
    }
};
MobileUserService.prototype.sendOtpEmail = function (userjson)
{
    try {
        var response = {};
        if (Platform.getNetworkState() == true) {
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/user/email/otpsend?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            response['status'] = status;
            if (status == 200) {
                return response;
            } 
            else if(status == 404){
                response['data'] = USER_DOES_NOT_EXIST;
                return response;
            }else if(status == 403){
                onAccessDenied();
                return false;
            }
            else {
                response['data'] = ERROR_MESSAGE;
                return response;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : sendOtpSms"
                + "\n message:" + e.message);
    }
};

MobileUserService.prototype.validateEmailOtp = function (userjson)
{
    try {
        var response = {};
        if (Platform.getNetworkState() == true) {
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/user/email/otpverify?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            response['status'] = status;
            if (status == 200) {
                return response;
            } else if(status == 401){
                response['data'] = OTP_DOES_NOT_MATCH_MESSAGE;
                return response;
            } else if (status == 403) {
                onAccessDenied();
                return false;
            } else if (status == 404) {
                response['data'] = USER_DOES_NOT_EXIST;
                return response;
            } else {
                response['data'] = ERROR_MESSAGE;
                return response;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : validateOtp"
                + "\n message:" + e.message);
    }
};
MobileUserService.prototype.updateUsername = function(userjson)
{
    try{
        if(Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/update/username?auth=' + AUTH_ENABLED;
            var postdata = userjson;
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                var userdata = user['data'];
                if(userdata.trim() != ""){
                    userdata = $.parseJSON(userdata);
                }
                return "true";
            }
            else {
                return status;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : updateUsername"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getClientConfig = function() {
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/clientConfig?auth=' + AUTH_ENABLED;
            var clientConfig = Platform.makeHttpGetCall(url);
            clientConfig = $.parseJSON(clientConfig);
            var status = clientConfig['status'];
            var config = "";
            if (status == 200) {
                config = $.parseJSON(clientConfig['data']);
                return config;
            }
            else {
                return false;
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : getClientConfig"
                + "\n message:" + e.message);

    }
};

MobileUserService.prototype.generate_fgp_Otp = function (userjson)
{
    try {
        var response = {};
        var url = "";
        if (Platform.getNetworkState() == true) {
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            url = OBJECT_SERVER_DOMAIN + '/api/sendotp?auth=' + AUTH_ENABLED; 
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            response['status'] = status;
            if (status == 200) {
                var userdata = user['data'];
                userdata = $.parseJSON(userdata.trim());
                response['data'] = userdata;
                return response;
            } else if (status == 405) {
                response['data'] = MOBILE_NO_NOT_VERIFIED_MESSAGE;
                return response;
            } else if (status == 403) {
                response['data'] = USER_HAS_MORE_THEN_ONE_EMAIL_ID_MESSAGE;
                return response;
            } else if (status == 401) {
                response['data'] = USER_HAS_BLOCKED_MESSAGE;
                return response;
            } else if (status == 404) {
                response['data'] = EMAIL_ID_DOES_NOT_EXIST_MESSAGE;
                return response;
            } else if (status == 411) {
                response['data'] = INVALID_MOBILE_NUMBER_MESSAGE;
                return response;
            } else if (status == 409) {
                response['data'] = MOBILE_NO_ASSOCIATED_WITH_MULTIPLE_ACC_MESSAGE;
                return response;
            } else if (status == 406) {
                response['data'] = MOBILE_NO_ALREADY_EXIST;
                return response;
            } else {
                response['data'] = ERROR_MESSAGE;
                return response;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : generateOtp"
                + "\n message:" + e.message);
    }
};

MobileUserService.prototype.validate_fgp_Otp = function (userjson)
{
    try {
        var response = {};
        if (Platform.getNetworkState() == true) {
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            url = OBJECT_SERVER_DOMAIN + '/api/verifyotp?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            response['status'] = status;
            if (status == 200) {
                return response;
            }
            else if (status == 403) {
                response['data'] = OTP_EXPIRED_MESSAGE;
                return response;
            } else if (status == 401) {
                response['data'] = OTP_DOES_NOT_MATCH_MESSAGE;
                return response;
            } else if (status == 406) {
                response['data'] = MOBILE_NO_ALREADY_EXIST;
                return response;
            } else if (status == 409) {
                response['data'] = MOBILE_NO_ASSOCIATED_WITH_MULTIPLE_ACC_MESSAGE;
                return response;
            } else {
                response['data'] = ERROR_MESSAGE;
                return response;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : validateOtp"
                + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getCurrentUserProperties = function(){
    try{
        if(CURRENT_USER_PROPERTIES.hasOwnProperty('currentuser') == false){
            var data = Platform.getObjectProperty("currentuser",1,"content")
            CURRENT_USER_PROPERTIES['currentuser'] = data;
            return data;
        }else{
            return CURRENT_USER_PROPERTIES['currentuser'];
        }
    }catch(e){
        console.log("getCurrentUserProperties " + e.message);
    }
}

MobileUserService.prototype.getUserProfileProperties = function(){
    try{
        if(USER_PROFILE_PROPERTIES.hasOwnProperty("userProfile") == false){
            var data = Platform.getObjectProperty("userProfile", OBJECT_USER_ID, "content");
            USER_PROFILE_PROPERTIES["userProfile"] = data;
            return data;
        }else{
            return USER_PROFILE_PROPERTIES["userProfile"];
        }
    }catch(e){
        console.log("getUserProfileProperties " + e.message);
    }
}


MobileUserService.prototype.validateCoupon = function (coupon)
{
    try {
        var response = {};
        if (Platform.getNetworkState() == true) {
            coupon = $.parseJSON(coupon);
            coupon = JSON.stringify(coupon);
            if(typeof CHATBOT_CLIENT_NAME_MAPPING != "undefined" && CHATBOT_CLIENT_NAME_MAPPING != ""){
                var url = OBJECT_SERVER_DOMAIN +"/api/ejaypee/validate/coupon" + "?client_name=" + CHATBOT_CLIENT_NAME_MAPPING[CHATBOT_CATEGORY_NID]
            }else{
                var url = OBJECT_SERVER_DOMAIN +"/api/ejaypee/validate/coupon";
            }
            var postdata = encodeURIComponent(coupon);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            coupon = $.parseJSON(coupon);
            var status = user['status'];
            response['status'] = status;
            return response; 
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : validateCoupon"
                + "\n message:" + e.message);
    }
};


MobileUserService.prototype.loginRedirectAjax= function(email, path, asynctype=true) {
    try {
         var loginsource =Platform.getPreference("loginsource")
        if (Platform.getNetworkState() == true) {
            var emailparam = "";
            if (typeof path == "undefined" || path == "") {
                path = "/loginsucess.html";
            }
            if(typeof email != "undefined"){
                emailparam = '&email=' + email;
            }
            if(typeof loginsource == "undefined" || loginsource == "" ){
                loginsource = "";
            }
            var url = OBJECT_SERVER_DOMAIN + '/api/login/redirect?uid='+OBJECT_USER_ID+ emailparam + '&path=' + path +'&login_source='+ loginsource +'&useractive=true&auth=' + AUTH_ENABLED;
            url = Platform.getAuthenticatedURL(url, 'GET', 'cloud');
            var user = makeAjaxCall(url,'',asynctype)
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : loginRedirectAjax"
                + "\n message:" + e.message);

    }
};


//api for the customized forgot password flow, where user resets the password by authenticating through OTP.
MobileUserService.prototype.sendOtpToChangePassword = function(userjson){
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/user/frgtpwd/otpsend?auth='+ AUTH_ENABLED;

        userjson = JSON.stringify(userjson);
        var postdata = encodeURIComponent(userjson);
        var user_otp = Platform.makeHttpPostCall(url, postdata);
        user_otp = $.parseJSON(user_otp);
        var status = user_otp['status'];
        if (status == 200){
            return user_otp;
        }else if(status == 404){
            user_otp['data'] = USER_DOES_NOT_EXIST;
            return user_otp;
         }
         else{
            user_otp['data'] = ERROR_MESSAGE;
            return user_otp;
         }
        
    }catch(e){
        console.log( "function : sendOtpToChangePassword"
             + "\n message:" + e.message);
    }
}
//api for the customized forgot password flow, where user resets the password by authenticating through OTP.
MobileUserService.prototype.verifyOtpToChangePassword = function (userjson)
{
    try {
        var response = {};
        if (Platform.getNetworkState() == true) {
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/user/frgtpwd/otpverify?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            response['status'] = status;
            if (status == 200) {
                return response;
            } else if(status == 401){
                response['data'] = OTP_DOES_NOT_MATCH_MESSAGE;
                return response;
            } else if (status == 404) {
                response['data'] = USER_DOES_NOT_EXIST;
                return response;
            } else {
                response['data'] = ERROR_MESSAGE;
                return response;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : verifyOtpToChangePassword"
                + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getCourseCertificateStatus = function(callback){
    var url = OBJECT_SERVER_DOMAIN + '/api/subscribed/'+ OBJECT_CATEGORIES
    try {
        onAsyncGetCertificateStatus = function (response) {
            var result = '';
            var status = response['status'];
            if (status == 200) {
                result = $.parseJSON(response.data);
            } else {
                result = false;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetCertificateStatus");
        } else {
            var response = Platform.makeHttpGetCall(url);
            return onAsyncGetCertificateStatus($.parseJSON(response));
        }
    } catch (e) {
        var error = "url :" + url
            + "\n message :" + e.message;
        console.log(error);
    }
}
function UtilsService(){};

UtilsService.prototype.getDataFromExternalFileSystem = function(url,callback){
    try {
        $.ajax({
            url: url,
            dataType : 'text',
            type:'GET',
            async: true,
            crossDomain: true,
            contentType: 'text/plain',
            success: callback,
            error: function(xhr, status, error) {
                var err = eval("(" + xhr.responseText + ")");
                console.log(err.Message);
            }
        });
    }
    catch (e)
    {
        var error = "url :" + url
        + "\n message :" + e.message;
        console.log(error);
    };
}

function replaceAll(find, replace, str) {
  return str.replace(new RegExp(find, 'g'), replace);
}
function MobileQuizService() {
}
;

MobileQuizService.prototype.getAllQuiz = function() {

    try {
        var quiz = Platform.getObjectsOfType('quiz');
        return  $.parseJSON(quiz);
    }
    catch (e) {
        console.log("function : getAllQuiz"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.downloadQuiz = function (quizdata, download_complete_url, downloadmessage) {

    try {
        var contentUtils = CONTROLLER.getService("ContentUtils");
        var nid = quizdata.nid;
        var downloadurl = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_QUIZ + '?quizid=' + nid + "&auth=" + AUTH_ENABLED;
        var filepath = "video/" + nid + "/" + nid + '_' + quizdata.changed + ".zip";
        var paramsJson = {'objectid': nid, 'objecttype': 'resource',
            'serverurl': downloadurl, 'localurl': filepath, 'message': downloadmessage,
            'sync': FILE_DOWNLOAD_ASYNC, 'expiryduration': "",
            'onComplete': "", 'youtubeid': quizdata.changed, 'format': "", 'type': 'content'};
        Platform.downloadFile(JSON.stringify(paramsJson));
        contentUtils.showProgressBar(nid);
        contentUtils.showInProgressMessage(quizdata);
    }
    catch (e) {
        console.log(e.message);
    }
};

MobileQuizService.prototype.getAllModuleQuizzes = function () {

    try {
        var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_COURSE + OBJECT_QUIZ + "?uid=" + OBJECT_USER_ID);
        quiz = $.parseJSON(quiz);
                    var status = quiz['status'];
                    if (status == 200)
                    {
                        var data = '{"quiz":'+quiz['data'].trim()+'}';
                        quiz = $.parseJSON(data);
                    }
                    else{
                        quiz = "";
                    }
                    return quiz;
    }
    catch (e) {
        console.log(e.message);
    }
};

MobileQuizService.prototype.getAllQuizzes = function (groups, type, always_available, limit, page, searchText, callback) {
    if (typeof page != "undefined") {
        page = "&page=" + page;
    } else {
        page = "";
    }
    if (typeof limit != "undefined") {
        limit = "&limit=" + limit;
    } else {
        limit = "";
    }
    if(searchText) {
        searchText = "&text=" + searchText;
    } else {
        searchText = "";
    }
    var url = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_QUIZ + "?uid=" + OBJECT_USER_ID + "&groups=" + groups + '&type=' + type + '&always_available=' + always_available + limit + page + searchText;
    try {
        onAsyncGetAllQuizzesComplete = function (response) {
            var result = '';
            var status = response['status'];
            if (status == 200) {
                var data = '{"quiz":' + response['data'].trim() + '}';
                result = $.parseJSON(data);
            } else {
                result = false;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetAllQuizzesComplete");
        } else {
            var response = Platform.makeHttpGetCall(url);
            return onAsyncGetAllQuizzesComplete($.parseJSON(response));
        }
    } catch (e) {
        var error = "url :" + url
            + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getQuestionsForQuiz = function (quiz_nid, quiz_changed, question_number_index ='1', use_downloaded_quiz=true)
{
    var questions = "";

    var contentUtils = CONTROLLER.getService("ContentUtils");
    try {
            var isFileAvailable = contentUtils.isFileAvailable(quiz_nid, quiz_changed, "", "quiz");
            $("#quiz_nid" + quiz_nid).css("opacity", "1");
            if ((isFileAvailable.filestatus == "Available") && (isFileAvailable.version == "new" || isFileAvailable.version == "old")
            && typeof use_downloaded_quiz != 'undefined' && use_downloaded_quiz)
            {
                if (typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0) {
                    var localpath = Platform.getLocalServerPath();
                    quiz_changed = isFileAvailable.youtubeid;
                    if (typeof GET_DATA_FROMFILESYSTEM != 'undefined' && GET_DATA_FROMFILESYSTEM == 'true') {
                        var filepath = "/video/" + quiz_nid + "/" + quiz_nid + '_' + quiz_changed + ".quiz";
                        questions = Platform.getDataFromFileSystem(filepath);
                    } else {
                        var filepath = "/video/" + quiz_nid + "/" + quiz_nid + '_' + quiz_changed + ".quiz";
                        questions = Platform.getDataFromInternalFileSystem(filepath);
                    }

                    if (questions == "")
                    {
                        Client.closeProgressBar();
                        alert(t("ERROR_MESSAGE"),true);
	                }
	                else{
                        questions = $.parseJSON(questions);
                    }

	            }
	            else {
                    if (isFileAvailable.youtubeid == "") {
                        var filepath = "video/" + quiz_nid + "/" + quiz_nid + ".quiz";
                    } else {
                        if (isFileAvailable.version == "old") {
                            quiz_changed = isFileAvailable.youtubeid;
                        }
                        var filepath = "video/" + quiz_nid + "/" + quiz_nid + '_' + quiz_changed + ".quiz";
                    }
                    questions = Platform.getDataFromFileSystem(filepath);
                }
            CONTROLLER.setVariable("resourcevvhmode",0);
        }
        else if(questions == "" && (USER_PRODUCT_TYPE == "online" || USER_PRODUCT_TYPE == "hybrid")){
            if (Platform.getNetworkState() == true) {
                if(typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION){
                    var questions = this.getQuizQuestionFromNumber(quiz_nid, question_number_index);
                }else{
                    var questions = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUESTION + 'sfor' + OBJECT_QUIZ + '?nid=' + quiz_nid);
                }
                try {
                    questions = $.parseJSON(questions);
                    var status = questions['status'];
                    if (status == 200)
                    {
                        var data = '{"question":' + questions['data'].trim() + '}';
                        questions = this.updateQuestionLocale($.parseJSON(data));
                    }
                    else{
                        questions = "";
                    }
                    // Commented for performance optimization
                    // Platform.saveDataToFileSystem("video/"+quiz_nid+"/"+quiz_nid+".quiz",questions['data']);
                    CONTROLLER.setVariable("resourcevvhmode",1);
                    if (EXEC_MODE == "online") {
                        questions = fixURLsWithoutProtocol(questions);
                    }
                    return questions;
                }
                catch (e)
                {
                    var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUESTION+'sfor'+OBJECT_QUIZ+'?nid='+quiz_nid
                    + "\n error_code :" + status
                    + "\n response_data :" + questions['data']
                    + "\n message :" + e.message;
                    console.log(error);
                };
            }
            else{
                if (questions == "") {
                    var questions_for_quiz = "questions_for_quiz_" + quiz_nid;
                    var question_meta = Platform.getPreference(questions_for_quiz);
                    question_meta = $.parseJSON(question_meta);
                    if (question_meta != null && question_meta != "" && typeof question_meta != "undefined") {
                        questions = question_meta;
                        return questions;
                    }else{
                        alert(t("INTERNET_ERROR",true));
                    }
                }
                // alert(t("INTERNET_ERROR",true));
            }
        }
        if (questions == "") {
            var questions_for_quiz = "questions_for_quiz_" + quiz_nid;
            var question_meta = Platform.getPreference(questions_for_quiz);
            question_meta = $.parseJSON(question_meta);
            if (question_meta != null && question_meta != "" && typeof question_meta != "undefined") {
                questions = question_meta;
                return questions;
            }else{
                return "";
            }
        }
        if (isFileAvailable.filestatus == "Available") {
            if (typeof questions == 'string') {
	            questions = $.parseJSON(questions);
	            if (typeof questions == 'string') {
	                questions = $.parseJSON(questions);
	            }
            }
            questions = JSON.stringify(questions);
            var filepath = Platform.getLocalServerPath() +"/video/"+quiz_nid+"/" ;
            questions=replaceAll(STATIC_SERVER+"/",filepath,questions);

            var staticPath = STATIC_SERVER.substr(STATIC_SERVER.indexOf('//'));
            var localFilePath = filepath.substr(filepath.indexOf('//'));
            questions = replaceAll(staticPath+"/", localFilePath, questions);
            questions = replaceAll(OBJECT_SERVER_DOMAIN.substr(OBJECT_SERVER_DOMAIN.indexOf('//')) +  '/' , filepath, questions )
            questions = replaceAll("//" + OBJECT_SERVER_DOMAIN  + "/modules/mtp_quiz/image/play_video.png","/platform/images/play_video.90d2066a.png",questions);
            questions = replaceAll("//" + OBJECT_SERVER_DOMAIN +"/modules/quiz/images/no_audio_banner.jpg","/platform/images/no_audio_banner.233fa6fd.png",questions);
            if(typeof OLD_STATIC_SERVER != "undefined"){
                if (OLD_STATIC_SERVER instanceof Array) {
                    for (var i = 0; i < OLD_STATIC_SERVER.length; i++) {
                        var oldStaticPath = OLD_STATIC_SERVER[i].substr(OLD_STATIC_SERVER[i].indexOf('//'));
                        questions = replaceAll(oldStaticPath+"/", localFilePath, questions);
                    }
                }
                else{
                    var oldStaticPath = OLD_STATIC_SERVER.substr(OLD_STATIC_SERVER.indexOf('//'));
                    questions = replaceAll(oldStaticPath+"/", localFilePath, questions);
                }
            }
            questions = replaceAll('https:', '', questions);
        }

        questions = '{"question":' + $.trim(questions) + '}';
        questions = $.parseJSON(questions);
        questions['question'] = questions['question']['questions'] ? questions['question']['questions'] : questions['question'];
        questions = this.updateQuestionLocale(questions);  
        if (EXEC_MODE == "online") {
            questions = fixURLsWithoutProtocol(questions);
        }
        return questions;
    }
    catch (e) {
        console.log("function : getQuestionsForQuiz"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.updateQuestionLocale = function (questions) {
    for (var i in questions['question']) {
        var currentQuestion = $.extend(true,{},questions['question'][i]);
        var updatedLocale = {};
        if(typeof currentQuestion.language == "undefined"){
            currentQuestion.language = [];
            currentQuestion.language[0] = ENGLISH_LANGID;
        }
        updatedLocale[currentQuestion.language[0]] = $.extend(true, {}, currentQuestion);
        updatedLocale[currentQuestion.language[0]].locale = {};
        updatedLocale[currentQuestion.language[0]].localeQuestionId = currentQuestion.nid;

		if(typeof currentQuestion.locale != 'undefined'){
			for (var j in currentQuestion.locale) {
                if (j) {
                    updatedLocale[j] = $.extend(true, {}, currentQuestion);

                    var currentQuestionLocale = currentQuestion.locale[j];
                    updatedLocale[j].title = currentQuestionLocale.title;
                    updatedLocale[j].body = currentQuestionLocale.body;
                    updatedLocale[j].hints = currentQuestionLocale.hints;
                    updatedLocale[j].solution = currentQuestionLocale.solution;
                    updatedLocale[j].localeQuestionId = currentQuestionLocale.nid;

                    if (currentQuestion.type == "matching") {
                        for (var k in currentQuestion.match) {
                            if(typeof updatedLocale[j].match[k] != "undefined" && typeof currentQuestionLocale.match[k] != "undefined"){
                                updatedLocale[j].match[k].question = currentQuestionLocale.match[k].question;
                                updatedLocale[j].match[k].answer = currentQuestionLocale.match[k].answer;
                                updatedLocale[j].match[k].feedback = currentQuestionLocale.match[k].feedback;
                            }
                        }
                    } else if (currentQuestion.type == "short_answer") {
                        if (currentQuestion.correct_answer_evaluation == '5') {
                            updatedLocale[j].answer_list = currentQuestionLocale.answer_list;
                            updatedLocale[j].correct_answer = currentQuestionLocale.correct_answer;
                        }
                    } else if (currentQuestion.type == "multichoice") {
                        for (var k in currentQuestion.alternatives) {
                            // Check if the Localized options are available. 
                            // If not then display the English options. 
                            if(updatedLocale[j].alternatives.length != currentQuestionLocale.alternatives.length){
                                var currentAltLength = currentQuestionLocale.alternatives.length;
                                currentQuestionLocale.alternatives[currentAltLength] = updatedLocale[j].alternatives[currentAltLength];
                            }
                            updatedLocale[j].alternatives[k].answer = currentQuestionLocale.alternatives[k].answer;
                        }
                    }
                    updatedLocale[j].locale = {};
                }
	        }
		}
        questions['question'][i]['updatedLocale'] = updatedLocale;
    }
    return questions;
};

MobileQuizService.prototype.getQuestionLocaleInfo = function (language, activeLang) {
    var settingService = CONTROLLER.getService('Settings');
    if(typeof OBJECT_FROM_ID.lang =="undefined"){
        var allLanguages = settingService.getAllLanguages();
        if(typeof allLanguages['lang'] != 'undefined'){
            allLanguages =allLanguages['lang']
            OBJECT_FROM_ID.lang= allLanguages;
        }
    }else{
        var allLanguages = OBJECT_FROM_ID.lang
    }
    var langInfo = [];
    for (var i in allLanguages) {
        for (var j in language) {
            if (allLanguages[i].tid === language[j]) {
                var selected = '';
                if (activeLang === language[j])
                    selected = 'selected';
                langInfo.push({ 'tid': language[j], 'name': allLanguages[i].name, 'selected': selected });
                break;
            }
        }
    }
    return langInfo;
};

MobileQuizService.prototype.getActiveQuizzes = function (nid, groups, page, limit, callback) {
    var quizjson = {};
    if(typeof page != "undefined"){
        page = "&page=" + page;
    }
    else{
        page = "";
    }
    if(typeof limit != "undefined"){
        limit = "&limit=" + limit;
    }
    else{
        limit = "";
    }
    var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/active' + OBJECT_QUIZ + '?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + "&nid=" + nid + "&groups=" + groups + page + limit;
    try {
        onAsyncGetActiveQuizzesComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetActiveQuizzesComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetActiveQuizzesComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getTestSeriesCount = function (nid, groups) {
    var quizjson = {};
    var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/test_collection/getcount?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + "&nid=" + nid + '&groups=' + groups);
    try {
        quiz = $.parseJSON(quiz);
        var status = quiz['status'];
        if (status == 200) {
            return $.parseJSON(quiz["data"]);
        } else if (status == 401 || status == 403) {
            return status;
        } else {
            return t("ERROR_MESSAGE",true);
        }
    } catch (e) {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUESTION + 'for' + OBJECT_QUIZ + '?nid=' + nid
                + "\n error_code :" + status
                + "\n response_data :" + quiz['data']
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getTestCollection = function (groups, callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/getalltest_collection?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + '&groups=' + groups;
    try {
        onAsyncGetTestCollectionComplete = function (test_collection) {
            var result = '';
            var status = test_collection['status'];
            if (status == 200) {
                var testCollection = $.parseJSON(test_collection["data"]);
                result = testCollection;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetTestCollectionComplete");
        } else {
            var test_collection = Platform.makeHttpGetCall(url);
            return onAsyncGetTestCollectionComplete($.parseJSON(test_collection));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
    ;
};

MobileQuizService.prototype.getUpcomingQuizzes = function (nid, groups, page, limit, callback) {
    var quizjson = {};
    if(typeof page != "undefined"){
        page = "&page=" + page;
    }
    else{
        page = "";
    }
    if(typeof limit != "undefined"){
        limit = "&limit=" + limit;
    }
    else{
        limit = "";
    }
    var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/upcoming' + OBJECT_QUIZ + '?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + "&nid=" + nid + "&groups=" + groups + page + limit;
    try {
        onAsyncGetUpcomingQuizzesComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetUpcomingQuizzesComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetUpcomingQuizzesComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getMissedQuizzes = function (nid, groups, page, limit, callback) {
    var quizjson = {};
    if(typeof page != "undefined"){
        page = "&page=" + page;
    }
    else{
        page = "";
    }
    if(typeof limit != "undefined"){
        limit = "&limit=" + limit;
    }
    else{
        limit = "";
    }
    var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/missed' + OBJECT_QUIZ + '?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + "&nid=" + nid + "&groups=" + groups + page + limit;
    try {
        onAsyncGetMissedQuizzesComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetMissedQuizzesComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetMissedQuizzesComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getAttemptedQuizzes = function (nid, groups, page, limit, callback) {
    var quizjson = {};
    if(typeof page != "undefined"){
        page = "&page=" + page;
    }
    else{
        page = "";
    }
    if(typeof limit != "undefined"){
        limit = "&limit=" + limit;
    }
    else{
        limit = "";
    }
    var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/attempted' + OBJECT_QUIZ + '?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + "&nid=" + nid + "&groups=" + groups + page + limit;
    try {
        onAsyncGetAttemptedQuizzesComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetAttemptedQuizzesComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetAttemptedQuizzesComplete($.parseJSON(quiz));
        }
    } catch (e)
    {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getAttemptedQuizzesScorecard = function (quiz_nid, resultid) {
    var quizjson = {};
    if(resultid != '')
        var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/test_collection/result/scorecard?quiznid=' + quiz_nid +'&resultid=' + resultid);
    else
        var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/test_collection/result/scorecard?quiznid=' + quiz_nid);
    try {
        quiz = $.parseJSON(quiz);
        var status = quiz['status'];
        if (status == 200) {
            quizjson["quiz"] = $.parseJSON(quiz["data"]);
            return quizjson;
        } else if(status == 201 ){
            return quiz;
        }
        else if(status == 401){
            return status;
        }
        else if (status == 403) {
            return onAccessDenied();
        } else {
            return t("ERROR_MESSAGE",true);
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getQuizzesComparisonChart = function (quiz_nid, resultid, callback) {
    var quizjson = {};
    if(resultid != '')
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/comparisonchart?quiznid=' + quiz_nid + '&resultid=' + resultid;
    else
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/comparisonchart?quiznid=' + quiz_nid ;
    try {
        onAsyncGetQuizzesComparisonChartComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            }else if(status == 201){
                result = quiz;
            }
            else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetQuizzesComparisonChartComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetQuizzesComparisonChartComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getTopicWiseReport = function (quiz_nid, resultid, callback) {
    var quizjson = {};
    if(resultid != '')
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/topicwisereport?quiznid=' + quiz_nid + '&resultid=' + resultid;
    else
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/topicwisereport?quiznid=' + quiz_nid ;
    try {
        onAsyncGetTopicWiseReportComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetTopicWiseReportComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetTopicWiseReportComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getLevelWiseReport = function (quiz_nid, resultid, callback) {
    var quizjson = {};
    if(resultid != '')
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/levelwisereport?quiznid=' + quiz_nid + '&resultid=' + resultid;
    else
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/levelwisereport?quiznid=' + quiz_nid ;
    try {
        onAsyncGetLevelWiseReportComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetLevelWiseReportComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetLevelWiseReportComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getQuizzesAccuracyManagementChart = function (quiz_nid, resultid, callback) {
    var quizjson = {};
    if(resultid != '')
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/accuracymanagement?quiznid=' + quiz_nid + '&resultid=' + resultid;
    else
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/accuracymanagement?quiznid=' + quiz_nid ;
    try {
        onAsyncGetQuizzesAccuracyManagementChartComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if(status == 201){
                result = quiz;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetQuizzesAccuracyManagementChartComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetQuizzesAccuracyManagementChartComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getQuizzesTimeManagementChart = function (quiz_nid, resultid, callback) {
    var quizjson = {};
    if(resultid != '')
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/timemanagement?quiznid=' + quiz_nid + '&resultid=' + resultid;
    else
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/timemanagement?quiznid=' + quiz_nid ;
    try {
        onAsyncGetQuizzesTimeManagementChartComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if(status == 201){
                result = quiz;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetQuizzesTimeManagementChartComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetQuizzesTimeManagementChartComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getQuizzesCategoryWiseReport = function (quiz_nid, resultid, callback) {
    var quizjson = {};
    if(resultid != '')
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/coursewisereport?quiznid=' + quiz_nid + '&resultid=' + resultid;
    else
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/coursewisereport?quiznid=' + quiz_nid ;
    try {
        onAsyncGetQuizzesComparisonChartComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            }else if(status == 201){
                result = quiz;
            }
            else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetQuizzesComparisonChartComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetQuizzesComparisonChartComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.downloadQuestionsOfQuiz = function(quiz_nids){
    var quiz_nid = "";
    for(i=0;i<quiz_nids.length;i++){
        quiz_nid = quiz_nids[i];
        var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUESTION+'sfor'+OBJECT_QUIZ+'?nid='+quiz_nid);
        try {
            quiz = $.parseJSON(quiz);
            var status = quiz['status'];
            if (status == 200)
            {
                Platform.saveDataToFileSystem("video1/"+quiz_nid+"/"+quiz_nid+".quiz",quiz['data']);
            }
        }
        catch (e)
        {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUESTION+'for'+OBJECT_QUIZ+'?nid='+nid
            + "\n error_code :" + status
            + "\n response_data :" + quiz['data']
            + "\n message :" + e.message;
            console.log(error);
        };
    }
}

MobileQuizService.prototype.getQuiz = function(quiz_nid, newquiz)
{

    try {
        var quiz = Platform.getObjectProperty(OBJECT_QUIZ, quiz_nid, 'content');
            quiz = $.parseJSON(quiz);
        if (typeof newquiz != 'undefined' && newquiz == true)
            quiz = '';
        if (quiz == null || quiz == "" || typeof quiz == "undefined") {
            if (Platform.getNetworkState() == true) {
                var quizoftheday = CONTROLLER.getVariable("quizoftheday", "true");
                if (quizoftheday != 'undefined' && quizoftheday == 'true') {
                    var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'ofday?auth=' + AUTH_ENABLED + '&uid=' + OBJECT_USER_ID);
                } else {
                    if(typeof LOAD_QUIZ_ONLINE != 'undefined' && LOAD_QUIZ_ONLINE){
                        var quiz_url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'fromid?nid=' + quiz_nid;
                        var authenticatedURL = Platform.getAuthenticatedURL(quiz_url, 'GET', 'cloud');
                        var quiz = getQuizData(authenticatedURL);
                    }else{
                        var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'fromid?nid=' + quiz_nid+'&auth='+AUTH_ENABLED);
                    }
                }
                try {
                    quiz = $.parseJSON(quiz);
                    var status = quiz['status'];
                    if (status == 200) {
                        quiz = quiz['data'];
                        quiz = $.parseJSON(quiz);
                        if (quiz.length != 0 && (quizoftheday != 'undefined' && quizoftheday != 'true')) {
                            Platform.saveObject(OBJECT_QUIZ, quiz_nid, 'content', JSON.stringify(quiz[0]));
                        }
                    } else {
                        return "error"
					}
                } catch (e) {
                    var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'fromid?nid=' + quiz_nid
                            + "\n error_code :" + status
                            + "\n response_data :" + quiz['data']
                            + "\n message :" + e.message;
                    console.log(error);
                }
                ;
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
        if (isArray(quiz)) {
            quiz = quiz[0];
        }
        var quiz_changed = quiz.changed;
        var contentUtils = CONTROLLER.getService("ContentUtils");
        var isFileAvailable = contentUtils.isFileAvailable(quiz_nid, quiz_changed, "", "quiz");
        if (isFileAvailable.filestatus == "Available") {
            quiz = JSON.stringify(quiz);
            var filepath = Platform.getLocalServerPath() + "/video/" + quiz_nid + "/";
            quiz = replaceAll(STATIC_SERVER + "/", filepath, quiz);

            var staticPath = STATIC_SERVER.substr(STATIC_SERVER.indexOf('//'));
            var localFilePath = filepath.substr(filepath.indexOf('//'));
            quiz = replaceAll(staticPath + "/", localFilePath, quiz);

            if (typeof OLD_STATIC_SERVER != "undefined") {
                if (OLD_STATIC_SERVER instanceof Array) {
                    for (var i = 0; i < OLD_STATIC_SERVER.length; i++) {
                        var oldStaticPath = OLD_STATIC_SERVER[i].substr(OLD_STATIC_SERVER[i].indexOf('//'));
                        quiz = replaceAll(oldStaticPath + "/", localFilePath, quiz);
                    }
                } else {
                    var oldStaticPath = OLD_STATIC_SERVER.substr(OLD_STATIC_SERVER.indexOf('//'));
                    quiz = replaceAll(oldStaticPath + "/", localFilePath, quiz);
                }
            }
            quiz = replaceAll('https:', '', quiz);
            quiz = $.parseJSON(quiz);
        }
        if (EXEC_MODE == "online") {
            quiz = fixURLsWithoutProtocol(quiz);
        }
        var quiz_meta = Platform.getPreference(quiz_nid);
        quiz_meta = $.parseJSON(quiz_meta);
        if (quiz_meta != null && quiz_meta != "" && typeof quiz_meta != "undefined" && quiz == "") {
            if (quiz_nid == quiz_meta.nid) {
                quiz = quiz_meta;
            }
        }
        quiz = this.updateQuizLocale(quiz);
        return quiz;
    } catch (e) {
        console.log("function : getQuizbyNid"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.updateQuizLocale = function (quiz) {
    if (typeof quiz.locale != 'undefined' && quiz.locale != '') {
        var quiz_instruction = quiz.quiz_instruction;
        var quiz_section = $.extend(true, {}, quiz.sections);

        var updatedLocale = {};
        updatedLocale.quiz_instruction = {};
        updatedLocale.quiz_section = {};
        updatedLocale.quiz_instruction[quiz.language[0]] = quiz_instruction;
        updatedLocale.quiz_section[quiz.language[0]] = quiz_section;

        for (var j in quiz.locale.quiz_instruction) {
            updatedLocale.quiz_instruction[j] = quiz.locale.quiz_instruction[j];
        }
        for (var j in quiz.locale.quiz_section) {
            updatedLocale.quiz_section[j] = $.extend(true, {}, quiz.locale.quiz_section[j]);
        }
        quiz['updatedLocale'] = updatedLocale;
    }
    return quiz;
};

MobileQuizService.prototype.getQuizOfTheDay = function ()
{

    try {
        if (Platform.getNetworkState() == true) {
            var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'ofday?auth=' + AUTH_ENABLED + '&uid=' + OBJECT_USER_ID);
            try {
                quiz = $.parseJSON(quiz);
                var status = quiz['status'];
                if (status == 200)
                {
                    quiz = quiz['data'];
                    quiz = $.parseJSON(quiz);
                    if(quiz.length != 0){
                        return quiz[0];
                    }
                    else{
                        return NO_QOTD_ERROR;
                    }
                }
                else if(status == 403){
                     return status;
                }
                else {
                    return t("ERROR_MESSAGE",true);
                }
            }
            catch (e)
            {
                var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getgetQuizOfTheDay'
                + "\n error_code :" + status
                + "\n response_data :" + quiz['data']
                + "\n message :" + e.message;
                console.log(error);
            };
        }
        else{
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : getQuizOfTheDay"
                + "\n message:" + e.message);
    }
};


MobileQuizService.prototype.getQuizResult = function (quiz_id, checkOnline=false)
{

    try {
        var quiz_result = Platform.getRelatedObjects(OBJECT_QUIZ, quiz_id, "quiz_result");
        var quiz_result = $.parseJSON(quiz_result);
        if (Platform.getNetworkState() == true && (checkOnline == true || ((quiz_result == null || quiz_result['quiz_result'].length == 0)))) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getquizresults?quiz_id=' + quiz_id + '&uid=' + OBJECT_USER_ID +'&auth=' + AUTH_ENABLED;
            var response = Platform.makeHttpGetCall(url);
            response = $.parseJSON(response);
            var result = '';
            var status = response['status'];
            if (status == 200 || status == 201) {
                var data = response['data'];
                data = $.parseJSON(data);
                if(data.length > 0){
                    result = {};
                    result['quiz_result'] = data;
                    for(var i in result['quiz_result']){
                        var curr_result = result['quiz_result'][i];
                        if(curr_result["is_evaluated"] > 0 && EXEC_MODE == "mobile") {
                            this.quizResultSave(curr_result.result_id, curr_result.nid, JSON.stringify(curr_result));
                        }
                    }
                }
                quiz_result = result;
            } else if (status == 403) {
                onAccessDenied();
            }
        }
        return quiz_result;
    }
    catch (e) {
        console.log("function : GetQuizResult"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getEvaluatedQuizResults = function (quiz_id)
{

    try {
        var evaluatedResults = [];
        var quiz_results = this.getQuizResult(quiz_id);
        if (quiz_results && quiz_results['quiz_result'] && quiz_results['quiz_result'].length != 0) {
            for (var i = 0; i < quiz_results['quiz_result'].length; i++) {
                var currentResult = quiz_results['quiz_result'][i];
                if (currentResult.is_evaluated && currentResult.is_evaluated != "0") {
                    evaluatedResults.push(currentResult);
                }
            }
        }
        return evaluatedResults;
    }
    catch (e) {
        console.log("function : GetQuizResult"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getTestCollectionQuizResult = function (test_collection_id)
{
    try {
        var quiz_result = Platform.getRelatedObjects("test_collection", test_collection_id, "quiz_result");
        var quiz_result = $.parseJSON(quiz_result);
        return quiz_result;
    }
    catch (e) {
        console.log("function : getTestCollectionQuizResult"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.quizResultSave = function (result_id, quiz_id, quiz_result, testCollectionId)
{
    try {

        //If resultid is already generated, use that result id as local object id
        var parsedResult = $.parseJSON(quiz_result);
        if(typeof parsedResult["gen_result_id"] != "undefined" && parsedResult["gen_result_id"].indexOf("temp_") == -1 ){
            result_id = parsedResult["gen_result_id"]
        }
        if(typeof SAVE_SINGLE_QUESTION_OFFLINE != 'undefined' && SAVE_SINGLE_QUESTION_OFFLINE){
            SAVE_SINGLE_QUESTION_OFFLINE = false;
            result_id = "single_question_" + result_id.split('temp_quiz_')[1];
            if(EXEC_MODE == "mobile" && typeof parsedResult !== 'undefined' && typeof parsedResult.result_id != 'undefined' && 
                parsedResult['is_evaluated'] == "1" && typeof parsedResult.evaluated_time != 'undefined'){
                Platform.saveQuizResult("quiz_result", parsedResult.result_id, "content", quiz_result, parsedResult.evaluated_time);
            }else{
                Platform.saveObject("quiz_result", result_id, "content", quiz_result);
            }
            Platform.addRelation(OBJECT_QUIZ, quiz_id, "quiz_result", result_id);
        }else{
            if(EXEC_MODE == "mobile" && typeof parsedResult !== 'undefined' && typeof parsedResult.result_id != 'undefined' && 
                parsedResult['is_evaluated'] == "1" && typeof parsedResult.evaluated_time != 'undefined'){
                Platform.saveQuizResult("quiz_result", parsedResult.result_id, "content", quiz_result, parsedResult.evaluated_time);
            }else{
                Platform.saveObject("quiz_result", result_id, "content", quiz_result);
            }
            Platform.addRelation(OBJECT_QUIZ, quiz_id, "quiz_result", result_id);
        }
        if (testCollectionId) {
            Platform.addRelation(OBJECT_COURSE, CONTROLLER.getVariable("activecourseid"), OBJECT_TEST_COLLECTION, testCollectionId);
            Platform.addRelation(OBJECT_TEST_COLLECTION, testCollectionId, "quiz_result", result_id);
        }
    }
    catch (e) {
        console.log("function : quizResultSave"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getQuizAttempts = function (nid)
{
    try {
        if(Platform.getNetworkState() == true) {
            var json = "{";
            var temp = "";
            temp = ' "uid" : "' + OBJECT_USER_ID + '",';
            temp = temp + ' "quizid" : "' + nid + '"';
            json = json + temp + "}";

            var url = OBJECT_SERVER_DOMAIN + '/api/getquizattempts?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(json);
            var attempts = Platform.makeHttpPostCall(url, postdata);
            attempts = $.parseJSON(attempts);
            var status = attempts['status'];
            var attempts_json = {};
            if (status == 200) {
                var tot_attempts = attempts['data'];
                tot_attempts = $.parseJSON(tot_attempts);
                attempts_json["attempts"] = tot_attempts;
                return attempts_json;
            }else{
                return t("ERROR_MESSAGE",true);
            }
        }else {
            return t("ERROR_MESSAGE",true);
        }
    }
    catch (e) {
        console.log("function : getQuizAttempts"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getQuestionFromId = function(nid)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getQuestionFromId?nid=' + nid;
            var postdata = encodeURIComponent("");
            var questionData = Platform.makeHttpPostCall(url, postdata);
            questionData = $.parseJSON(questionData);
            var status = questionData['status'];
            var question_json = {};
            if (status == 200) {
                var qsnData = questionData['data'];
                qsnData = $.parseJSON(qsnData);
                question_json["questiondata"] = qsnData;
                return question_json;
            }
            else {
                return t("ERROR_MESSAGE",true);
            }
        }
        else {
             return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : getQuestionFromId"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getQuizAttemptsinOffline = function(nid) {
    try{
        var quiz_result = this.getQuizResult(nid);
        CONTROLLER.setVariable("offlinequizresults",quiz_result);
        var attemptsCount = 0;
        if (quiz_result != '' && quiz_result != null && quiz_result['quiz_result'].length != 0){
            for(var i=0;i<quiz_result['quiz_result'].length;i++){
                if (quiz_result['quiz_result'][i]["is_evaluated"] == "1" && quiz_result['quiz_result'][i]['uid'] == OBJECT_USER_ID) {
                    attemptsCount++;
                }
            }
        }
        return attemptsCount;
    }catch(e){
        console.log("getQuizAttemptsinOffline " +  e.message);
    }
};

MobileQuizService.prototype.getQuestionsResultData = function(nid, resultid, group) {
    try{
        if (Platform.getNetworkState() == true) {
            if(resultid != '')
                var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/questionslist?quiznid=' + nid + '&resultid=' + resultid + '&group=' + group;
            else
                var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/questionslist?quiznid=' + nid + '&group=' + group;
            var postdata = encodeURIComponent("");
            var resultData = Platform.makeHttpPostCall(url, postdata);
            resultData = $.parseJSON(resultData);
            var status = resultData['status'];
            var result_json = {};
            if (status == 200) {
                var qsnData = resultData['data'];
                qsnData = $.parseJSON(qsnData);
                result_json["result_data"] = qsnData;
                return result_json;
            } else if(status == 201){
                return resultData;
            }
            else if(status == 403){
                onAccessDenied();
            }
            else {
                return t("ERROR_MESSAGE",true);
            }
        }
        else {
             return t("INTERNET_ERROR",true);
        }
    }catch (e) {
        console.log("function : getQuestionsResultData"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getQuizResultFromResultId = function(quiz_result_id) {
    try{
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getquizresultfromid?resultid=' + quiz_result_id + '&uid=' + OBJECT_USER_ID;
            var response = Platform.makeHttpGetCall(url);
            response = $.parseJSON(response);
            var result = '';
            var status = response['status'];
            if (status == 200) {
                var data = response['data'];
                data = $.parseJSON(data);
                result = data;
            } else if (status == 403) {
                result = t("USER_LOGOUT_ERROR",true);
            } else {
                result = t("ERROR_MESSAGE",true);
                console.log("function : getQuizResultFromResultId - /api/getquizresultfromid " +
                + "\n message:" + status + " result :" + result);
            }
            return result;
        }
    } catch (e) {
        console.log("function : getQuizResultFromResultId"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.createResultId = function(nid, temp_result_id, resume, attemptLog, callback) {
    try{
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/'+OBJECT_QUIZ+'/start?quizid=' + nid + '&resume=' + resume + '&uid=' + OBJECT_USER_ID +'&auth='+ AUTH_ENABLED;
            var postdata = attemptLog ? encodeURIComponent(attemptLog) : "";
            onCreateResultIdComplete = function (response) {
                var result = '', resultid = '';
                var status = response['status'];
                if (status == 200) {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    resultid = data['resultid'];
                    result = resultid;
                } else if (status == 403) {
                    result = t("USER_LOGOUT_ERROR",true);
                } else {
                    result = t("ERROR_MESSAGE",true);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            };
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onCreateResultIdComplete");
            } else {
                var result = Platform.makeHttpPostCall(url, postdata);
                return onCreateResultIdComplete($.parseJSON(result));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(temp_result_id);
            } else {
                return temp_result_id;
            }
        }
    } catch (e) {
        console.log("function : createResultId"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.saveQuestionResult = function(result_id, quiz_id, questResult, callback) {
    try {
        if (Platform.getNetworkState() == true && result_id.indexOf("temp_") < 0) {
            var url = OBJECT_SERVER_DOMAIN + '/api/' + OBJECT_QUIZ + '/updateresult?quizid=' + quiz_id + '&resultid='+ result_id +'&uid=' + OBJECT_USER_ID;
            var postdata = encodeURIComponent(questResult);
            onQuizResultSaveComplete = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 200) {
                    var data = response['data'];
                    data = $.parseJSON(data.trim());
                    result = data;
                } else if (status == 403) {
                    result = t("USER_LOGOUT_ERROR",true);
                } else {
                    result = t("ERROR_MESSAGE",true);
                    console.log("function : saveQuestionResult - /api/" + OBJECT_QUIZ + "/updateresult " +
                    + "\n message:" + status + " result :" + result);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback.apply();
                } else {
                    return;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onQuizResultSaveComplete");
            } else {
                var quiz = Platform.makeHttpPostCall(url,postdata);
                return onQuizResultSaveComplete($.parseJSON(quiz));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback.apply();
            } else {
                return;
            }
        }
    }
    catch (e) {
        console.log("function : saveQuestionResult"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.finishQuiz = function(quiz_id, result_id, attemptLog) {
    try {
        if (Platform.getNetworkState() == true && result_id.indexOf("temp_") < 0) {
            var url = OBJECT_SERVER_DOMAIN + '/api/' + OBJECT_QUIZ + '/finish?quizid=' + quiz_id + '&resultid='+ result_id +'&uid=' + OBJECT_USER_ID;
            var content = {
                "attemptLog": attemptLog
            };
            var postdata = encodeURIComponent(JSON.stringify(content));
            var response = Platform.makeHttpPostCall(url, postdata);
            response = $.parseJSON(response);
            var result = '';
            var status = response['status'];
            if (status == 200) {
                var data = response['data'];
                data = $.parseJSON(data);
                return data;
            } else if (status == 403) {
                return onAccessDenied();
            } else {
                result = t("ERROR_MESSAGE",true);
                console.log("function : finishQuiz - /api/" + OBJECT_QUIZ + "/finish " +
                + "\n message:" + status + " result :" + result);
                return false;
            }
        }
    }
    catch (e) {
        console.log("function : finishQuiz"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getServerClientOffset = function (callback) {
    try {
        var url = OBJECT_SERVER_DOMAIN + '/api/gettime';
        if (Platform.getNetworkState() == true) {
            var clientTimestamp = (new Date()).valueOf();
            onGetServerClientOffsetComplete = function (time) {
                var result = '';
                var status = time['status'];
                if (status != 200) {
                    result = t("ERROR_MESSAGE",true);
                } else {
                    var data = time['data'];
                    data = $.parseJSON(data);

                    var serverTimestamp = data[0].time * 1000;

                    var t0 = clientTimestamp,
                        t1 = serverTimestamp,
                        t2 = serverTimestamp,
                        t3 = (new Date()).valueOf();

                    var c = ntp(t0, t1, t2, t3);
                    var offset = Math.round(c.offset);
                    //var syncedServerTime = (new Date(t3 + offset)).valueOf();
                    result = offset;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            };
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetServerClientOffsetComplete");
            } else {
                var time = Platform.makeHttpGetCall(url);
                return onGetServerClientOffsetComplete($.parseJSON(time));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    } catch (e) {
        console.log("function : getServerClientOffset"
            + "\n message:" + e.message);
    }
}

MobileQuizService.prototype.replicateQuiz = function (quizId, startTime, endTime, title, callback) {
    var title = title ? ("&quiz_title=" + encodeURIComponent(title)) : "";
    var url = OBJECT_SERVER_DOMAIN + '/api/' + OBJECT_QUIZ + '/replicate?quiz_id=' + quizId + '&uid=' + OBJECT_USER_ID + "&start_time=" + startTime + "&end_time=" + endTime + title + "&auth=" + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            onReplicateQuizComplete = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 403) {
                    result = onAccessDenied();
                } else if (status != 200) {
                    result = t("ERROR_MESSAGE",true);
                } else {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onReplicateQuizComplete");
            } else {
                var quiz = Platform.makeHttpGetCall(url);
                return onReplicateQuizComplete($.parseJSON(quiz));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    } catch (e) {
        console.log("function : replicateQuiz"
            + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getActiveAttemptDetails = function (nid, starttime, questionIndex, callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/' + OBJECT_QUIZ + '/getactiveattemptdetails?nid=' + nid + '&start_time=' + starttime + '&question_num='+ questionIndex +'&uid=' + OBJECT_USER_ID;
    try {
        if (Platform.getNetworkState() == true) {
            onGetActiveAttemptDetailsComplete = function (attemptDetails) {
                var result = '';
                var status = attemptDetails['status'];
                if (status != 200) {
                    result = t("ERROR_MESSAGE",true);
                } else {
                    var data = attemptDetails['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetActiveAttemptDetailsComplete");
            } else {
                var quiz = Platform.makeHttpGetCall(url);
                return onGetActiveAttemptDetailsComplete($.parseJSON(quiz));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    } catch (e) {
        console.log("function : getActiveAttemptDetails"
            + "\n message:" + e.message);
    }
}

MobileQuizService.prototype.getTestCollectionFromId = function (nid, callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/get/testcollection/'+ nid +'/details?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID;
    try {
        onAsyncGetTestCollectionFromIdComplete = function (test_collection) {
            var result = '';
            var status = test_collection['status'];
            if (status == 200) {
                var testCollection = $.parseJSON(test_collection["data"]);
                result = testCollection;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetTestCollectionFromIdComplete");
        } else {
            var test_collection = Platform.makeHttpGetCall(url);
            return onAsyncGetTestCollectionFromIdComplete($.parseJSON(test_collection));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
    ;
};
MobileQuizService.prototype.getQuizByGroup = function (groupid) {

    try {
        
        var quiz = Platform.getRelatedObjects(OBJECT_GROUP, groupid, OBJECT_QUIZ);
        quiz = $.parseJSON(quiz);
        if (quiz == '' || quiz == null || quiz[OBJECT_QUIZ].length == 0 ) {
            var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_QUIZ + "?uid=" + OBJECT_USER_ID + "&groups=" + groupid);
            quiz = $.parseJSON(quiz);
            var status = quiz['status'];
            if (status == 200)
            {
                var data = '{"' + OBJECT_QUIZ + '":' + quiz['data'].trim() + '}';
            } else {
                data = '{"' + OBJECT_QUIZ + '":[]}';
            }
            quiz = $.parseJSON(data);
        }
        return quiz;
    }
    catch (e) {
        console.log(e.message);
    }
};

MobileQuizService.prototype.updateSectionTextAllTitles = function () {
    LOCALE_SECTIONS_TEXT_ALL_TITLES = {}
    if (typeof QUIZ_MODULE != 'undefined' && QUIZ_MODULE == 'true') {
        var allLanguages = Platform.getAllLanguages();
    } else {
        var settingService = CONTROLLER.getService('Settings');
        var allLanguages = settingService.getAllLanguages();
        allLanguages = allLanguages['lang'];
    }
    for (var i in allLanguages) {
        if (allLanguages[i].name === 'Hindi') {
            LOCALE_SECTIONS_TEXT_ALL_TITLES[allLanguages[i].tid] = "??";
        } else {
            LOCALE_SECTIONS_TEXT_ALL_TITLES[allLanguages[i].tid] = ACTIVE_LANGUAGE.all;
        }
    }
};


MobileQuizService.prototype.uploadQuizLog = function (nid, result_id, log, logType, callback) {
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/logs/uploadquizlog.php?user_id=' + OBJECT_USER_ID + '&quiz_id=' + nid + '&result_id=' + result_id + "&type=" + logType;
            var postdata = JSON.stringify(log);
            onUploadQuizLogComplete = function (response) {
                SUBMITTED_RESULT_ID = "";
                var status = response['status'];
                var result = "";
                if (status != 200) {
                    result = ERROR_MESSAGE;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            };
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                Platform.makePostAsyncCall(url, "onUploadQuizLogComplete", postdata);
            } else {
                var result = Platform.makeHttpPostCall(url, postdata);
                return onUploadQuizLogComplete($.parseJSON(result));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    } catch (e) {
        console.log("function : uploadQuizLog"
            + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getQuizQuestionFromNumber = function (quiz_nid, question_no) {
    var url = OBJECT_SERVER_DOMAIN + '/api/getquizquestionfromnumber?nid=' + quiz_nid + '&qn_no=' + question_no + "&auth=" + AUTH_ENABLED;
    try {
        onGetQuizQuestionFromNumber = function (response) {
            var result = '';
            var status = response['status'];
            if (status == 200) {
                var result = {};
                result.status = response['status'];
                result.data = [];
                result.data.push(JSON.parse(response['data']));
                result.data = JSON.stringify(result.data);
                result = JSON.stringify(result);
            } else {
                result = false;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onGetQuizQuestionFromNumber");
        } else {
            var authenticatedURL = Platform.getAuthenticatedURL(url, 'GET', 'cloud');
            var response = getSingleQuestionData(authenticatedURL);
            return onGetQuizQuestionFromNumber($.parseJSON(response));
        }
    } catch (e) {
        var error = "url :" + url
            + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getAllQuizQuestions = function (quiz_nid) {
    var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUESTION + 'sfor' + OBJECT_QUIZ + '?nid=' + quiz_nid;
    try {
        onGetAllQuizQuestions = function (response) {
            var result = '';
            var status = response['status'];
            if (status == 200) {
                result = response['data'];
            } else {
                result = false;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onGetAllQuizQuestions");
        } else {
            var authenticatedURL = Platform.getAuthenticatedURL(url, 'GET', 'cloud');
            var response = getQuizData(authenticatedURL);
            return onGetAllQuizQuestions($.parseJSON(response));
        }
    } catch (e) {
        var error = "url :" + url
            + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getAdaptiveTestCollectionForUser = function (callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/getalltest_collection?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + '&adaptive=true';
    try {
        onAsyncGetTestCollectionComplete = function (test_collection) {
            var result = '';
            var status = test_collection['status'];
            if (status == 200) {
                var testCollection = $.parseJSON(test_collection["data"]);
                result = testCollection;
            } else if (status == 401 || status == 403) {
                result = ERROR_MESSAGE;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetTestCollectionComplete");
        } else {
            var test_collection = Platform.makeHttpGetCall(url);
            return onAsyncGetTestCollectionComplete($.parseJSON(test_collection));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.deleteAdaptiveQuiz = function (quiz_nid, testColl_id) {
    var url = OBJECT_SERVER_DOMAIN + '/api/delete/adaptivequiz?auth=' + AUTH_ENABLED;
    var postdata = {"quiz_id":quiz_nid, "uid":OBJECT_USER_ID, "test_coll":testColl_id};
    postdata = JSON.stringify(postdata);
    try{
        var result = Platform.makeHttpPostCall(url, postdata);
        result = $.parseJSON(result);
        return result;
    }catch(e){
        var error = "url :" + url
            + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getQuizForQuizOfTheDay = function(user_id, group, callback)
{
    if(Platform.getNetworkState() == true){
        url = OBJECT_SERVER_DOMAIN + '/api/getquizofdayforuser?auth=' + AUTH_ENABLED + "&uid=" + user_id + '&groupid=' + group;
        quizOfTheDayCallBack = function(quizDetails){
            try {
                var status = quizDetails['status'];
                if (status == 200)
                {
                    var data = $.parseJSON(quizDetails['data']);
                    return callback(data);
                }
                else if(status == 400){
                    return callback("error");
                }
            }
            catch (e)
            {
                var error = "url :" + url
                + "\n error_code :" + status
                + "\n response_data :" + data
                + "\n message :" + e.message;
                console.log(error);
                return callback("error");
            }
        };
        makeAsyncTaskCall("", "", url, "get", "false", "quizOfTheDayCallBack");
    }
    else{
        return callback("error");
    }
};

MobileQuizService.prototype.getQuestionsForQuizAsync = function(quiz_nid, quiz_type, callback)
{
    var THIS = this;
    if(Platform.getNetworkState() == true){
        url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUESTION + 'sfor' + OBJECT_QUIZ + '?nid=' + quiz_nid;
        getQuestionsForQuizCallBack = function(questions){
            try {
                var status = questions['status'];
                if (status == 200)
                {
                    var data = '{"question":' + questions['data'].trim() + '}';
                    questions = THIS.updateQuestionLocale($.parseJSON(data));
                    return callback(questions);
                }else{
                    questions = "";
                    return callback(questions);
                }
            }
            catch (e)
            {
                var error = "url :" + url
                + "\n error_code :" + status
                + "\n response_data :" + data
                + "\n message :" + e.message;
                console.log(error);
                return callback("error");
            }
        };
        makeAsyncTaskCall("", "", url, "get", "false", "getQuestionsForQuizCallBack");
    }
    else{
        return callback("error");
    }    
};

MobileQuizService.prototype.getTestRemainingDuration = function (result_id) {
    var url = OBJECT_SERVER_DOMAIN + '/api/get/testremainingduration' + '?result_id=' + result_id;
    try {
        onGetTestRemainingDuration = function (data) {
            var result = '';
            var status = data['status'];
            if (status == 200) {
                var remainingDuration = $.parseJSON(data["data"]);
                result = remainingDuration.remaining_duration;
            } else if (status == 401 ) {
                result = "";
            } else if(status == 403){
                return onAccessDenied();
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onGetTestRemainingDuration");
        } else {
            var data = Platform.makeHttpGetCall(url);
            return onGetTestRemainingDuration($.parseJSON(data));
        }
    } catch (e) {
        var error = "url :" + url
            + "\n message :" + e.message;
        console.log(error);
    }
};
MobileQuizService.prototype.deleteQuizAttempt = function(resultids){
    try{
        if (Platform.getNetworkState() == true) {
            var json = "{";
            var temp = "";
            temp = ' "resultid" : "' + resultids + '"' ;
            json = json + temp + "}";

            var url = OBJECT_SERVER_DOMAIN + '/api/delete'+OBJECT_QUIZ+'results?auth='+AUTH_ENABLED;
            var postdata = encodeURIComponent(json);
            var resultData = Platform.makeHttpPostCall(url, postdata);
            resultData = $.parseJSON(resultData);
            var status = resultData['status'];
            if (status == 200) {
                return status;
            } else {
                return ERROR_MESSAGE;
            }
        }else{
            alert(INTERNET_ERROR);
        }
    }
    catch(e){
        console.log(e.message);
    }
}
function TocService() {
    this.utils = CONTROLLER.getService("Utils");
}
;

TocService.prototype.getTocSdCardInfo = function(cid, productToc) {

    if (productToc) {
        var toc = productToc;
    } else {
        var toc = ""
        if(typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0){
            toc = Platform.getDataFromExternalFileSystem("product.toc");
        }
        else{
            toc = Platform.getDataFromFileSystem("product.toc");
        }
    }
    if(toc == ""){
        return "";
    }
    var sdcard='';
    try {
        toc = $.parseJSON(toc);
        $.each(toc, function(ckeys, group_values) {
            if (group_values instanceof Array || typeof group_values == "object") {
            $.each(group_values, function(keys, values) {
                if (values instanceof Array) {
                    $.each(values, function(key, value) {
                        if (value.nid == cid) {
                            sdcard = value.sdcard;
                            return true;
                        }
                    });
                }
            });
        }
        });

        if (sdcard) {
            return sdcard;
        } else {
            return false;
        }
    }
    catch (e)
    {
        var error = "method : getTocSdCardInfo"
                + "\n response_data :" + toc
                + "\n message :" + e.message;
        console.log(error);
    };

};

TocService.prototype.getChaptersOfSdCard = function() {
    try {
        if(typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0){
            var chaptersofsdcard = Platform.getDataFromExternalFileSystem("content.toc");
        }
        else{
            chaptersofsdcard = Platform.getDataFromFileSystem("content.toc");
        }
        if(chaptersofsdcard != ""){
            chaptersofsdcard = $.parseJSON(chaptersofsdcard);
        }
        else{
            chaptersofsdcard = "";
        }
        return chaptersofsdcard;
    }
    catch (e) {
        console.log("function : getChaptersOfSdCard"
                + " message" + e.message);
    }
};


function MobileCourseService() {}
;

MobileCourseService.prototype.getChaptersForCourse = function (courseid, groupid)
{
    try {
        if (COURSE_DETAILS.hasOwnProperty(courseid) == false) {
            try {
                var mode = getQueryParameter('mode', LOCATION_URL);
                if(typeof mode != 'undefined' && mode != null && mode != 'null' && mode == 'adaptivelearning'){
                    var adaptiveGroupId = "";
                    if(typeof OBJECT_USER_GROUPS_JSON != 'undefined' && OBJECT_USER_GROUPS_JSON.length > 0){
                        for(var i = 0; i < OBJECT_USER_GROUPS_JSON.length; i++){
                            if(OBJECT_USER_GROUPS_JSON[i].type == "adaptive"){
                                if(adaptiveGroupId == ''){
                                    adaptiveGroupId = adaptiveGroupId.concat(OBJECT_USER_GROUPS_JSON[i].nid);
                                }else{
                                    adaptiveGroupId = adaptiveGroupId.concat(',',OBJECT_USER_GROUPS_JSON[i].nid);
                                }
                            }
                        }
                    }
                    if ($.isFunction(Platform.getTopicDetails)) {
                        var chapters = Platform.getTopicDetails(adaptiveGroupId, courseid, "", "", "");
                        chapters = $.parseJSON(chapters);
                        if(isArray(chapters) == true){
                            COURSE_DETAILS[courseid] = chapters[0];
                            return chapters[0];
                        }else{
                            COURSE_DETAILS[courseid] = chapters
                            return chapters;
                        }
                    }else {
                        var chapters = Platform.getRelatedObjects(OBJECT_COURSE, courseid, OBJECT_CHAPTER);
                        chapters = $.parseJSON(chapters);
                        return chapters;
                    }
                }
                if (typeof groupid != "undefined" && groupid != "") {
                    var filter = '[{"object_type":"group","object_id":"' + groupid + '","relation_object_type":"' + OBJECT_CHAPTER + '"}]';
                    if ($.isFunction(Platform.getTopicDetails)) {
                        var chapters = Platform.getTopicDetails(groupid, courseid, "", "", "");
                        chapters = $.parseJSON(chapters);
                        if(isArray(chapters) == true){
                            COURSE_DETAILS[courseid] = chapters[0];
                            return chapters[0];
                        }else{
                            COURSE_DETAILS[courseid] = chapters
                            return chapters;
                        }
                    } else {
                        if(EXEC_MODE == "online"){
                            var chapters = Platform.getRelatedObjectsFilter(OBJECT_COURSE, courseid, OBJECT_CHAPTER, filter);
                        }else{
                            var chapters = Platform.getRelatedObjectsFilterByGroup(groupid,OBJECT_COURSE, courseid, OBJECT_CHAPTER);
                        }
                        chapters = $.parseJSON(chapters);
                        return chapters;
                    }
                }
                else {
                    if ($.isFunction(Platform.getTopicDetails)) {
                        var chapters = Platform.getTopicDetails(String(OBJECT_USER_GROUPS), courseid, "", "", "");
                        chapters = $.parseJSON(chapters);
                        if(isArray(chapters) == true){
                            COURSE_DETAILS[courseid] = chapters[0];
                            return chapters[0];
                        }else{
                            COURSE_DETAILS[courseid] = chapters
                            return chapters;
                        }
                        
                    } else {
                        var chapters = Platform.getRelatedObjects(OBJECT_COURSE, courseid, OBJECT_CHAPTER);
                        chapters = $.parseJSON(chapters);
                        return chapters;
                    }
                    
                }
            } catch (e) {
                console.log("function : getChaptersForCourse"
                    + "\n message:" + e.message);
            }
        } else {
            return COURSE_DETAILS[courseid];
        }
    } catch (e) {
        console.log("function : getChaptersForCourse"
                + "\n message:" + e.message);
    }
};

MobileCourseService.prototype.getCourseDetails = function(courseid, groupid){
    try{
        if (COURSE_DETAILS.hasOwnProperty(courseid) == false) {
            var groupFilter = "";
            var lang = "";
            if (typeof groupid != undefined) {
                groupFilter = '&groupid=' + groupid;
            }
            if(typeof ENABLE_LOCALIZATION != "undefined" && ENABLE_LOCALIZATION == "true" && (APP_TYPE == "web" || APP_TYPE == "ios")){

                lang = "&lang="+Platform.getProperty('lang');
            }
            var courses = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get'+OBJECT_COURSE+'details?uid=' + OBJECT_USER_ID + '&nid=' + courseid + groupFilter + lang);
            try {
                courses = $.parseJSON(courses);
                var status = courses['status'];
                if (status == 200)
                {
                    var data = $.parseJSON(courses['data']);
                    COURSE_DETAILS[courseid] = data;
                    return data;
                }
                else{
                    return "error";
                }
            }
            catch (e)
            {
                var error = "function - getCourseDetails"
                + "\n error_code :" + status
                + "\n response_data :" + data
                + "\n message :" + e.message;
                console.log(error);
            }
        }
        else{
            return COURSE_DETAILS[courseid];
        }
    }
    catch(e){
        console.log("function : getCourseDetails"
            + " message" + e.message);
    }
};

MobileCourseService.prototype.getVideosWatchedByCourse = function (catid) {
    try {
        if(VIDEOS_WATCHED_CATEGORY.hasOwnProperty(catid)){
            return VIDEOS_WATCHED_CATEGORY[catid];
        }
        var url = OBJECT_SERVER_DOMAIN + '/api/getapi/getwatched'+OBJECT_VIDEO+'sfor'+OBJECT_COURSE+'?uid=' + OBJECT_USER_ID;
        var cp = Platform.makeHttpGetCall(url);
        try {
            cp = $.parseJSON(cp);
            var status = cp['status'];
            if (status == 200)
            {
                var data = $.parseJSON(cp['data']);
                VIDEOS_WATCHED_COURSE.push(data);
                return data;
            } else {
                return "error";
            }
        } catch (e)
        {
            var error = "url :" + url
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            console.log(error);
        }

    } catch (e) {
        console.log("function - getVideosWatchedByCourse message - " + e.message);
    }
};

MobileCourseService.prototype.getCoursesForGroup = function (groupid) {
    try {
        if(COURSES_FOR_GROUP.hasOwnProperty(groupid)){
            return COURSES_FOR_GROUP[groupid];
        }
        var group = $.parseJSON(Platform.getObjectProperty(OBJECT_GROUP, groupid, "content"));
        var url = OBJECT_SERVER_DOMAIN + '/api/getall'+OBJECT_COURSE+'s?groups='+group.title+'&uid=' + OBJECT_USER_ID;
        var cp = Platform.makeHttpGetCall(url);
        try {
            cp = $.parseJSON(cp);
            var status = cp['status'];
            if (status == 200)
            {
                var data = $.parseJSON(cp['data']);
                COURSES_FOR_GROUP[groupid]= data;
                return data;
            } else {
                return "error";
            }
        } catch (e)
        {
            var error = "url :" + url
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            console.log(error);
        }

    } catch (e) {
        console.log("function - getCoursesForGroup message - " + e.message);
    }
};

MobileCourseService.prototype.getCourseProgress = function (courseids, callback) {
    try {
        var coursesToBeChecked = [];
        for (var i = 0; i < courseids.length; i++) {
            if (typeof COURSE_PROGRESS[courseids[i]] == "undefined") {
                coursesToBeChecked.push(courseids[i]);
            }
        }
        if (coursesToBeChecked.length > 0) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getuser' + OBJECT_COURSE + 'progress?uid=' + OBJECT_USER_ID + '&courses=' + coursesToBeChecked.join(",");
            Platform.makePostAsyncCall(url, function (cp) {
                try {
                    var status = cp['status'];
                    if (status == 200)
                    {
                        var data = $.parseJSON(cp['data']);
                        $.extend(COURSE_PROGRESS, data);
                        callback(COURSE_PROGRESS);
                    } else {
                        callback("error");
                    }
                } catch (e)
                {
                    var error = "url :" + url
                            + "\n error_code :" + status
                            + "\n response_data :" + data
                            + "\n message :" + e.message;
                    console.log(error);
                }
            });
        } else {
            var coursesWithProgress = {};
            for (var i = 0; i < courseids.length; i++) {
                coursesWithProgress[courseids[i]] = 0;
                if (typeof COURSE_PROGRESS[courseids[i]] != "undefined") {
                    coursesWithProgress[courseids[i]] = COURSE_PROGRESS[courseids[i]];
                }
            }
            callback(coursesWithProgress);
        }
    } catch (e) {
        console.log("function - getCourseProgress message - " + e.message);
    }
};


MobileCourseService.prototype.getTitle = function (catid, courseid, groupid) {
    var course = this.getOffsetForCourse(catid, courseid, groupid);
    if (typeof COURSE_INDEX == "undefined" || COURSE_INDEX == "") {
        if (course.offset != "") {
            return course.offset + ". " + course.title;
        }
        return course.title;
    } else {
        return COURSE_INDEX + ". " + course.title;
    }
}
MobileCourseService.prototype.getCourseTitle = function (param) {
    try {
        if (typeof param == "object") {
            var courseid = param.courseid;
        } else {
            var courseid = param;
        }
	    var catobj = CONTROLLER.getVariable("activecatobj");
	    if(typeof COURSE_INDEX_MAP!= 'undefined' && typeof COURSE_INDEX_MAP[courseid]!= 'undefined'){
	        if(typeof catobj != "undefined" && (typeof catobj.hide_label != "undefined" && catobj.hide_label == 1)){
	             return COURSE_INDEX_MAP[courseid]['title'];
	        }else if (typeof COURSE_INDEX == "undefined" || COURSE_INDEX == "") {
	            return COURSE_INDEX_MAP[courseid]['offset'] + ". " + COURSE_INDEX_MAP[courseid]['title'];
	        }else {
	            return COURSE_INDEX + ". " + COURSE_INDEX_MAP[courseid]['title'];
	        }
	    }else{
	        var course_title = Platform.getObjectProperty(OBJECT_COURSE, courseid, 'content');
            course_title = $.parseJSON(course_title);
            if(typeof ENABLE_LOCALIZATION != 'undefined' && ENABLE_LOCALIZATION == "true"){
                if(typeof course_title == 'undefined' || course_title == null){
                    CONTROLLER.loadView('HomePage');
                }
            }
	        return course_title.title;
	    }
	} catch (e) {
        var error = "getCourseTitle"
        + "\n message :" + e.message;
        console.log(error);
    }
}
MobileCourseService.prototype.getAllAssignment = function (courseid,status,callback) {
    // if(typeof status == "undefined" || status == ""){
    //     status = 0;
    // } To list all submitted and not submitted status should be empty string
	if(typeof ENABLE_LOCALIZATION != "undefined" && ENABLE_LOCALIZATION == "true") {
        var langCode = Platform.getPreference("lang");
        var url = OBJECT_SERVER_DOMAIN + '/api/getalluser'+OBJECT_ASSIGNMENT + '?uid=' + OBJECT_USER_ID + '&course_id=' + courseid + "&status=" + status + "&lid=" + langCode;
    }else{
        var url = OBJECT_SERVER_DOMAIN + '/api/getalluser'+OBJECT_ASSIGNMENT + '?uid=' + OBJECT_USER_ID + '&course_id=' + courseid + "&status=" + status ;
    }
    try {
        if (Platform.getNetworkState() == true) {
            onGetAllAssignment = function(assignment){
                var result = '';
                var status = assignment['status'];
                if (status == 200)
                {
                    var data = assignment['data'];
                    data =  $.parseJSON(data);
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetAllAssignment");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return onGetAllAssignment($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getalluser'+OBJECT_ASSIGNMENT+'?uid=' + OBJECT_USER_ID
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }

}
MobileCourseService.prototype.getAssignmentAttempts = function ( courseid) {
    var assignment = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getalluser'+OBJECT_ASSIGNMENT+'?uid=' + OBJECT_USER_ID +'&assignment_nid=' + courseid);
        try {
            assignment = $.parseJSON(assignment);
            var status = assignment['status'];
            if (status == 200)
            {
                var data = $.parseJSON(assignment['data']);
                return data;
            }
            else{
                return "error";
            }
        }
        catch (e)
        {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getalluserassignment' +  '?uid=' + OBJECT_USER_ID
            + "\n error_code :" + status
            + "\n response_data :" + data
            + "\n message :" + e.message;
            console.log(error);
        }
}
MobileCourseService.prototype.getCompletedVideosOfCourseCount = function(groupid, courseid, filetype, contenttype) {
    if (typeof GET_VVH_BY_USER_GROUPS == "undefined") {
        groupid = "";
    }
    var filter = '[{"object_type":' + OBJECT_COURSE + ',"object_id":' + courseid + '},{"object_type":' + OBJECT_CHAPTER + '},{"object_type":' + OBJECT_VIDEO + '}]';
    if ((filetype) && (contenttype)) {
        var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
    } else {
        var ignore_params = "";
    }
    var count = Platform.getVideoViewHistoryCompletedCountByType(OBJECT_USER_ID, groupid, courseid, "course",ignore_params,true);
    return count;
}

MobileCourseService.prototype.getResourcesCount = function(catid,courseid,groupid,filetype,contenttype){
    try{
        var filter = '[{"object_type":"'+OBJECT_COURSE+'","object_id":"'+courseid+'"},{"object_type":"'+OBJECT_CHAPTER+'"},{"object_type":"'+OBJECT_VIDEO+'"}]';
        if ((filetype) && (contenttype)) {
            var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
        } else {
            var ignore_params = "";
        }
        var resourcescount = Platform.getRelationObjectCount(groupid,OBJECT_CATEGORY,catid,filter,ignore_params);
        return resourcescount;
    }
    catch(e){

    }
}
MobileCourseService.prototype.getCoursesCount = function(catid,groups){
    try{
        var filter = '[{"object_type":'+OBJECT_COURSE+'}]';
        var ignore_params = "";
        var coursescount = Platform.getRelationObjectCount(groups,OBJECT_CATEGORY,catid,filter,ignore_params);
        return coursescount;
    }
    catch(e){

    }
}
MobileCourseService.prototype.getEbookCount = function (catid, courseid, groupid) {
    try {
        var filter = '[{"object_type":"' + OBJECT_COURSE + '","object_id":"' + courseid + '"},{"object_type":"' + OBJECT_BOOK + '"}]';
        var resourcescount = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid, filter);
        return resourcescount;
    }
    catch (e) {
        console.log("function : getEbookCount"
                + "\n message:" + e.message);
    }
}
MobileCourseService.prototype.getQuizzesCount = function (catid, courseid, groupid) {
    try {
        var filter = '[{"object_type":"' + OBJECT_COURSE + '","object_id":"' + courseid + '"},{"object_type":"' + OBJECT_QUIZ + '"}]';
        var resourcescount = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid, filter);
        return resourcescount;
    }
    catch (e) {
         console.log("function : getQuizzesCount"
                + "\n message:" + e.message);
    }
}

MobileCourseService.prototype.getResourcesCountByType = function(courseid,groupid,filetype,contenttype){
    try {
        if (COURSE_DETAILS.hasOwnProperty(courseid) == false) {
            var filter = '[{"object_type":"' + OBJECT_CHAPTER + '"},{"object_type":"' + OBJECT_VIDEO + '"}]';
            if ((filetype) && (contenttype)) {
                var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
            } else {
                var ignore_params = "";
            }
            if (typeof GET_VVH_BY_USER_GROUPS == "undefined") {
                groupid = "";
            }
            var resourcescount = Platform.getRelationObjectCount(groupid, OBJECT_COURSE, courseid, filter, ignore_params);
            return resourcescount;
        } else {
            var coursedetails = COURSE_DETAILS[courseid];
            var resourcescount = 0;
            for (var i = 0; i < coursedetails[OBJECT_CHAPTER].length; i++) {
                resourcescount = resourcescount + coursedetails[OBJECT_CHAPTER][i][OBJECT_VIDEO].length;
            }
            return resourcescount;
        }
    }
    catch (e) {
        console.log("function : getResourcesCountByType"
        + "\n message:" + e.message);
    }
}


MobileCourseService.prototype.getOffsetForCourse = function (catid, courseid, groupid) {
	if(EXEC_MODE == 'online'){
		if(COURSE_INDEX_MAP.hasOwnProperty(courseid)){
	        return COURSE_INDEX_MAP[courseid];
	    }
	    var categoryService = CONTROLLER.getService("Category");
	    var courses = categoryService.getCoursesForCategory(catid, groupid);
	    courses[OBJECT_COURSE] = sortByRank(courses[OBJECT_COURSE]);
	    COURSE_INDEX_MAP = {};
	    for (var i = 0; i < courses[OBJECT_COURSE].length; i++) {
	        COURSE_INDEX_MAP[courses[OBJECT_COURSE][i].nid] = {"offset":i+1,"title":courses[OBJECT_COURSE][i].title};
	    }
	    return COURSE_INDEX_MAP[courseid];
	} else {
		try{
	        var filter = '[{"object_type":"group","object_id":"' + groupid + '","relation_object_type":"' + OBJECT_COURSE + '","relation_object_id":"' + courseid + '"}]';
	        var course = Platform.getRelatedObjectByOffset(OBJECT_CATEGORY, catid, OBJECT_COURSE, courseid, filter);

	        course = $.parseJSON(course);
	        return course;
	    }
	    catch(e){
	        console.log("MobileCourseService - getOffsetForCourse : " + e.message);
	        return "";
	    }
	}
}


MobileCourseService.prototype.getEbooksForCourse = function (courseid) {
    if(EXEC_MODE == 'online' && COURSE_DETAILS.hasOwnProperty(courseid)){
        var ebooks = [];
        ebooks[OBJECT_BOOK] = COURSE_DETAILS[courseid]["ebook"];
        return ebooks;
    }
    if (COURSE_DETAILS.hasOwnProperty(courseid)) {
        var ebooks = [];
        ebooks[OBJECT_BOOK] = COURSE_DETAILS[courseid][OBJECT_BOOK];
        return ebooks;
    } else {
        var ebooks = Platform.getRelatedObjects(OBJECT_COURSE, courseid, OBJECT_BOOK);
        ebooks = $.parseJSON(ebooks);
        return ebooks;
    }
}

MobileCourseService.prototype.getNotesForCourse = function (courseid)
{
    try {
        if(EXEC_MODE == 'online'){
            var notes = this.getNotesofUserByCourse(courseid);
            notes['chapterToRender'] = notes[OBJECT_CHAPTER]
            return notes;
        } else if(typeof NOTES_OLD != "undefined" && NOTES_OLD == true){
            var notes = Platform.getRelatedObjects(OBJECT_COURSE, courseid, OBJECT_NOTES);
            notes = $.parseJSON(notes);
            return notes;
        }
        var notes_list = [];
        var new_notes_list = [];
        var new_resoursce_list = [];
        var resouse_list;
        var i = 0;
        var new_notes_text_list = [];
        var course_notes = [];
        var chapterService = CONTROLLER.getService("Chapter");
        var courseService = CONTROLLER.getService("Course");
        var notesTemp = "";
        var groupid = CONTROLLER.getVariable("activegroupid");
        var catid = CONTROLLER.getVariable("activecatid");
        var courseid = CONTROLLER.getVariable("activecourseid");
        var chapter = courseService.getChaptersForCourse(courseid, groupid);
        course_notes[OBJECT_NOTES] = [];
        for (j in chapter[OBJECT_CHAPTER]) {
            if (typeof CHECK_CONTENT_BELONGS_TO_USERGROUPS != "undefined" && CHECK_CONTENT_BELONGS_TO_USERGROUPS == "true" && 
            typeof RENDER_NOTES != 'undefined' && !RENDER_NOTES) {
                videos = chapterService.getResourcesForChapterByGroups(chapter[OBJECT_CHAPTER][j].nid);
            } else {
               videos = chapterService.getResourcesForChapter(chapter[OBJECT_CHAPTER][j].nid);
            }
            for (k in videos[OBJECT_VIDEO]) {
                notesTemp = Platform.getAllNotesByUserID(OBJECT_USER_ID, videos[OBJECT_VIDEO][k].nid);
                try {
                    notesTemp = $.parseJSON(notesTemp);
                    if (!$.isEmptyObject(notesTemp[OBJECT_NOTES])) {
                        for (m in notesTemp[OBJECT_NOTES]) {
                            try {
                                notesTemp[OBJECT_NOTES][m].chapterid = chapter[OBJECT_CHAPTER][j].nid;
                                notesTemp[OBJECT_NOTES][m].courseid = courseid;
                                notesTemp[OBJECT_NOTES][m].catid = catid;
                                notesTemp[OBJECT_NOTES][m].groupid = groupid;
                                notesTemp[OBJECT_NOTES][m].originalDuration = notesTemp[OBJECT_NOTES][m].duration;
                                notesTemp[OBJECT_NOTES][m].note = notesTemp[OBJECT_NOTES][m].note.replace(/^\n+|\n+$/g, '').replace(/^\t+|\t+$/g, '').replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
                                var video_notes = CONTROLLER.getVariable("video_notes")
                                if(typeof video_notes !="undefined" && video_notes == "true"){
                                    notesTemp[OBJECT_NOTES][m].duration = convertSecondsToHHMMSS(parseInt(notesTemp[OBJECT_NOTES][m].duration))
                                }
                                else{
                                    notesTemp[OBJECT_NOTES][m].duration = getVideoDurationFromSeconds(parseInt(notesTemp[OBJECT_NOTES][m].duration, 10)) + "m";
                                }
                                course_notes[OBJECT_NOTES].push(notesTemp[OBJECT_NOTES][m]);
                            } catch (e) {
                                console.log("notes loop " + e.message);
                            }

                        }
                    }
                } catch (e) {
                    console.log("get notes " + e.message);
                }
            }
        }

        if (course_notes[OBJECT_NOTES].length > 0) {
            notes_list = course_notes;

            new_notes_list[OBJECT_CHAPTER] = [];
            // Initializing new_notes_list array to store notes
            for (i = 0; i < chapter[CLIENT_OBJECT_CHAPTER].length; i++) {
                new_notes_list[OBJECT_CHAPTER][i] = {};
                new_notes_list[OBJECT_CHAPTER][i].title = chapter[OBJECT_CHAPTER][i].title;
                new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO] = [];
                var chapterid = chapter[OBJECT_CHAPTER][i].nid;
                  if (typeof CHECK_CONTENT_BELONGS_TO_USERGROUPS != "undefined" && CHECK_CONTENT_BELONGS_TO_USERGROUPS == "true"
                  && typeof RENDER_NOTES != 'undefined' && !RENDER_NOTES) {
                     var resources = chapterService.getResourcesForChapterByGroups(chapterid);
                  } else {
                     var resources = chapterService.getResourcesForChapter(chapterid); // take all videos of each chapter
                  }
                for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                    new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = {};
                    new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k].title = resources[OBJECT_VIDEO][k].title;
                    new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES] = [];
                }
            }

            //comparing all the chapters with the notes_list chapters
            for (i = 0; i < chapter[CLIENT_OBJECT_CHAPTER].length; i++) {
                for (var j = 0; j < course_notes[OBJECT_NOTES].length; j++) {
                    if (chapter[OBJECT_CHAPTER][i].nid == course_notes[OBJECT_NOTES][j].chapterid) {
                        var chapterid = chapter[OBJECT_CHAPTER][i].nid;
                        if (typeof CHECK_CONTENT_BELONGS_TO_USERGROUPS != "undefined" && CHECK_CONTENT_BELONGS_TO_USERGROUPS == "true"
                        && typeof RENDER_NOTES != 'undefined' && !RENDER_NOTES) {
                            var resources = chapterService.getResourcesForChapterByGroups(chapterid);
                        } else {
                            var resources = chapterService.getResourcesForChapter(chapterid);
                        }
                        //getting all resorces of chapter and adding the notes to array if it contains
                        for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                            if (resources[OBJECT_VIDEO][k].nid == course_notes[OBJECT_NOTES][j].resourceid) {
                                new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].push(course_notes[OBJECT_NOTES][j]);
                            }
                        }
                    }
                }
            }

            for (i = chapter[CLIENT_OBJECT_CHAPTER].length - 1; i >= 0; i--) {
                notescount = 0;
                for (var k = new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO].length - 1; k >= 0; k--) {
                    notescount = new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO].length;
                    count = new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length;
                    // removing resourses which does not have notes
                    if (new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length == 0) {
                        new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = "";
                        new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO].splice(k, 1);
                        notescount = notescount - 1;
                    }
                }
                // removing chapters which does not have resourse with notes
                if (notescount == 0) {
                    new_notes_list[OBJECT_CHAPTER][i] = "";
                    new_notes_list[OBJECT_CHAPTER].splice(i, 1);
                }
            }
        }

        new_notes_list['chapterToRender'] = new_notes_list[OBJECT_CHAPTER];

        return new_notes_list;
    } catch (e) {
        console.log("function : getNotesForCourse"
                + "\n message:" + e.message);
    }
};
MobileCourseService.prototype.getDownloadTime = function(){
    var downloadTime = "";
    if(typeof LAST_DOWNLOAD_ADDED_TIME == 'undefined' || LAST_DOWNLOAD_ADDED_TIME == 0 || LAST_DOWNLOAD_ADDED_TIME < new Date().getTime()) {
        downloadTime = 0;
        LAST_DOWNLOAD_ADDED_TIME = new Date().getTime();
    }
    else{
        downloadTime = (LAST_DOWNLOAD_ADDED_TIME + 500) - new Date().getTime();
        LAST_DOWNLOAD_ADDED_TIME = LAST_DOWNLOAD_ADDED_TIME + 500;
    }
    return downloadTime;
}
MobileCourseService.prototype.downloadAllResourcesOfCourse = function(courseid)
{
    var resources = "";
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var chapters = this.getChaptersForCourse(courseid);
    var resourceStatus = "";
    var THIS = this;
    if(CONTROLLER.getVariable("catid_addedfor_download")){
        var catid = CONTROLLER.getVariable("catid_addedfor_download");
    }else{
        var catid = CONTROLLER.getVariable("activecatid");
    }
    this.downloadContentsOfCourse(chapters,courseid,catid);

    this.downloadQuizOfCourse(courseid,catid);

    this.downloadSupportDocsofCourse(chapters,courseid,catid);

    CONTROLLER.setVariable("courseleveldownload","true");
}

MobileCourseService.prototype.downloadContentsOfCourse = function(chapters,courseid, catid)
{
    var resourceStatus = "";
    var resources = {};resources[OBJECT_VIDEO] = [];
    var THIS = this;
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var download_action = CONTROLLER.getVariable("download_action");
    for (var j = 0; j < chapters[OBJECT_CHAPTER].length; j++) {
        var contents = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][j].nid, OBJECT_VIDEO))
        for(var i=0; i<contents[OBJECT_VIDEO].length; i++){
            if (contents[OBJECT_VIDEO][i].type == "Webinar") {
                var webinarRecordObject = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR,contents[OBJECT_VIDEO][i].webinar_record_id,"content"));
                if (webinarRecordObject) {
                    resources[OBJECT_VIDEO].push(contents[OBJECT_VIDEO][i]);
                    TOTALCONTENTUNDERCOURSE.push(contents[OBJECT_VIDEO][i]);
                }
            }
            if ((contents[OBJECT_VIDEO][i].type != "HTML" && contents[OBJECT_VIDEO][i].source != "Link" && contents[OBJECT_VIDEO][i].filetype != "jpg") || (contents[OBJECT_VIDEO][i].type == "Assessment" && (contents[OBJECT_VIDEO][i].source == "Link" || contents[OBJECT_VIDEO][i].source == "Upload"))) {
                resources[OBJECT_VIDEO].push(contents[OBJECT_VIDEO][i]);
                TOTALCONTENTUNDERCOURSE.push(contents[OBJECT_VIDEO][i]);
            }
        }
    }
    for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
        (function(k) {
            var downloadTime = THIS.getDownloadTime();
            setTimeout(function() {
                try{
                    if (resources[OBJECT_VIDEO][k]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                        var quiz = quizService.getQuiz(resources[OBJECT_VIDEO][k].url);
                        if (typeof quiz != "undefined") {
                            if (download_action == "download") {
                                var status = contentUtils.checkResourceDownloadStatus(resources[OBJECT_VIDEO][k].nid, "content", courseid, resources[OBJECT_VIDEO][k].youtube_id);
                                if (status != 2) {
                                    quiz['contentid'] = resources[OBJECT_VIDEO][k]["nid"];
                                    contentUtils.fileDownload(quiz, "", "", "", courseid, OBJECT_QUIZ, catid,resources[OBJECT_VIDEO][k]["nid"]);
                                }
                            } else {
                                quiz['contentid'] = resources[OBJECT_VIDEO][k]["nid"];
                                contentUtils.fileDownload(quiz, "", "", "", courseid, OBJECT_QUIZ, catid,resources[OBJECT_VIDEO][k]["nid"]);
                            }
                        }
                    }
                    else {
                        if (download_action == "download") {
                            var status = contentUtils.checkResourceDownloadStatus(resources[OBJECT_VIDEO][k].nid, "content", courseid, resources[OBJECT_VIDEO][k].youtube_id);
                            if (status != 2) {
                                contentUtils.fileDownload(resources[OBJECT_VIDEO][k], RESOURCE_FORMAT, "", "", courseid, OBJECT_VIDEO, catid);
                            }
                        } else {
                            contentUtils.fileDownload(resources[OBJECT_VIDEO][k], RESOURCE_FORMAT, "", "", courseid, OBJECT_VIDEO, catid);
                        }
                    }
                }
                catch(e){
                    console.log(e.message + " resource = " + JSON.stringify(resources[OBJECT_VIDEO]) + " index = " + k);
                }
            },downloadTime);
        }(k));
    }
}
MobileCourseService.prototype.downloadQuizOfCourse = function(courseid, catid){
    var THIS = this;
    var all_quizzes = this.getQuizForCourse(courseid);
    var contentUtils = CONTROLLER.getService("ContentUtils");
    if (!$.isEmptyObject(all_quizzes[OBJECT_QUIZ])) {
        for (var i in all_quizzes[OBJECT_QUIZ]) {
            var downloadTime = THIS.getDownloadTime();
            (function(i) {
                setTimeout(function() {
                    var status = contentUtils.checkResourceDownloadStatus(all_quizzes[OBJECT_QUIZ][i]['nid'], OBJECT_QUIZ, courseid, all_quizzes[OBJECT_QUIZ][i]['changed']);
                    if (status != 2) {
                        contentUtils.fileDownload(all_quizzes[OBJECT_QUIZ][i], "", "", "", courseid, OBJECT_QUIZ, catid);
                    }
                }, downloadTime);
            }(i));
        }
    }
}
MobileCourseService.prototype.downloadSupportDocsofCourse = function(chapters,courseid, catid)
{
    var THIS = this;
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var downloadTime = "";
    var supportdocs = {};
    supportdocs[OBJECT_SUPPORT_DOCS] = [];
    var groupId = CONTROLLER.getVariable("activegroupid");
    for (var j = 0; j < chapters[OBJECT_CHAPTER].length; j++) {
        var docs = $.parseJSON(Platform.getRelatedObjects(groupId, OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][j].nid, OBJECT_SUPPORT_DOCS));
        for(var i=0; i<docs[OBJECT_SUPPORT_DOCS].length; i++){
            supportdocs[OBJECT_SUPPORT_DOCS].push(docs[OBJECT_SUPPORT_DOCS][i]);
            TOTALCONTENTUNDERCOURSE.push(docs[OBJECT_SUPPORT_DOCS][i]);
        }
    }
    if (!$.isEmptyObject(supportdocs[OBJECT_SUPPORT_DOCS])) {
        for (var i in supportdocs[OBJECT_SUPPORT_DOCS]) {
            (function(i) {
                downloadTime = THIS.getDownloadTime();
                setTimeout(function() {
                    var status = contentUtils.checkResourceDownloadStatus(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid'], OBJECT_SUPPORT_DOCS, courseid);
                    if (status != 2) {
                        if ((supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] != RESOURCE_TYPE_LINK || supportdocs[OBJECT_SUPPORT_DOCS][i]['type'] == RESOURCE_TYPE_ASSESSMENT) || (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] == RESOURCE_TYPE_LINK && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['type'] == RESOURCE_TYPE_HTML && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                            contentUtils.fileDownload(supportdocs[OBJECT_SUPPORT_DOCS][i], DOCUMENT_FORMAT, "", "", courseid, OBJECT_SUPPORT_DOCS, catid);
                        }
                    }
                }, downloadTime);
            }(i));
        }
    }
}
MobileCourseService.prototype.getCourseById = function (courseid)
{
    try {
        var course = Platform.getObjectProperty(OBJECT_COURSE, courseid, "content");
        course = $.parseJSON(course);
        return course;
    } catch (e) {
        console.log("function : getCourseById"
                + "\n message:" + e.message);
    }

};

MobileCourseService.prototype.getQuizForCourse = function (courseid, groupid)
{
    try {
        if (EXEC_MODE == "online") {
            if(COURSE_DETAILS.hasOwnProperty(courseid)){
                var quiz = [];
                quiz[OBJECT_QUIZ] = COURSE_DETAILS[courseid]["quiz"];
                return quiz;
            }
        } else {
            if (typeof groupid != "undefined" && groupid) {
                var filter = '[{"object_type":"group","object_id":' + groupid + ',"relation_object_type":' + OBJECT_QUIZ + '}]';
                var quiz = Platform.getRelatedObjectsFilterByGroup(groupid,OBJECT_COURSE, courseid, OBJECT_QUIZ);
                chapters = $.parseJSON(quiz);
                return chapters;
            } else {
                if (COURSE_DETAILS.hasOwnProperty(courseid)) {
                    var quiz = [];
                    quiz[OBJECT_QUIZ] = COURSE_DETAILS[courseid][OBJECT_QUIZ];
                    return quiz;
                } else {
                    var quiz = Platform.getRelatedObjects(OBJECT_COURSE, courseid, OBJECT_QUIZ);
                    chapters = $.parseJSON(quiz);
                    return chapters;
                }
            }
		}
    } catch (e) {
        console.log("function : getQuizForCourse"
                + "\n message:" + e.message);
    }
};

MobileCourseService.prototype.downloadAllBooksOfCourse = function(courseid)
{
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var books = this.getEbooksForCourse(courseid);
    var resourceStatus = "";
    var THIS = this;
    if(CONTROLLER.getVariable("catid_addedfor_download")){
        var catid = CONTROLLER.getVariable("catid_addedfor_download");
    }else{
        var catid = CONTROLLER.getVariable("activecatid");
    }
    for(i in books[OBJECT_BOOK]){
        (function (i) {
            var downloadTime = THIS.getDownloadTime();
            setTimeout(function () {
                try {
                    resourceStatus = contentUtils.checkResourceDownloadStatus(books[OBJECT_BOOK][i]["nid"], OBJECT_BOOK, courseid);
                    if (resourceStatus == 0) {
                        contentUtils.fileDownload(books[OBJECT_BOOK][i], RESOURCE_FORMAT, "", "", courseid, OBJECT_BOOK, catid);
                    }
                } catch (e) {
                    console.log(e.message + " book = " + JSON.stringify(books[OBJECT_BOOK]) + " index = " + i);
                }
            }, downloadTime);
        }(i));
    }
}

MobileCourseService.prototype.pauseAllDownloadsOfCourse = function(courseid)
{
    var resources = "";
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var chapters = this.getChaptersForCourse(courseid);
    var resourceStatus = "";
    var all_quizzes = this.getQuizForCourse(courseid);
    var THIS = this;
    if (!$.isEmptyObject(all_quizzes)) {
        for (var i in all_quizzes[OBJECT_QUIZ]) {
            (function(i) {
                var downloadTime = THIS.getDownloadTime();
                setTimeout(function() {
                    try{
                        var status = contentUtils.checkResourceDownloadStatus(all_quizzes[OBJECT_QUIZ][i]['nid'], OBJECT_QUIZ, courseid, all_quizzes[OBJECT_QUIZ][i]['changed']);
                        if (status == 2) {
                            Platform.pauseDownload(all_quizzes[OBJECT_QUIZ][i]['nid']);
                        }
                    } catch (e) {
                        console.log(e.message + " quiz = " + JSON.stringify(all_quizzes[OBJECT_QUIZ]) + " index = " + i);
                    }
                }, downloadTime);
            }(i));
        }
    }
    for (i in chapters[OBJECT_CHAPTER]) {
        resources = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_VIDEO));
        var supportdocs = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_SUPPORT_DOCS));
        if (!$.isEmptyObject(supportdocs[OBJECT_SUPPORT_DOCS])) {
            for (var i in supportdocs[OBJECT_SUPPORT_DOCS]) {
                (function (i) {
                    var downloadTime = THIS.getDownloadTime();
                    setTimeout(function () {
                        try {
                            var status = contentUtils.checkResourceDownloadStatus(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid'], OBJECT_SUPPORT_DOCS, courseid);
                            if (status == 2) {
                                if (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] != RESOURCE_TYPE_LINK || (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] == RESOURCE_TYPE_LINK && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['type'] == RESOURCE_TYPE_HTML && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                                    Platform.pauseDownload(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid']);
                                }
                            }
                        } catch (e) {
                            console.log(e.message + " support docs = " + JSON.stringify(supportdocs[OBJECT_SUPPORT_DOCS]) + " index = " + i);
                        }
                    }, downloadTime);
                }(i));
            }
        }
        for (j in resources[OBJECT_VIDEO]) {
            (function (j) {
                var downloadTime = THIS.getDownloadTime();
                setTimeout(function () {
                    try {
                        var content = Platform.getObjectProperty(OBJECT_VIDEO, resources[OBJECT_VIDEO][j]["nid"], "content");
                        content = $.parseJSON(content);
                        var quiz = quizService.getQuiz(content.url);
                        if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                            if (typeof quiz != "undefined") {
                                resourceStatus = contentUtils.checkResourceDownloadStatus(quiz["nid"], OBJECT_QUIZ, courseid, quiz['changed']);
                            }
                            else{
                                return;
                            }
                        } else {
                            resourceStatus = contentUtils.checkResourceDownloadStatus(resources[OBJECT_VIDEO][j]["nid"], OBJECT_VIDEO, courseid);
                        }
                        if (resourceStatus == 2) {
                            if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                                if (typeof quiz != "undefined") {
                                    Platform.pauseDownload(quiz["nid"]);
                                }
                            } else {
                                if (resources[OBJECT_VIDEO][j]['source'] != RESOURCE_TYPE_LINK || (resources[OBJECT_VIDEO][j]['source'] == RESOURCE_TYPE_LINK && ((resources[OBJECT_VIDEO][j]['type'] == RESOURCE_TYPE_HTML && ((resources[OBJECT_VIDEO][j]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                                    Platform.pauseDownload(resources[OBJECT_VIDEO][j].nid);
                                }
                            }
                        }
                    } catch (e) {
                        console.log(e.message + " resource = " + JSON.stringify(resources[OBJECT_VIDEO]) + " index = " + j);
                    }
                }, downloadTime);
            }(j));
        }
    }
    var books = this.getEbooksForCourse(courseid);
    for(i in books[OBJECT_BOOK]){
        (function (i) {
            var downloadTime = THIS.getDownloadTime();
            setTimeout(function () {
                try {
                    resourceStatus = contentUtils.checkResourceDownloadStatus(books[OBJECT_BOOK][i]["nid"], OBJECT_BOOK, courseid);
                    if (resourceStatus == 2) {
                        Platform.pauseDownload(books[OBJECT_BOOK][i].nid);
                    }
                } catch (e) {
                    console.log(e.message + " books = " + JSON.stringify(books[OBJECT_BOOK]) + " index = " + j);
                }
            }, downloadTime);
        }(i));
    }
}

MobileCourseService.prototype.deleteAllDownloadsOfCourse = function(courseid)
{
    var resources = "";
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var chapters = this.getChaptersForCourse(courseid);
    var resourceStatus = "";
    var all_quizzes = this.getQuizForCourse(courseid);
    var THIS = this;
    if (!$.isEmptyObject(all_quizzes[OBJECT_QUIZ])) {
        for (var i in all_quizzes[OBJECT_QUIZ]) {
            (function (i) {
                var downloadTime = THIS.getDownloadTime();
                setTimeout(function () {
                    try {
                        var status = contentUtils.checkResourceDownloadStatus(all_quizzes[OBJECT_QUIZ][i]['nid'], OBJECT_QUIZ, courseid, all_quizzes[OBJECT_QUIZ][i]['changed']);
                        if (status != 0) {
                            Platform.deleteFileFromInternalMemory(all_quizzes[OBJECT_QUIZ][i]['nid']);
                        }
                    } catch (e) {
                        console.log(e.message + " quiz = " + JSON.stringify(all_quizzes[OBJECT_QUIZ]) + " index = " + j);
                    }
                }, downloadTime);
            }(i));
        }
    }
    for(i in chapters[OBJECT_CHAPTER]){
        resources = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_VIDEO));
        var supportdocs = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_SUPPORT_DOCS));
        if (!$.isEmptyObject(supportdocs)) {
            for (var i in supportdocs[OBJECT_SUPPORT_DOCS]) {
                (function (i) {
                    var downloadTime = THIS.getDownloadTime();
                    setTimeout(function () {
                        try {
                            var status = contentUtils.checkResourceDownloadStatus(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid'], OBJECT_SUPPORT_DOCS, courseid);
                            if (status != 0) {
                                if (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] != RESOURCE_TYPE_LINK || (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] == RESOURCE_TYPE_LINK && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['type'] == RESOURCE_TYPE_HTML && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                                    Platform.deleteFileFromInternalMemory(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid']);
                                }
                            }
                        } catch (e) {
                            console.log(e.message + " support docs = " + JSON.stringify(supportdocs[OBJECT_SUPPORT_DOCS]) + " index = " + i);
                        }
                    }, downloadTime);
                }(i));
            }
        }
        for(j in resources[OBJECT_VIDEO]){
            (function (j) {
                var downloadTime = THIS.getDownloadTime();
                setTimeout(function () {
                    try {
                        var content = Platform.getObjectProperty(OBJECT_VIDEO, resources[OBJECT_VIDEO][j]["nid"], "content");
                        content = $.parseJSON(content);
                        var quiz = quizService.getQuiz(content.url);
                        if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                            if (typeof quiz != "undefined") {
                                resourceStatus = contentUtils.checkResourceDownloadStatus(quiz["nid"], OBJECT_QUIZ, courseid, quiz['changed']);
                            }
                            else{
//                                continue;
                                return;
                            }
                        } else {
                            resourceStatus = contentUtils.checkResourceDownloadStatus(resources[OBJECT_VIDEO][j]["nid"], OBJECT_VIDEO, courseid);
                        }
                        if (resourceStatus != 0) {
                            if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                                if (typeof quiz != "undefined") {
                                    Platform.deleteFileFromInternalMemory(quiz["nid"]);
                                }
                            } else {
                                if (resources[OBJECT_VIDEO][j]['source'] != RESOURCE_TYPE_LINK || (resources[OBJECT_VIDEO][j]['source'] == RESOURCE_TYPE_LINK && ((resources[OBJECT_VIDEO][j]['type'] == RESOURCE_TYPE_HTML && ((resources[OBJECT_VIDEO][j]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                                    Platform.deleteFileFromInternalMemory(resources[OBJECT_VIDEO][j].nid);
                                }
                            }
                        }
                    } catch (e) {
                        console.log(e.message + " resource = " + JSON.stringify(resources[OBJECT_VIDEO]) + " index = " + j);
                    }
                }, downloadTime);
            }(j));
        }
    }
    var books = this.getEbooksForCourse(courseid);
    for(i in books[OBJECT_BOOK]){
        (function (i) {
            var downloadTime = THIS.getDownloadTime();
            setTimeout(function () {
                try {
                    resourceStatus = contentUtils.checkResourceDownloadStatus(books[OBJECT_BOOK][i]["nid"], OBJECT_BOOK, courseid);
                    if (resourceStatus != 0) {
                        Platform.deleteFileFromInternalMemory(books[OBJECT_BOOK][i].nid);
                    }
                } catch (e) {
                    console.log(e.message + " books = " + JSON.stringify(books[OBJECT_BOOK]) + " index = " + j);
                }
            }, downloadTime);
        }(i));
    }
}

MobileCourseService.prototype.resumeAllDownloadsOfCourse = function(courseid)
{
    var resources = "";
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var chapters = this.getChaptersForCourse(courseid);
    var resourceStatus = "";
    var all_quizzes = this.getQuizForCourse(courseid);
    var THIS = this;
    if (!$.isEmptyObject(all_quizzes)) {
        for (var i in all_quizzes[OBJECT_QUIZ]) {
            (function (i) {
                var downloadTime = THIS.getDownloadTime();
                setTimeout(function () {
                    try {
                        var status = contentUtils.checkResourceDownloadStatus(all_quizzes[OBJECT_QUIZ][i]['nid'], OBJECT_QUIZ, courseid, all_quizzes[OBJECT_QUIZ][i]['changed']);
                        if (status != 0) {
                            Platform.resumeDownload(all_quizzes[OBJECT_QUIZ][i]['nid']);
                        }
                    } catch (e) {
                        console.log(e.message + " quiz = " + JSON.stringify(all_quizzes[OBJECT_QUIZ]) + " index = " + i);
                    }
                }, downloadTime);
            }(i));
        }
    }
    for(i in chapters[OBJECT_CHAPTER]){
        resources = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_VIDEO));
        var supportdocs = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_SUPPORT_DOCS));
        if (!$.isEmptyObject(supportdocs)) {
            for (var i in supportdocs[OBJECT_SUPPORT_DOCS]) {
                (function (i) {
                    var downloadTime = THIS.getDownloadTime();
                    setTimeout(function () {
                        try {
                            var status = contentUtils.checkResourceDownloadStatus(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid'], OBJECT_SUPPORT_DOCS, courseid);
                            if (status != 0) {
                                if (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] != RESOURCE_TYPE_LINK || (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] == RESOURCE_TYPE_LINK && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['type'] == RESOURCE_TYPE_HTML && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                                    Platform.resumeDownload(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid']);
                                }
                            }
                        } catch (e) {
                            console.log(e.message + " support docs = " + JSON.stringify(supportdocs[OBJECT_SUPPORT_DOCS]) + " index = " + i);
                        }
                    }, downloadTime);
                }(i));
            }
        }
        for(j in resources[OBJECT_VIDEO]){
            (function (j) {
                var downloadTime = THIS.getDownloadTime();
                setTimeout(function () {
                    try {
                        var content = Platform.getObjectProperty(OBJECT_VIDEO, resources[OBJECT_VIDEO][j]["nid"], "content");
                        content = $.parseJSON(content);
                        var quiz = quizService.getQuiz(content.url);
                        if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                            if (typeof quiz != "undefined") {
                                resourceStatus = contentUtils.checkResourceDownloadStatus(quiz["nid"], OBJECT_QUIZ, courseid, quiz['changed']);
                            }
                            else{
//                                continue;
                                return;
                            }
                        } else {
                            resourceStatus = contentUtils.checkResourceDownloadStatus(resources[OBJECT_VIDEO][j]["nid"], OBJECT_VIDEO, courseid);
                        }
                        if (resourceStatus == 2) {
                            if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                                if (typeof quiz != "undefined") {
                                    Platform.resumeDownload(quiz["nid"]);
                                }
                            } else {
                                Platform.resumeDownload(resources[OBJECT_VIDEO][j].nid);
                            }
                        }
                    } catch (e) {
                        console.log(e.message + " resource = " + JSON.stringify(resources[OBJECT_VIDEO]) + " index = " + j);
                    }
                }, downloadTime);
            }(j));
        }
    }
    var books = this.getEbooksForCourse(courseid);
    for(i in books[OBJECT_BOOK]){
        (function (i) {
            var downloadTime = THIS.getDownloadTime();
            setTimeout(function () {
                try {
                    resourceStatus = contentUtils.checkResourceDownloadStatus(books[OBJECT_BOOK][i]["nid"], OBJECT_BOOK, courseid);
                    if (resourceStatus == 2) {
                        Platform.resumeDownload(books[OBJECT_BOOK][i].nid);
                    }
                } catch (e) {
                    console.log(e.message + " books = " + JSON.stringify(books[OBJECT_BOOK]) + " index = " + j);
                }
            }, downloadTime);
        }(i));
    }
}

MobileCourseService.prototype.getBatchScheduleOfCourse = function(groupid,courseid)
{
    var batchScheduleOfCourse = [];
    batchScheduleOfCourse[OBJECT_COURSE] = {};
    var groups=[];
    if(!Array.isArray(OBJECT_USER_DATA.groups)){
        groups = OBJECT_USER_DATA.groups.split(",");
    }
    else{
        groups = OBJECT_USER_DATA.groups;
    }
    try {
        if (typeof OBJECT_BATCH_SCHEDULE != 'undefined' && OBJECT_BATCH_SCHEDULE != '') {
            for (var i = 0;i < groups.length;i++) {
                var batchSchedule = $.parseJSON(Platform.getRelatedObjects(OBJECT_GROUP, groups[i], OBJECT_BATCH_SCHEDULE));
                if (batchSchedule != null && !$.isEmptyObject(batchSchedule[OBJECT_BATCH_SCHEDULE]))
                    batchSchedule = batchSchedule[OBJECT_BATCH_SCHEDULE][0];


                if (typeof batchSchedule != 'undefined' && batchSchedule != null && !$.isEmptyObject(batchSchedule[OBJECT_COURSE])) {
                    for (var j in batchSchedule[OBJECT_COURSE]) {
                        if (batchSchedule[OBJECT_COURSE][j].nid == courseid) {
                            batchScheduleOfCourse[OBJECT_COURSE] = batchSchedule[OBJECT_COURSE][j];
                            break;
                        }
                    }
                }
            }
        }
        return batchScheduleOfCourse;
    } catch (e) {
        console.log("function : getBatchScheduleOfCourse"
                + "\n message:" + e.message);
        return batchScheduleOfCourse;
    }
}

MobileCourseService.prototype.getNotesofUserByCourse = function(courseid)
{
    try {

        if(typeof NOTES_OF_COURSE != "undefined" && NOTES_OF_COURSE.length > 0){
            for (var i = 0; i < NOTES_OF_COURSE.length; i++) {
                if(typeof NOTES_OF_COURSE[i].courseid != "undefined" && NOTES_OF_COURSE[i].courseid == courseid)
                    return NOTES_OF_COURSE[i].notes_data;
            }
        }

        var courseService = CONTROLLER.getService("Course");
        var i = 0;
        var courseid = CONTROLLER.getVariable("activecourseid");
        var catid = CONTROLLER.getVariable("activecatid");
        var groupid = CONTROLLER.getVariable("activegroupid");
        var courseNotes = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getnotesofuserby' + OBJECT_COURSE + '?uid=' + OBJECT_USER_ID + '&nid=' + courseid);
        courseNotes = $.parseJSON(courseNotes);
        var notes = {};
        var status = courseNotes['status'];
        if (status == 200) {
            var data = courseNotes['data'];
            notes[OBJECT_NOTES] = $.parseJSON(data);
            notes[OBJECT_NOTES] =sortByDuration(notes[OBJECT_NOTES])
            var chapter = courseService.getChaptersForCourse(courseid, groupid);
            for (var i = 0; i < notes[OBJECT_NOTES].length; i++) {
                var chapterid = "";
                notes[OBJECT_NOTES][i]['catid'] = catid;
                notes[OBJECT_NOTES][i]['groupid'] = groupid;
                notes[OBJECT_NOTES][i]['courseid'] = courseid;
                notes[OBJECT_NOTES][i].originalDuration = notes[OBJECT_NOTES][i].duration;
                notes[OBJECT_NOTES][i].note = notes[OBJECT_NOTES][i].note.replace(/^\n+|\n+$/g, '').replace(/^\t+|\t+$/g, '').replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
                notes[OBJECT_NOTES][i].duration = getVideoDurationFromSeconds(parseInt(notes[OBJECT_NOTES][i].duration, 10)) + "m";

                var content = Platform.getObjectProperty(OBJECT_VIDEO, notes[OBJECT_NOTES][i]['resourceid'], "content");
                content = $.parseJSON(content);
                if(content[OBJECT_CHAPTER].length > 1){
                    for (var j in content[OBJECT_CHAPTER]) {
                        for (var k in chapter[OBJECT_CHAPTER]) {
                            if(content[OBJECT_CHAPTER][j] == chapter[OBJECT_CHAPTER][k]['nid'])
                                chapterid = content[OBJECT_CHAPTER][j];
                        }
                    }
                }else{
                    chapterid = content[OBJECT_CHAPTER][0];
                }
                notes[OBJECT_NOTES][i]['chapterid'] = chapterid;
            }
            var new_notes_list = this.getNotesofCourseWithTopicDetails(notes, chapter);

            var notesCourse ={};
            notesCourse.courseid = courseid;
            notesCourse.notes_data = new_notes_list;
            NOTES_OF_COURSE.push(notesCourse);

            return new_notes_list;
        } else {
            return false;
        }
    } catch (e) {
        console.log("function : getCourseById"
                + "\n message:" + e.message);
    }

};

MobileCourseService.prototype.getNotesofCourseWithTopicDetails = function(notes, chapter)
{
    try {
        var new_notes_list = [];
        var notes_list = [];
        var chapterService = CONTROLLER.getService("Chapter");
         if (notes[OBJECT_NOTES].length > 0) {
            notes_list = notes;

            new_notes_list[OBJECT_CHAPTER] = [];
            // Initializing new_notes_list array to store notes
            for (var i = 0; i < chapter[CLIENT_OBJECT_CHAPTER].length; i++) {
                new_notes_list[OBJECT_CHAPTER][i] = {};
                new_notes_list[OBJECT_CHAPTER][i].title = chapter[OBJECT_CHAPTER][i].title;
                new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO] = [];
                var chapterid = chapter[OBJECT_CHAPTER][i].nid;
                var resources = chapterService.getResourcesForChapter(chapterid, CONTROLLER.getVariable("activecourseid"))// take all videos of each chapter
                for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                    new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = {};
                    new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k].title = resources[OBJECT_VIDEO][k].title;
                    new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES] = [];
                }
            }

            //comparing all the chapters with the notes_list chapters
            for (var i = 0; i < chapter[CLIENT_OBJECT_CHAPTER].length; i++) {
                for (var j = 0; j < notes[OBJECT_NOTES].length; j++) {
                    if (chapter[OBJECT_CHAPTER][i].nid == notes[OBJECT_NOTES][j].chapterid) {
                        var chapterid = chapter[OBJECT_CHAPTER][i].nid;
                        var resources = chapterService.getResourcesForChapter(chapterid, CONTROLLER.getVariable("activecourseid"));
                        //getting all resorces of chapter and adding the notes to array if it contains
                        for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                            if (resources[OBJECT_VIDEO][k].nid == notes[OBJECT_NOTES][j].resourceid) {
                                new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].push(notes[OBJECT_NOTES][j]);
                            }
                        }
                    }
                }
            }
            for (i = chapter[CLIENT_OBJECT_CHAPTER].length - 1; i >= 0; i--) {
                notescount = 0;
                for (var k = new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO].length - 1; k >= 0; k--) {
                    notescount = new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO].length;
                    count = new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length;
                    // removing resourses which does not have notes
                    if (new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length == 0) {
                        new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = "";
                        new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO].splice(k, 1);
                        notescount = notescount - 1;
                    }
                }
                // removing chapters which does not have resourse with notes
                if (notescount == 0) {
                    new_notes_list[OBJECT_CHAPTER][i] = "";
                    new_notes_list[OBJECT_CHAPTER].splice(i, 1);
                }
            }
            }
             return new_notes_list;
    } catch (e) {
        console.log("function : getCourseById"
                + "\n message:" + e.message);
    }

};

MobileCourseService.prototype.getFlashCardCollection = function(courseid){
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/get/flashcardcollections?cat_id=' + courseid + '&topic_level=true';
        if (Platform.getNetworkState() === true) {
            var fc_collection = $.parseJSON(Platform.makeHttpGetCall(url));
            if(fc_collection.status == 200){
                var fd_collection_data = $.parseJSON(fc_collection.data);
                    return fd_collection_data;
            }else if(fc_collection.status == 401){
                alert(ERROR_MESSAGE);
                console.log("The "+OBJECT_CATEGORY+" tid passed is not valid.");
                return false;
            }else{
                alert(ERROR_MESSAGE);
                return false;
            }
        }else{
            alert(INTERNET_ERROR);
            return;
        }
    }catch(e){
        console.log("function : getFlashCardCollection"
                + "\n message:" + e.message);
    }
}


MobileCourseService.prototype.getObjectCourseProperties = function(courseid){
    try{
        if(COURSE_PROPERTIES.hasOwnProperty(courseid) == false){
            var data = Platform.getObjectProperty(OBJECT_COURSE, courseid, "content");
            COURSE_PROPERTIES[courseid] = data;
            return data;
        }else{
            return COURSE_PROPERTIES[courseid];
        }
    }catch(e){
        console.log("getObjectCourseProperties " + e.message);
    }
};

MobileCourseService.prototype.getDownloadableContentUnderCourse = function(catid){
    try {
        var uniqueNids = {};
        TOTALCONTENTUNDERCOURSE = TOTALCONTENTUNDERCOURSE.filter(item => {
            if (!uniqueNids[item.nid]) {
              uniqueNids[item.nid] = true;
              return true;
            }
            return false;
          });
          var downloadTypes = [RESOURCE_TYPE_ASSESSMENT, RESOURCE_TYPE_DOCUMENT, RESOURCE_TYPE_VIDEO];
          TOTALCONTENTUNDERCOURSE = TOTALCONTENTUNDERCOURSE.filter(item => {
            if (downloadTypes.includes(item.type)) {
                if (item.type == RESOURCE_TYPE_DOCUMENT && item.source != "Upload") {
                    return false;
                }
              return true;
            }
            return false;
          });
          Platform.setPreference('total_resource_count'+catid,TOTALCONTENTUNDERCOURSE.length);
          TOTALCONTENTUNDERCOURSE=[]
    } catch (e) {
        console.log("getDownloadableContentUnderCourse" )
    }
}
function MobileBooksService(){};

MobileBooksService.prototype.downloadSingleChapter = function(chapterid,changed){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'fromid?nid=' + chapterid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&changed=' + changed + "&version=" + API_VERSION, OBJECT_CHAPTER+"-"+chapterid);
    }
    catch(e){
        console.log("function : downloadSingleChapter"
            + " message" + e.message);
    }
};

MobileBooksService.prototype.downloadChaptersForCourseAsync = function(courseid){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'sfor' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET, OBJECT_CHAPTER+"-"+courseid);
    }
    catch(e){
        console.log("function : downloadChaptersForCourseAsync"
            + " message" + e.message);
    }
};

MobileBooksService.prototype.getBooksForCourse = function(courseid)
{
    try{
        var books = Platform.getRelatedObjects(OBJECT_COURSE, courseid, OBJECT_BOOK);
        if(books != ""){
            books = $.parseJSON(books);
        }
        return books;
    }
    catch(e){
        console.log( "function : getBooksForCourse"
            + "\n message:" + e.message);
    }
};

function downloadModifiedChapter(pendingobjectdata){
    if((pendingobjectdata.operation == 'modified' || pendingobjectdata.operation == 'created') && pendingobjectdata.offline != '0'){
        DataManager.downloadSingleChapter(pendingobjectdata.nid,pendingobjectdata.changed);
    } else if(pendingobjectdata.offline == '0' || pendingobjectdata.operation == 'delete'){
        DataManager.removeObject(OBJECT_CHAPTER,pendingobjectdata.nid);
        Platform.deleteVideoViewHistoryByType(pendingobjectdata.nid,"chapter");
    }
}

function MobileResourceService(){};

MobileResourceService.prototype.getResourceById = function(resourceid)
{
    try{
        var resource = Platform.getObjectProperty(OBJECT_VIDEO, resourceid, "content");
        resource = $.parseJSON(resource);
        return resource;
    }
    catch(e){
        console.log( "function : getResourceById"
            + "\n message:" + e.message);
    }
};
MobileResourceService.prototype.getContentById = function(nid)
{
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/getcontentfromid?nid=' + nid;
        var content = Platform.makeHttpGetCall(url);
        content = $.parseJSON(content);
        return content;
    }
    catch(e){
        console.log( "function : getContentById"
            + "\n message:" + e.message);
    }
};

MobileResourceService.prototype.downloadResourceAsync = function(resourceid, youtube_id, operation, downloadmessage, downloadExpiry, download_complete_url,courseid,type,categoryid,container_id)
{
    try{
        var containerid = "";
        if(type){
            var resource = Platform.getObjectProperty(type,resourceid,"content");
        }
        else{
            resource = Platform.getObjectProperty(OBJECT_VIDEO,resourceid,"content");
        }
        resource = $.parseJSON(resource);
        var filepath = getResourceLocalPath(resource);
        var downloadTitle = resource.title;
        if(resource.type == RESOURCE_TYPE_WEBINAR){
            if(typeof resource.webinar_record_id !== "undefined" && resource.webinar_record_id != ""){
                resource = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR,resource.webinar_record_id,"content"));
                youtube_id = resource.youtube_id;
            }
            else{
                return;
            }
        }

        if(resource.type == PRACTICE || resource.type == CERTIFICATE || resource.type == OBJECT_QUIZ || resource.type == RESOURCE_TYPE_ASSESSMENT || resource.type == RESOURCE_TYPE_FLASHCARD) {
            containerid = container_id
        }else{
            containerid = resource.nid;
        }

        var format = "";
        var categoryparam = "";
        var courseparam = "";
        var downloadapi = "",downloadstreamtype = "";
        var lang=Platform.getPreference("lang");
        if(!$.isEmptyObject(resource)){
            if (typeof ENABLE_LANG_SUPPORT != "undefined" && ENABLE_LANG_SUPPORT == "true") {
                    if (!$.isEmptyObject(resource.language)) {
                        if (jQuery.inArray(lang, resource.language) >= 0) {
                        } else {
                            return false;
                        }
                    }
            }
            if( typeof resource.filetype != 'undefined' &&  resource.filetype != null && (resource.filetype).toLowerCase() == 'pdf'){
                format = '&format=' + DOCUMENT_FORMAT;
            }
            else{
                if(resource.type == "Video" && typeof ENABLE_DOWNLOAD_RESOLUTION_SWITCH != 'undefined' && ENABLE_DOWNLOAD_RESOLUTION_SWITCH == true){
                    if(typeof DOWNLOAD_RESOURCE_FORMAT != "undefined" && DOWNLOAD_RESOURCE_FORMAT != ""){
                        format = '&format=' + DOWNLOAD_RESOURCE_FORMAT;
                    }else{
                        format = '&format=' + RESOURCE_FORMAT;
                    }
                }else{
                    format = '&format=' + RESOURCE_FORMAT;
                }
            }
            if(DOCUMENT_FORMAT == "original"){
                if( typeof resource.filetype != 'undefined' && resource.filetype != null &&(resource.filetype).toLowerCase() == 'pdf' && DOCUMENT_TRANSCODE == true){
                    downloadapi = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO + "/zip";
                }
                else{
                    downloadapi = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO;
                }
            }else{
                    downloadapi = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO;
            }
            if(typeof courseid != 'undefined' && courseid != ""){
                courseparam = "&courseid="+courseid;
            }
            else{
                courseparam = "";
            }
            if (typeof categoryid != 'undefined' && categoryid != ""){
                var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, categoryid, "content"));
                categoryparam = "&cat_id="+category.tid;
            } else {
                categoryparam = "";
            }
            if(typeof DOWNLOAD_VIDEO_TYPE != "undefined" && resource.type == "Video"){
                downloadstreamtype = "&stream="+DOWNLOAD_VIDEO_TYPE;
            }
			else if(typeof DOWNLOAD_STREAM != "undefined" && resource.type == "Video") {
				downloadstreamtype = "&stream="+DOWNLOAD_STREAM;
			}
            else if(resource.type == "Video"){
                downloadstreamtype = "&stream=mp4";
            }
            else{
                downloadstreamtype = "";
            }
            if(operation == 'modified'){
                var downloadurl = downloadapi + '?nid=' + resource.nid + '&redirect=true'+ format +'&youtubeid=' + youtube_id + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED + '&download=true' + courseparam + categoryparam + downloadstreamtype;
            } else {
                downloadurl = downloadapi + '?nid=' + resource.nid + '&redirect=true'+ format + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED + '&download=true' + courseparam + categoryparam + downloadstreamtype;
            }
            if(typeof CONTENT_EXPIRY_HOURS != "undefined" && CONTENT_EXPIRY_HOURS != null && CONTENT_EXPIRY_HOURS != ""){
                downloadExpiry = CONTENT_EXPIRY_HOURS;
            }
            if (resource.type == PRACTICE || resource.type == CERTIFICATE || resource.type == OBJECT_QUIZ || resource.type == RESOURCE_TYPE_ASSESSMENT) {
                format = "";
                downloadExpiry="";
 
                downloadurl = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_QUIZ + '?quizid=' + resourceid + "&auth=" + AUTH_ENABLED + "&nid=" + containerid + categoryparam + courseparam + "&additionaldetails=true";
            }
            if (resource.type == RESOURCE_TYPE_FLASHCARD) {
                format = "";
                downloadExpiry="";
                categoryparam = "&cat_id="+categoryid;
                downloadurl = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_QUIZ + '?quizid=' + container_id +categoryparam +courseparam+ "&auth=" + AUTH_ENABLED + "&nid="+container_id + "&additionaldetails=true";
            }
            if(resource.type == RESOURCE_TYPE_ROLEPLAY){
                resource.nid = resource.roleplay_nid;
                downloadurl = OBJECT_SERVER_DOMAIN + '/api/downloadroleplay?roleplayid=' + resource.roleplay_nid +"&auth=" + AUTH_ENABLED + "&nid=" + containerid + '&download=true' + courseparam + categoryparam;
            }
            if(typeof DOWNLOAD_VIDEO_TYPE != "undefined" && resource.type == "Video"){
                filepath = filepath.substring(0, filepath.length-3);
                filepath = filepath + DOWNLOAD_VIDEO_TYPE;
            }

            if (downloadmessage != "") {
                downloadmessage = downloadTitle + t(" downloaded successfully");
            }

            if(resource.type == "Video"){
                if(typeof ENABLE_DOWNLOAD_RESOLUTION_SWITCH != 'undefined' && ENABLE_DOWNLOAD_RESOLUTION_SWITCH == true){
                    if(typeof DOWNLOAD_RESOURCE_FORMAT != "undefined" && DOWNLOAD_RESOURCE_FORMAT != ""){
                        var resource_format = DOWNLOAD_RESOURCE_FORMAT;
                    }else{
                        var resource_format = RESOURCE_FORMAT
                    }
                }else{
                    var resource_format = RESOURCE_FORMAT
                }
            }else{
                var resource_format = RESOURCE_FORMAT
            }
            if (resource.type != RESOURCE_TYPE_FLASHCARD) {
                var object_id = resource.nid
            } else {
               var object_id =  containerid;
            }
            var paramsJson = {'objectid': object_id,'objecttype':'resource',
            'serverurl':downloadurl,'localurl':filepath, 'message':downloadmessage,
            'sync':FILE_DOWNLOAD_ASYNC, 'expiryduration':downloadExpiry, 'courseid' : courseid, 'categoryid' : categoryid, 'groupid' : CONTROLLER.getVariable("activegroupid"),
            'onComplete':download_complete_url, 'youtubeid':youtube_id,'format':resource_format,'type':'content', 'containerid':containerid};
            Platform.downloadFile(JSON.stringify(paramsJson));
        }
    }
    catch(e){
        console.log( "function : downloadResourceAsync"
            + "\n url:" + downloadurl
            + "\n message" + e.message);
    }
};

MobileResourceService.prototype.getResourceUrlFromResourceData = function(nid,type,chapterid,courseid,categoryid, linkedObjectItemId)
{
    var categoryparam = "";
    var courseparam = "";
    var chapterparam = "";
    if(typeof type != 'undefined' && type != null && type.toLowerCase() == 'pdf'){
        var format = '&format=original';
    }else if(typeof RESOURCE_TYPE_TEST_COLLECTION != "undefined" && type == RESOURCE_TYPE_TEST_COLLECTION){
        type=RESOURCE_TYPE_TEST_COLLECTION
        format = "";
    }
    else{
        format = '&format=' + RESOURCE_FORMAT;
    }
    if(typeof chapterid != 'undefined' && chapterid != ''){
        chapterparam = "&topicid="+chapterid;
    } else {
        chapterparam = '';
    }
    if(typeof courseid != 'undefined' && courseid != ""){
        courseparam = "&courseid="+courseid;
    }
    else{
        courseparam = "";
    }
    if (typeof categoryid != 'undefined' && categoryid != ""){
        var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, categoryid, "content"));
        categoryparam = "&cat_id="+category.tid;
    } else {
        categoryparam = "";
    }
    if(typeof linkedObjectItemId != 'undefined' && linkedObjectItemId != ""){
        linkedObjectParam = "&item_nid="+linkedObjectItemId;
    }
    else{
        linkedObjectParam = "";
    }
    var resource = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO + '?nid=' + nid + format + chapterparam + courseparam + categoryparam + linkedObjectParam + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED);
    resource = $.parseJSON(resource);
    var status = resource['status'];
    if (status == 200)
    {
        var resourcedata = resource['data'];
        resourcedata = $.parseJSON(resourcedata);
        var resourceurl = resourcedata[0].videourl;
        if(typeof type != 'undefined' && type != null && type.toLowerCase() == 'pdf'){
            resourceurl = resourceurl["original"];
        }else if(typeof RESOURCE_TYPE_TEST_COLLECTION != "undefined" && type == RESOURCE_TYPE_TEST_COLLECTION){
            type=RESOURCE_TYPE_TEST_COLLECTION
            resourceurl = resourcedata[0];
        }
        else{
            resourceurl = resourceurl[RESOURCE_FORMAT];
        }
        return resourceurl;

    }
    else {
        return "error";
    }
}

MobileResourceService.prototype.getBreaksDataForResource = function(resourceid)
{
    try {
        if (EXEC_MODE == "online") {
            var breaks = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getclippingbreaks?clip_id=' + resourceid + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED);
            breaks = $.parseJSON(breaks);
            var status = breaks['status'];
            if (status == 200)
            {
                var breaksdata = $.parseJSON(breaks['data']);
                CONTROLLER.setVariable("breaksdata",breaksdata);
                return breaksdata;
            }
            else{
                return "error";
            }
        } else if (EXEC_MODE == "mobile" && APP_TYPE == "android") {
            var breaksdata = JSON.parse(Platform.getClippingBreaks(resourceid));
            if(!checkIsEmpty(breaksdata) && Object.keys(breaksdata).length > 0){
                for(var i=0; i<Object.keys(breaksdata).length; i++){
                    breaksdata[Object.keys(breaksdata)[i]] = $.parseJSON(Object.values(breaksdata)[i]);
                }
            }
        }
        if(checkIsEmpty(breaksdata) || Object.keys(breaksdata).length == 0){
            return "error";
        }else{
            CONTROLLER.setVariable("breaksdata",breaksdata);
            return breaksdata;
        }
    } catch (e) {
        console.log( "function : getBreaksDataForResource"
            + "\n message " + e.message);
    }
    
};

function getResourceLocalPath(resource){
    try
    {

        var cat_tag = CONTROLLER.getVariable("category_tagname")
        if ( typeof cat_tag != "undefined" && cat_tag == 'flash_card' && resource.type == OBJECT_FLASHCARD || resource.type == "Flashcard") {
            filepath = "video/" + resource.nid + "/" + resource.nid + '_' + resource.changed + ".zip";
            return filepath;
        }
        
        var filepath = "/video";

        if(resource.type == RESOURCE_TYPE_ROLEPLAY){
            filepath = "video/" + resource.roleplay_nid + "/" + resource.roleplay_nid + '_' + resource.changed + ".zip";
            return filepath;
        }

        if (resource.type == PRACTICE || resource.type == CERTIFICATE || resource.type == OBJECT_QUIZ || resource.type == RESOURCE_TYPE_ASSESSMENT || resource.type == RESOURCE_TYPE_FLASHCARD || resource.type == "Flashcard") {
            if (resource.type == RESOURCE_TYPE_FLASHCARD || resource.type == "Flashcard") {
                filepath = "video/" + resource.url + "/" + resource.url + '_' + resource.changed + ".zip";
            } else {
                filepath = "video/" + resource.nid + "/" + resource.nid + '_' + resource.changed + ".zip";
            }
            return filepath;
        }

        if(resource.type == RESOURCE_TYPE_HTML || resource.type.toLowerCase().indexOf('scorm') >= 0){
            filepath = "video/" + resource.nid + "/content.zip";
            return filepath;
        }

        if(resource.resourceurl && resource.filetype != "html" && resource.url.indexOf("sites/default") > -1){
            var resourceurl = resource.resourceurl;
            resourceurl = resourceurl.split('?');
            resourceurl = resourceurl[0];
            resourceurl = resourceurl.split("/").slice(3).join("/");
        }
        else{
            if(resource.url.indexOf("sites/default") > -1){
                if (resource.url.indexOf("sites/default/files") > -1) {
                    resourceurl = resource.url.split("sites/default/files/");
                    resourceurl = resourceurl[1];
                    if (resource.filetype == 'mp3' || resource.type == 'Video') {
                        resourceurl = resourceurl + ".mp4";
                    }
                    else if (resource.filetype.toLowerCase() == 'pdf') {
                        resourceurl = resourceurl + ".zip";
                    }
                }else  if(resource.url.indexOf("sites/default/videos") > -1){
                     resourceurl =  resource.url.split("sites/default/videos/");
                    resourceurl= resourceurl[1];
                     if (resource.filetype == 'mp3' || resource.type == 'Video') {
                        resourceurl = resourceurl + ".mp4";
                    }
                    else if (resource.filetype.toLowerCase() == 'pdf') {
                        resourceurl = resourceurl + ".zip";
                    }
                }
            }
            else if(resource.url.indexOf("sites") > -1){
                resourceurl = resource.url.split("/").slice(3).join("/");
                if (resource.filetype == 'mp3' || resource.type == 'Video') {
                    resourceurl = resourceurl + ".mp4";
                }
                else if (resource.filetype.toLowerCase() == 'pdf') {
                    resourceurl = resourceurl + ".zip";
                }
            } else {
                if(resource.type == RESOURCE_TYPE_WEBINAR){
                    var webinarRecordObject = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR,resource.webinar_record_id,"content"));
                    var webinar_record_youtube_id = webinarRecordObject["url"].split("/")["1"]
                    resourceurl = webinarRecordObject.nid + "/" + webinar_record_youtube_id;
                    Platform.addRelation(OBJECT_VIDEO_WEBINAR, webinarRecordObject.nid, OBJECT_VIDEO, resource.nid, "");
                }
                else{
                    resourceurl = resource.url;
                }
            }
        }
        if (resource.filetype == 'mp3' || resource.type == 'Video' || resource.type == RESOURCE_TYPE_WEBINAR) {
            filepath = filepath + "/" + resourceurl;
			if(resource.type == RESOURCE_TYPE_WEBINAR){
				clipping_murl = webinarRecordObject["clipping_murl"] || null;
			}
			else{
				clipping_murl = resource["clipping_murl"] || null;
			}

            if(DOWNLOAD_STREAM == "m3u8" && (typeof clipping_murl == "undefined" || (typeof clipping_murl != "undefined" && clipping_murl != "mp4" ) ) ){
                filepath = filepath.replace(".mp4",".m3u8");
            }

            //Replace format of download from settings
            var temp = filepath.split(".");
            var newString = temp[0].substring(0, temp[0].length-3);
            if(typeof ENABLE_DOWNLOAD_RESOLUTION_SWITCH != 'undefined' && ENABLE_DOWNLOAD_RESOLUTION_SWITCH == true){
                if(typeof DOWNLOAD_RESOURCE_FORMAT != "undefined" && DOWNLOAD_RESOURCE_FORMAT != ""){
                    newString = newString + DOWNLOAD_RESOURCE_FORMAT;
                }else{
                    newString = newString + RESOURCE_FORMAT;
                }
            }else{
                newString = newString + RESOURCE_FORMAT;
            }
            filepath = newString + "." + temp[1];
            if(typeof ENCRYPTION_ENABLED != "undefined" && ENCRYPTION_ENABLED != "" && ENCRYPTION_ENABLED == "true"){
                generateEncryptedPath(filepath);
            }
        } else if (resource.filetype.toLowerCase() == 'pdf') {
             if(DOCUMENT_FORMAT == "native"){
                filepath = filepath + "/" + resource.nid + "/" + resource.youtube_id + ".pdf";
            }
            else{
                resourceurl = resourceurl.replace("pdf","zip");
                resourceurl = resourceurl.replace("html","zip");
                filepath = filepath + "/" + resourceurl;
            }
        }
        return filepath;
    }
    catch(e){
        console.log( "function : getResourceLocalPath"
            + "\n message" + e.message);
    }
};

function generateEncryptedPath(filepath){
    if(filepath.indexOf("m3u8") >= 0){
        filepath = filepath.replace('.m3u8','.em3u8');
    }
    else{
        filepath = filepath.replace('.mp4','.emp4');
    }
    return filepath;
}

function getResourceImagePath(resourceid){
    try
    {
        var resourceimagepath = "/video/" + resourceid + "/" + POSTER_IMAGE_NAME + ".jpg";
        return resourceimagepath;
    }
    catch(e){
        console.log( "function : getResourceImagePath"
            + "\n message:" + e.message);
    }
}

MobileResourceService.prototype.getIndexOfresource = function(resourceid){
    try{
        var index = Platform.getRelatedObjects(OBJECT_VIDEO, resourceid, OBJECT_VIDEO_INDEX);
        index = $.parseJSON(index);
        return index;
    }
    catch(e){
        console.log("function : getIndexOfresource, message: " + e.message);
    }

}

MobileResourceService.prototype.getDownloadedContentForWebinar = function(webinar_record_id){
    var resourceObj = $.parseJSON(Platform.getRelatedObjects(OBJECT_VIDEO_WEBINAR,webinar_record_id,OBJECT_VIDEO));
    return resourceObj;
}

MobileResourceService.prototype.downloadSingleResourceMeta = function(resourceid,changed){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'fromid?nid=' + resourceid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&changed=' + changed + "&version=" + API_VERSION, "resourcecontent-"+resourceid);
    }
    catch(e){
        console.log("function : downloadSingleResourceMeta"
            + " message" + e.message);
    }
};

MobileResourceService.prototype.deleteResource = function(resourceid){
    try{
        var resource = Platform.getObjectProperty(OBJECT_VIDEO,resourceid,"content");
        if(resource != ""){
            resource = $.parseJSON(resource);
        }
        if(!$.isEmptyObject(resource)){
            var resourcefilepath = getResourceLocalPath(resource);
            var resourcefileAvailable = Platform.isContentAvailableInFilesTable(resourceid,resource.youtube_id,RESOURCE_FORMAT);
            if(resourcefileAvailable == true && resourcefilepath != OBJECT_DATA_FOLDER && resourcefilepath != OBJECT_RESOURCE_FOLDER){
                Platform.deleteFileFromDocRoot(resourcefilepath);
            }
            var resourceimagepath = getResourceImagePath(resourceid);
            var resourceimagefileAvailable = Platform.isContentPosterAvailableInFilesTable(resourceid,"","");
            if(resourceimagefileAvailable == true && resourceimagepath != OBJECT_DATA_FOLDER && resourcefilepath != OBJECT_RESOURCE_FOLDER){
                Platform.deleteFileFromDocRoot(resourceimagepath);
            }
        }
    }
    catch(e){
        console.log("function : deleteResource"
            + " message" + e.message);
    }
};

MobileResourceService.prototype.downloadResourcesForChapterAsync = function(chapterid){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'sfor' + OBJECT_CHAPTER + '?nid=' + chapterid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET, OBJECT_VIDEO+"-"+chapterid);
    }
    catch(e){
        console.log("function : downloadResourcesForChapterAsync"
            + " message" + e.message);
    }
};

MobileResourceService.prototype.getAllResource = function()
{
    try{
        var categories = Platform.getObjectsOfType(OBJECT_VIDEO);
        return $.parseJSON(categories);
    }
    catch(e){
        console.log( "function : getAllResource"
            + "\n message:" + e.message);
    }

};

MobileResourceService.prototype.getResourceIdFromExternalId = function(externalid)
{
    try{
        var mapping = Platform.getObjectProperty("resourcemapping", externalid, "content");
        if(mapping != "" && mapping != null){
            mapping = $.parseJSON(mapping);
            if(!$.isEmptyObject(mapping)){
                return mapping.nid;
            }
            else{
                return "";
            }
        }
        else
        {
            return "";
        }
    }
    catch(e){
        console.log( "function : getResourceIdFromExternalId"
            + "\n message:" + e.message);
    }

};

MobileResourceService.prototype.getResourcePath = function(resourceid,courseid,catid,type)
{
    try{
        var filedata = Array();
        var chaptersubject = false;
        var contentUtilsService = CONTROLLER.getService("ContentUtils");
        var tocService = CONTROLLER.getService("Toc");
        var resource = "";
        var filepath = "";
        var courseparam = "";
        var categoryparam = "";
        var fileAvailable = "";
        if(type == "Webinar") type = OBJECT_VIDEO;
        if(typeof CONTENT_IN_MULTIPLE_SDCARDS != "undefined" && CONTENT_IN_MULTIPLE_SDCARDS == true && typeof USER_PRODUCT_TYPE != "undefined" && USER_PRODUCT_TYPE == "offline"){

            var sdcard = tocService.getTocSdCardInfo(courseid);
            if(sdcard !== false){
                var chaptersofsdcard = tocService.getChaptersOfSdCard();
                var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content"));
                if(typeof chaptersofsdcard[category.title] == "undefined" || chaptersofsdcard[category.title] == ""){
                    chaptersofsdcard = "";
                }
                if(chaptersofsdcard != ""){
                    for(j=0;j<chaptersofsdcard[category.title].length;j++){
                        if(chaptersofsdcard[category.title][j].nid == courseid){
                            chaptersubject = true;
                        }
                    }
                }
                if(chaptersubject == false){
                    var sdcard = tocService.getTocSdCardInfo(courseid);
                    filedata[0] = 'error';
                    if(typeof sdcard == "undefined" || sdcard == ""){
                        filedata[1] = "Please insert SD card and try again";
                    }
                    else{
                        filedata[1] = "Please insert SD card " + sdcard + " to view this.";
                    }
                    return filedata;
                }
            }
        }

        if(typeof type == "undefined" || type == "" || type == "Video"){
            type = OBJECT_VIDEO;
        }
        resource = Platform.getObjectProperty(type, resourceid, "content");
        resource = $.parseJSON(resource);

        filepath = "video";

        if(resource.resourceurl && resource.filetype != "html" && resource.url.indexOf("sites/default") > -1){
            var resourceurl = resource.resourceurl;
            resourceurl = resourceurl.split('?');
            resourceurl = resourceurl[0];
            resourceurl = resourceurl.split("/").slice(3).join("/");
        }
        else if(resource.type == RESOURCE_TYPE_WEBINAR && resource.webinar_record_id){
            var webinarRecordObject = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR,resource.webinar_record_id,"content"));
            var webinar_record_youtube_id = webinarRecordObject["url"].split("/")["1"]
            resourceurl = webinarRecordObject.nid + "/" + webinar_record_youtube_id;
        } else {
            resourceurl = resource.url;
        }

        if (resource.filetype == 'mp3' || resource.type == 'Video' || resource.type == RESOURCE_TYPE_WEBINAR) {
            filepath = filepath + "/" + resourceurl;
            if(resource.type == RESOURCE_TYPE_WEBINAR){
                clipping_murl = webinarRecordObject["clipping_murl"] || null;;
            }
            else{
                clipping_murl = resource["clipping_murl"] || null;;
            }
            if(DOWNLOAD_STREAM == "m3u8" && (typeof clipping_murl == "undefined" || (typeof clipping_murl != "undefined" && clipping_murl != "mp4" ) ) ){
               filepath = filepath.replace(".mp4",".m3u8");
            }
            if(typeof ENCRYPTION_ENABLED != "undefined" && ENCRYPTION_ENABLED != "" && ENCRYPTION_ENABLED == "true"){
                filepath = generateEncryptedPath(filepath);
            }
        } else if(resource.filetype != null && resource.filetype.toLowerCase() == 'pdf'){
            filepath = filepath + "/" + resourceurl;
        }
        else if( resource.filetype == 'html'){
            filepath = "/" + resource.url;
            params = "?content=true&local=" + timestamp;
        }

        if(resource.type == RESOURCE_TYPE_WEBINAR && resource.webinar_record_id){
            youtubeid = webinarRecordObject.youtube_id;
        }
        else{
            youtubeid = resource.youtube_id
        }


        fileAvailable = contentUtilsService.isFileAvailable(resource.nid,youtubeid,courseid,"video",catid);

        if(resource.type != RESOURCE_TYPE_WEBINAR && (fileAvailable.filestatus == "Available") ||
                (fileAvailable.filestatus == "In Progress") ){
                filepath = filepath.replace(resource.youtube_id,fileAvailable.youtubeid);
        }

        if( fileAvailable.filestatus == "Not Available" || (fileAvailable.filestatus == "In Progress" && fileAvailable.version != "old") || USER_PRODUCT_TYPE == "online")
        {
            if(Platform.getNetworkState() == true && resource.filetype == 'html' && USER_PRODUCT_TYPE != "offline"){
                filedata[0] = 'online';
                filedata[1] = Platform.getLocalServerPath() + "/" + resource.url + params;
            }
            else if(Platform.getNetworkState() == true && USER_PRODUCT_TYPE != "offline")
            {
                //online redirect api call for type video
                if(courseid){
                    courseparam = "&courseid="+courseid;
                }
                else{
                    courseparam = "";
                }
                if(catid){
                    var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content"));
                    categoryparam = "&cat_id="+category.tid;
                }
                else{
                    categoryparam = "";
                }
                var onlineResourceId = resourceid;
                if (resource.type == RESOURCE_TYPE_WEBINAR && resource.webinar_record_id) {
                    onlineResourceId = resource.webinar_record_id;
                }
                filepath = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO + '?nid=' + onlineResourceId + '&redirect=true&format=' + RESOURCE_ONLINE_FORMAT + '&uid=' + OBJECT_USER_ID + '&auth=' + VIDEO_AUTH_ENABLED + courseparam + categoryparam + '&stream=' + STREAM_TYPE;
                if(VIDEO_AUTH_ENABLED == true) {
                    filepath = Platform.getAuthenticatedURL(filepath, 'GET', 'cloud');
                }
                filedata[0] = 'online';
                filedata[1] = filepath;

            }
            else
            {
                filedata[0] = 'error';
                if(resource.filetype && resource.filetype.toLowerCase() == 'pdf'){
                    filedata[1] = t("FILE_NOT_AVAILABLE",true);
                }else if((resource.source && resource.source == 'link') || USER_PRODUCT_TYPE == "online"){
                    filedata[1] = 'Internet connectivity is required to view this content';
                }else{
                    filedata[1] = t("NO_INTERNET_AND_FILE_NOT_AVAILABLE",true);
                }
            }
        }
        else if (resource.type == 'Document' && resource.source == "Upload") {
            var timestamp = new Date().getTime();
            if(resource.url.indexOf("?") > -1){
                var params = "&content=true&local=" + timestamp;
            }
            else{
                params = "?content=true&local=" + timestamp;
            }
            filedata[0] = 'offline';
            if(resource.filetype == 'html'){
                resourceurl = resource.url  + params;
                filedata[1] = Platform.getLocalServerPath() + "/" + resourceurl;
            }
            else if(resource.filetype.toLowerCase() == 'pdf'){
                resourceurl = filepath;
                filedata[1] = OBJECT_RESOURCE_FOLDER + "/" + resourceurl;
            }
        }
        else
        {
            if(fileAvailable.filestatus == false){
                if(fileAvailable.youtubeid != resource.youtube_id){
                    //play old video
                    filepath.replace(fileAvailable.youtubeid,resource.youtube_id);
                }
            }
            filedata[0] = 'offline';
            if(resource.filetype.toLowerCase() == 'pdf'){
                filedata[1] = OBJECT_RESOURCE_FOLDER + filepath;
            }else{
                //Replace format of download from settings
                var temp = filepath.split(".");
                var newString = temp[0].substring(0, temp[0].length-3);
                if(typeof ENABLE_DOWNLOAD_RESOLUTION_SWITCH != 'undefined' && ENABLE_DOWNLOAD_RESOLUTION_SWITCH == true){
                    newString = newString + fileAvailable.format;
                }else{
                    newString = newString + RESOURCE_FORMAT;
                }
                filepath = newString + "." + temp[1];
                if(typeof DOWNLOAD_VIDEO_TYPE != "undefined" && resource.type == "Video" && ((fileAvailable.filestatus == "Available") ||
                (fileAvailable.filestatus == "In Progress" && fileAvailable.version == "old") ) ){
                    filepath = filepath.substring(0, filepath.length-3);
                    filepath = filepath + fileAvailable.type;
                }
                filedata[1] = Platform.getLocalServerPath() + "/" + filepath;
            }
        }
        CONTROLLER.setVariable("resourcemode",filedata[0]);
        return filedata;
    }
    catch(e){
        console.log( "function : getResourcePath"
            + "\n message:" + e.message);
    }
};

MobileResourceService.prototype.getUnsubscribedResourcePath = function(resourceid,youtube_id)
{
    if (typeof UNSUBSCRIBED_RES_PATH != 'undefined') {
        var path = PORTAL_SERVER_DOMAIN + UNSUBSCRIBED_RES_PATH + "/public_video.php?nid=" + resourceid + "&format=" + UNSUBSCRIBED_VIDEO_FORMAT + "&uid=" + OBJECT_USER_ID + "&app=true"+'&youtube_id='+youtube_id;
    } else {
        var path = PORTAL_SERVER_DOMAIN + "/sites/all/includes/public_video.php?nid=" + resourceid + "&format=" + UNSUBSCRIBED_VIDEO_FORMAT + "&uid=" + OBJECT_USER_ID + "&app=true"+'&youtube_id='+youtube_id;
    }
    return path;
};

MobileResourceService.prototype.getResourcePosterPath = function(resourceid,thumbnail_id,thumbnail_filename,filetype)
{
    try{
        var fileAvailable = OBJECT_RESOURCE_FOLDER;
        var contentUtilsService = CONTROLLER.getService("ContentUtils");
        if(thumbnail_id == null || thumbnail_id == "null"){
            var filephypath = "video/" + resourceid + "/" + POSTER_IMAGE_NAME + ".jpg";
            var filepath = Platform.getLocalServerPath() + "/" + filephypath
        } else {
            filephypath = "video/" + resourceid + "/" + encodeURI(thumbnail_filename);
            filepath = Platform.getLocalServerPath() + "/" + filephypath;
        }
        var fileAvailable = Platform.isFilePresentInDocRoot(filephypath);
        if( fileAvailable == false)
        {
            contentUtilsService.downloadResourcePosterImage(resourceid, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC, thumbnail_id, thumbnail_filename, filetype);

            if(filetype.toLowerCase() == 'pdf'){
                filepath = "/platform/images/document.png";
            } else {
                if(BOOTSTRAP_IMAGES_SYNC == 'true' || (BOOTSTRAP_IMAGES_SYNC == 'false' && Platform.getNetworkState() == false)){
                    filepath = "/platform/images/noimageavailable.c3a74d2f.jpg";
                }
                else if(BOOTSTRAP_IMAGES_SYNC == 'false' && Platform.getNetworkState() == true)
                {
                    filepath = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + resourceid + '&preset=' + OBJECT_PRESET + '&uid=' + OBJECT_USER_ID;
                }
            }
        }
        return filepath;
    }
    catch(e){
        console.log( "function : getResourcePosterPath"
            + "\n message:" + e.message);
    }
};

MobileResourceService.prototype.downloadAllResources = function(){
   try{
        var resources = this.getObjectsOfType(OBJECT_VIDEO);
        for(var i=0;i<resources[OBJECT_VIDEO].length;i++){
           var filephypath = getResourceLocalPath(resources[OBJECT_VIDEO][i]);
           var resourcefileAvailable = Platform.isFilePresentInDocRoot(filephypath);
            if(resourcefileAvailable != true && filephypath != OBJECT_DATA_FOLDER && filephypath != OBJECT_RESOURCE_FOLDER){
                this.downloadResourcePosterImage(resources[OBJECT_VIDEO][i].nid, OBJECT_PRESET, BOOTSTRAP_IMAGES_SYNC, resources[OBJECT_VIDEO][i].thumbnail_id, resources[OBJECT_VIDEO][i].thumbnail_filename, resources[OBJECT_VIDEO][i].filetype);
                this.downloadResourceAsync(resources[OBJECT_VIDEO][i].nid,resources[OBJECT_VIDEO][i].youtube_id, resources[OBJECT_VIDEO][i].title + t(" downloaded successfully"));
            }
        }
    }
    catch(e){
        console.log( "function : downloadAllResources"
            + "\n message:" + e.message);
    }
};

MobileResourceService.prototype.getDownloadFileProgress = function(filepath){
    var status = Platform.getDownloadFileProgress(filepath);
    return status;
}

MobileResourceService.prototype.checkDownloadStatus = function(nid) {
    var resource = DataManager.getObjectProperty(OBJECT_VIDEO,nid,"content");
    var filephypath = getResourceLocalPath(resource);

    var status = Platform.getFileDownloadStatus(filephypath);


    if(status == 0 || status == 1){
        return 2; //Download in progress and part file exists
    }
    else if(status == 2)
    {
        return 1; //Download complete and file exists
    }
    else if(status == -1){
        var isAvail  = DataManager.isFileExist(filephypath);
        if(isAvail){
            return 1; //File exists already
        }else{
            return 0; //File is not available and not put for download
        }
    }
};

MobileResourceService.prototype.showDownloadMessage = function(msg) {
    try{

        Platform.showMessage(msg);
    }
    catch(e){
        console.log( "function : MobileResourceService showMessage"
            + "\n message:" + e.message);
    }
};
MobileResourceService.prototype.getVideoViewedCount = function(resourceid, callback)
{
    if(Platform.getNetworkState() == true){
        var url = OBJECT_SERVER_DOMAIN + '/api/videoview/count?nid=' + resourceid + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED;
        videoViewedCountCallBack = function(videoviewcount){
            try {
                var status = videoviewcount['status'];
                if (status == 200)
                {
                    var data = $.parseJSON(videoviewcount['data']);
                    return callback(data);
                }
                else if(status == 400){
                    return callback("error");
                }
            }
            catch (e)
            {
                var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/videoview/count?nid=' + resourceid + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED
                + "\n error_code :" + status
                + "\n response_data :" + data
                + "\n message :" + e.message;
                console.log(error);
                return callback("error");
            }
        };
        makeAsyncTaskCall("", "", url, "get", "false", "videoViewedCountCallBack");
    }
    else{
        return callback("error");
    }
};
MobileResourceService.prototype.getVideoDurationFromSeconds = function(resourceDuration){
    if(!resourceDuration){
        return "";
    }
    var sec_num = parseInt(resourceDuration, 10);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}

    if (hours > 0) {
        resourceDuration =hours + ':' + minutes + ':' + seconds;
    } else {
        resourceDuration = minutes + ':' + seconds;
    }
    return resourceDuration;
}

function downloadModifiedResource(pendingobjectdata){
    if((pendingobjectdata.operation == 'modified' || pendingobjectdata.operation == 'created') && pendingobjectdata.offline != '0'){
        DataManager.downloadSingleResourceMeta(pendingobjectdata.nid,pendingobjectdata.changed);
    } else if(pendingobjectdata.offline == '0' || pendingobjectdata.operation == 'delete'){
        DataManager.deleteResource(pendingobjectdata.nid);
        DataManager.removeObject(OBJECT_VIDEO,pendingobjectdata.nid);
        Platform.deleteVideoViewHistoryByType(pendingobjectdata.nid,"resource");
    }
}

MobileResourceService.prototype.getScormData = function(resourceid){
    try {
        var scormData = Platform.getObjectProperty('scorm_result', resourceid, "content");
        if(scormData !== "" && scormData !== null){
            scormData = $.parseJSON(scormData);
            return scormData;
        } else {
            scormData = {};
            return scormData;
        }

    } catch(e) {
        var error = "function : getScormData"
        + "\n message :" + e.message;
        console.log(error);
    }
}

MobileResourceService.prototype.setScormData = function(jsonData){
    try {
        var result_id = 'temp_scorm_' + jsonData.nid + '_' + new Date().getTime();
        Platform.saveObject('scorm_result', result_id, 'content', JSON.stringify(jsonData));
    } catch (e) {
        var error = "function : setScormData"
        + "\n message :" + e.message;
        console.log(error);
    };
}
MobileResourceService.prototype.getResourceInfoById = function(resourceid,save,type){
    if(!type) {
        type=OBJECT_VIDEO;
    }
    if (Platform.getNetworkState() == true) {
        var data = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'fromid?nid=' + resourceid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + "&version=" + API_VERSION);
        try {
            data = $.parseJSON(data);
            var status = data['status'];
            if (status == 200)
            {
                var data = $.parseJSON(data['data']);
                if(save && EXEC_MODE == "mobile"){
                    Platform.saveObject(type, resourceid, 'content', JSON.stringify(data[0]));
                }
                return data[0];
            }
            else {
                return "error";
            }
        }
        catch (e)
        {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_VIDEO + 'fromid?uid=' + OBJECT_USER_ID
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            console.log(error);
        }
        ;
    }
    else {
        return "error";
    }
};

MobileResourceService.prototype.getVideoSettings = function(){
    var url = OBJECT_SERVER_DOMAIN + '/api/get/videosettings' + '&auth=' + AUTH_ENABLED;
    try{
        if (Platform.getNetworkState() == true) {
            onGetVideoSettingsCallback = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200)
                {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetVideoSettingsCallback");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return onGetVideoSettingsCallback($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }

        }
    }
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get/videosettings'
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}

MobileResourceService.prototype.submitBreakQuestion = function(params){
    var url = OBJECT_SERVER_DOMAIN + '/api/submit/breakquestion?auth=' + AUTH_ENABLED;
    try{
        if (Platform.getNetworkState() == true) {
            var postdata = encodeURIComponent(JSON.stringify(params));
            var submitted = Platform.makeHttpPostCall(url, postdata);
            submitted = $.parseJSON(submitted);
            var status = submitted['status'];
            if (status == 200) {
                return true;
            }else{
                return false;
            }
        }else{
            return t("INTERNET_ERROR",true);
        }

    }
    catch(e){
        console.log(e.message);
    }
}
MobileResourceService.prototype.getVideoFeedback = function (callback) {
    var url = OBJECT_SERVER_DOMAIN + "/api/get/videofeedback?auth=" + AUTH_ENABLED + '&uid=' + OBJECT_USER_ID;
    try {
        if (Platform.getNetworkState() == true) {
            onGetVideoFeedbackCallback = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 200) {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                } else if (status == 404) {
                    result = [];
                } else {
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetVideoFeedbackCallback");
            } else {
                var data = Platform.makeHttpGetCall(url);
                return onGetVideoFeedbackCallback($.parseJSON(data));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }
    catch (e) {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get/videofeedback'
            + "\n message :" + e.message;
        console.log(error);
    }
};

MobileResourceService.prototype.videoFeedbackSave = function (feedbackjson, callback)
{
        var url = OBJECT_SERVER_DOMAIN + "/api/set/videofeedback?auth=" + AUTH_ENABLED + '&uid=' + OBJECT_USER_ID;
    try {
        if (Platform.getNetworkState() == true) {
            feedbackjson = $.parseJSON(feedbackjson);
            delete feedbackjson.id;
            delete feedbackjson.feedback_id;
            var postdata = encodeURIComponent(JSON.stringify(feedbackjson));
            onVideoFeedbackSaveCallback = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 200) {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                } else if (status == 404) {
                    result = [];
                } else {
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onVideoFeedbackSaveCallback");
            } else {
                var data = Platform.makeHttpPostCall(url, postdata);
                return onVideoFeedbackSaveCallback($.parseJSON(data));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
    catch (e) {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/set/videofeedback'
            + "\n message :" + e.message;
        console.log(error);
    }
};

MobileResourceService.prototype.addOrUpdateVideoViewHistory = function(data, callback,contenttype){
    try{
        var videoDetails = JSON.parse(data);
        if(typeof videoDetails['courseid'] == "undefined" || videoDetails['courseid'] == ""){
			if(EXEC_MODE == "mobile") {
            	var objectCourseId = $.parseJSON(Platform.getObjectFromRelations(OBJECT_CHAPTER, resourceMeta[OBJECT_CHAPTER][0], OBJECT_COURSE))[OBJECT_COURSE][0]["objectid"];
            	videoDetails['courseid'] = objectCourseId;
			} else {
	            var objectChapter = $.parseJSON(Platform.getObjectProperty("content", videoDetails.resourceid, "content"));
	            if(typeof objectChapter != 'undefined' && objectChapter != ''){
	                var objectCourseId = $.parseJSON( Platform.getObjectProperty(OBJECT_CHAPTER, objectChapter[OBJECT_CHAPTER][0], "content"));
	                videoDetails['courseid'] = objectCourseId['module'][0];
	            }
			}
        }
        if(typeof videoDetails != 'undefined' && videoDetails){
            if (EXEC_MODE == 'online') {
                Platform.addOrUpdateVideoViewHistory(JSON.stringify(videoDetails),"",contenttype);
            } else {
                Platform.addOrUpdateVideoViewHistory(JSON.stringify(videoDetails));
            }
            
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            return callback();
        }
    }catch(e){
        console.log('addOrUpdateVideoViewHistory failed' + e);
    };
};

MobileResourceService.prototype.updateAdaptiveVideoViewHistory = function(data, callback){
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/adaptive/videoviewhistory';
        if (Platform.getNetworkState() == true) {
            var postdata = encodeURIComponent(JSON.stringify(data));
            onUpdateAdaptiveVideoViewHistory = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 200) {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                } else if (status == 404) {
                    result = [];
                } else {
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onUpdateAdaptiveVideoViewHistory");
            } else {
                var data = Platform.makeHttpPostCall(url, postdata);
                return onUpdateAdaptiveVideoViewHistory($.parseJSON(data));
            }
        }else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }catch(e){
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/adaptive/videoviewhistory'
            + "\n message :" + e.message;
        console.log(error);
    }
}



MobileResourceService.prototype.getTopTenVideos = function(groupid, callback){
    var url = OBJECT_SERVER_DOMAIN + "/api/gettoptenvideos?group=" + groupid;
    try{
        if (Platform.getNetworkState() == true) {
            onGetTopTenVideosCallback = function(response){
                var result = '';
                var status = response['status'];
                if (status == 200){
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetTopTenVideosCallback");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return onGetTopTenVideosCallback($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }catch (e){
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get/gettoptenvideos'
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}

MobileResourceService.prototype.getObjectContentProperties = function(type, resourceid){
    try{
        if(CONTENT_PROPERTIES.hasOwnProperty(resourceid) == false){
            var data = Platform.getObjectProperty(type, resourceid, "content");
            CONTENT_PROPERTIES[resourceid] = data;
            return data;
        }else{
            return CONTENT_PROPERTIES[resourceid];
        }
    }catch(e){
        console.log("getObjectContentProperties " + e.message);
    }
};

MobileResourceService.prototype.setPdfAnnotation = function(annotation_data, nid, callback){
    try{
        if (EXEC_MODE == "online") {
            var url = OBJECT_SERVER_DOMAIN + '/api/set/pdfannotation?auth=' + AUTH_ENABLED + '&uid=' + OBJECT_USER_ID + '&nid=' + nid;
            if(Platform.getNetworkState() == true){
                var postdata = JSON.stringify(annotation_data);
                onSetPdfAnnotation = function (response) { 
                    var status = response['status']; 
                    console.log(response); 
                    if (status == 200) { 
                        if (typeof callback != 'undefined' && typeof callback == 'function') { 
                            return callback(response); 
                        } else { 
                            return response; 
                        }
                    } 
                }; 
                if (typeof callback != 'undefined' && typeof callback == 'function') { 
                    Platform.makeAjaxPostAsyncCall(url, postdata, "onSetPdfAnnotation"); 
                }else{ 
                    var ignorecontent = true;
                    var result = Platform.makeHttpPostCall(url, postdata, '','',ignorecontent); 
                    return onSetPdfAnnotation($.parseJSON(result)); 
                }
            }else{
                alert(INTERNET_ERROR);
            }
        } else {
            Platform.saveObject("pdfannotation", nid, 'content', JSON.stringify(annotation_data));
        }
        
    }catch(e){
        console.log("setPdfAnnotation " + e.message);
    }
}

MobileResourceService.prototype.getPdfAnnotation = function (nid, callback) { 
    try{ 
        if (EXEC_MODE == "online") {
            var url = OBJECT_SERVER_DOMAIN + "/api/get/pdfAnnotation?nid="+nid + '&auth=' + AUTH_ENABLED + '&uid=' + OBJECT_USER_ID; 
            if (Platform.getNetworkState() == true) { 
                onGetPdfAnnotation = function (annotate_data) { 
                    var result = ''; 
                    var status = annotate_data['status']; 
                    if (status == 200) { 
                        var annotate_data = $.parseJSON(annotate_data["data"]); 
                        result = annotate_data; 
                    } else if (status == 401 || status == 403) { 
                        result = status; 
                    } else { 
                        result = t("ERROR_MESSAGE",true);
                    } 
                    if (typeof callback != 'undefined' && typeof callback == 'function') { 
                        return callback(result); 
                    } else { 
                        return result; 
                    } 
                } 
                if (typeof callback != 'undefined' && typeof callback == 'function') { 
                    makeAsyncTaskCall("", '', url, "get", "false", "onGetPdfAnnotation"); 
                } else { 
                    var annotate_data = Platform.makeHttpGetCall(url); 
                    return onGetPdfAnnotation($.parseJSON(annotate_data)); 
                } 
            }            
        }  else {
            var pdfData = Platform.getObjectProperty("pdfannotation", nid, 'content');
            pdfData = $.parseJSON(pdfData);
            return pdfData
        }
         
    }catch(e){ 
        console.log("function : getPdfAnnotation" + "\n message:" + e.message); 
    } 
}

MobileResourceService.prototype.getObjectContentProperties = function(type, resourceid){
    try{
        if(CONTENT_PROPERTIES.hasOwnProperty(resourceid) == false){
            var data = Platform.getObjectProperty(type, resourceid, "content");
            CONTENT_PROPERTIES[resourceid] = data;
            return data;
        }else{
            return CONTENT_PROPERTIES[resourceid];
        }
    }catch(e){
        console.log("getObjectContentProperties " + e.message);
    }
};

MobileResourceService.prototype.setUserRolePlayVideos = function(Data, callback){
    try{
        if(Platform.getNetworkState() == true){
            var url = OBJECT_SERVER_DOMAIN + '/api/set/userroleplay';
            var postdata = JSON.stringify(Data);
            onSetUserRolePlayVideos = function (response) { 
                var status = response['status']; 
                if (status == 200) { 
                    if (typeof callback != 'undefined' && typeof callback == 'function') { 
                        return callback(response); 
                    } else { 
                        return response; 
                    }
                } 
            }; 
            if (typeof callback != 'undefined' && typeof callback == 'function') { 
                Platform.makeAjaxPostAsyncCall(url, postdata, "onSetUserRolePlayVideos"); 
            }else{ 
                var data = Platform.makeHttpPostCall(url, postdata);
                return onSetUserRolePlayVideos($.parseJSON(data));
            }
        }else{
            alert(INTERNET_ERROR);
        }
    }catch(e){
        console.log("function : setUserRolePlayVideos" + "\n message:" + e.message); 
    }
}

MobileResourceService.prototype.downloadAllQuizBreaks = function(data){
    try{
        data = this.getBreaksDataForResource(data.nid);
        var values = [];
        var quizNids = [];
        var questionNids = [];
        var quizService = CONTROLLER.getService("Quiz");
        var keys =Object.keys(data);
        for(var i=0; i< keys.length; i++){
            values.push(data[keys[i]])
        }
        for(var j=0; j< values.length; j++){
            if(values[j].type == 'quiz' ){
                quizNids.push(values[j].breakContentId);
            }
        }
        for(var k=0; k< quizNids.length; k++){
            var quizdata = quizService.getQuiz(quizNids[k]);
            this.calldownloadQuizBreak(quizdata);
        }
    }catch(e){
        console.log('downloadAllQuizBreaks ' + e);
    }
}

MobileResourceService.prototype.calldownloadQuizBreak = function (quiz) {
    try{
        var nid = quiz.nid;
        var downloadurl = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_QUIZ + '?quizid=' + nid + "&auth=" + AUTH_ENABLED;
        var filepath = "video/" + nid + "/" + nid + '_' + quiz.changed + ".zip";
        var paramsJson = {'objectid': nid, 'objecttype': 'resource',
                'serverurl': downloadurl, 'localurl': filepath, 'message': '',
                'sync': FILE_DOWNLOAD_ASYNC, 'expiryduration': "",
                'onComplete': "", 'youtubeid': quiz.changed, 'format': "", 'type': 'content'};
        Platform.downloadFile(JSON.stringify(paramsJson));
    }catch(e){
        console.log('calldownloadQuizBreak ' + e);
    }
}
function MobileCategoryService() {};

MobileCategoryService.prototype.downloadSingleCategory = function(catid,changed){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CATEGORY + 'fromid?nid=' + catid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&changed=' + changed + "&version=" + API_VERSION, OBJECT_CATEGORY+"-"+catid);
    }
    catch(e){
        console.log("function : downloadSingleCategory"
            + " message" + e.message);
    }
};

MobileCategoryService.prototype.downloadAllCategoriesAsync = function(){
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET;
        Platform.downloadForm(url, OBJECT_CATEGORY);
    }
    catch(e){
        console.log("function : downloadAllCategoriesAsync"
            + " message" + e.message);
    }
};

MobileCategoryService.prototype.downloadPendingCategories = function(pendingobjectdata, pendingdata){
    try{
        for(var i=0;i<pendingobjectdata.length;i++){
            try{
                var category = DataManager.getObjectProperty(OBJECT_CATEGORY,pendingobjectdata[i].nid,"content");
                if(!$.isEmptyObject(category)){
                    if(category.thumbnail_id != pendingobjectdata[i].thumbnail_id){
                        DataManager.deleteMetaImage(OBJECT_CATEGORY, category.nid);
                    }
                }
                if(!$.isEmptyObject(pendingobjectdata[i])){
                    Platform.removeObject(OBJECT_CATEGORY,pendingobjectdata[i].nid);
                    Platform.saveObject(OBJECT_CATEGORY, pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
                    if(typeof OBJECT_GROUP != "undefined" && OBJECT_GROUP != ""){
                        addManyToOneRelation(OBJECT_GROUP, pendingobjectdata[i][OBJECT_GROUP], OBJECT_CATEGORY , pendingobjectdata[i].nid);
                    }
                    DataManager.downloadImage(pendingobjectdata[i].nid, pendingobjectdata[i].thumbnail_filename, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC);
                }
            }
            catch(e){
                var error = "FOR LOOP : method - downloadPendingCategories"
                + "\n data = " + JSON.stringify(pendingobjectdata[i])
                + "\n message:" + e.message
                console.log(error);
            }

        }
        Platform.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        console.log( "function : downloadPendingCategories"
            + "\n message:" + e.message);
    }
}

MobileCategoryService.prototype.getAllCategories = function()
{
    try{
        var categories = Platform.getObjectsOfType(OBJECT_CATEGORY);
        return $.parseJSON(categories);
    }
    catch(e){
        console.log( "function : getAllCategories"
            + "\n message:" + e.message);
    }

};

MobileCategoryService.prototype.getUserSubscribedCategories = function(groupid)
{
    try{
        if (groupid) {
            groupid = JSON.stringify([groupid]);
        } else {
            if(typeof HIDE_GROUPS_FILTER != 'undefined' && HIDE_GROUPS_FILTER == 'true'){
                if(typeof OBJECT_USER_GROUPS != 'undefined'){
                    groupid = OBJECT_USER_GROUPS.toString();
                }else{
                    groupid = "";
                }
            }else if(typeof HIDE_GROUPS_FILTER != 'undefined' && HIDE_GROUPS_FILTER == 'false'){
                if(typeof CONTROLLER.getVariable('activegroupid') != 'undefined'){
                    groupid = CONTROLLER.getVariable('activegroupid');
                }else{
                    groupid = "";
                }
            }else{
                groupid = "";
            }
        }
        // send empty string for groupid to get all the subscribed categories instead of sending all the groups
        if(typeof HIDE_GROUPS_FILTER != 'undefined' && HIDE_GROUPS_FILTER == 'true' 
            && typeof SEND_GROUPS_TO_GET_SUBSCRIBED_CATEGORIES != 'undefined' && !SEND_GROUPS_TO_GET_SUBSCRIBED_CATEGORIES){
            var categories = Platform.getAllUserSubscribedCategory('', '', OBJECT_USER_ID);
        }else{
            var categories = Platform.getAllUserSubscribedCategory('', groupid, OBJECT_USER_ID);
        }
        categories = $.parseJSON(categories);
        var categories_listing_mode = CONTROLLER.getVariable('categories_listing_mode');
        var filter_categories = [];
        for(var i = 0; i < categories.length; i++){
            if(typeof categories_listing_mode == "undefined" || (categories_listing_mode != '' && categories_listing_mode == 'AllSubjects')){
                if(categories[i].tags_details && (JSON.stringify(categories[i].tags_details).indexOf('exclude_allcourses') > -1 || JSON.stringify(categories[i].tags_details).indexOf('Test Series') > -1 || JSON.stringify(categories[i].tags_details).indexOf('NEET PG PRACTICE') > -1)){
                    continue;
                }else{
                    filter_categories.push(categories[i]);
                }
            }else if(categories_listing_mode != '' && categories_listing_mode == "TestSeries"){
                if(JSON.stringify(categories[i].tags_details).indexOf('Test Series') > -1){
                    filter_categories.push(categories[i]);
                }else{
                    continue;
                }
            }else if(categories_listing_mode != '' && categories_listing_mode == "Practice"){
                if(categories[i].tags_details && JSON.stringify(categories[i].tags_details).indexOf('NEET PG PRACTICE') > -1){
                    filter_categories.push(categories[i]);
                }else{
                    continue;
                }
            }else{
                filter_categories.push(categories[i]);
            }
        }
        return filter_categories;
    }
    catch(e){
        console.log( "function : getAllCategories"
            + "\n message:" + e.message);
    }
};

MobileCategoryService.prototype.getCategoriesForGroup = function(groupid)
{
    if(CATEGORIES_FOR_GROUPS[groupid]){
        return $.extend(true,{},CATEGORIES_FOR_GROUPS[groupid]);
    }
    if(EXEC_MODE == "test"){
        if(typeof BUILD_VERSION != "undefined" && BUILD_VERSION != ""){
            var version = '&version=' + BUILD_VERSION;
        }
        else{
            version = '';
        }
        if(typeof limit == "undefined" && typeof page == "undefined"){
            var limit = "";
            var page = "";
        }
        var theUrl = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?groups='+groupid;
        var response =  Platform.makeHttpGetCall(theUrl);
        if(response == ''){
            var data = '';
        }else{
            if(OBJECT_CLIENT_NAME == "ekisaan" || OBJECT_CLIENT_NAME == "pearson"){
                response = '{"'+OBJECT_CATEGORY+'":'+response+'}';
            }
            data = $.parseJSON(response);
        }
        return data;
    }
    else{
        var categories = Platform.getRelatedObjects(OBJECT_GROUP, groupid, OBJECT_CATEGORY);
        categories = $.parseJSON(categories);
        categories[OBJECT_CATEGORY].forEach(function (category, i) {
            if (getJSONByKey(CATEGORY_DETAILS, "nid", category["nid"]) == false) {
                CATEGORY_DETAILS.push(category);
            }
        });
        CATEGORIES_FOR_GROUPS[groupid] = $.extend(true,{},categories);
        return categories;
    }
};

MobileCategoryService.prototype.getCategoryFromid = function(catid)
{
    if(getJSONByKey(CATEGORY_DETAILS,"nid",catid) != false){
        return getJSONByKey(CATEGORY_DETAILS,"nid",catid)[0];
    }
    else{
        var category = Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content");
        return $.parseJSON(category);
    }
};

MobileCategoryService.prototype.getCoursesForCategory = function(catid,groupid,callback)
{
    try{
        if (EXEC_MODE == 'mobile') {
            if (typeof CHECK_SUBSCRIBED_COURSES != "undefined" && CHECK_SUBSCRIBED_COURSES == 'true' && typeof groupid != "undefined" && groupid != "") {
                var courses = [];
                courses[OBJECT_COURSE] = $.parseJSON(Platform.getAllUserSubscribedTopic(OBJECT_USER_ID, groupid, catid));
                courses = this.getUniqueCoursesforCategory(courses);
                return courses;
            }
        }
        if (typeof groupid != "undefined" && groupid != "") {
            var groups = "";
            var filter = "";
            if (HIDE_GROUPS_FILTER == "true" && EXEC_MODE == "online") {
                for (var i = 0; i < OBJECT_USER_GROUPS_JSON.length; i++) {
                    if (OBJECT_USER_GROUPS.indexOf(OBJECT_USER_GROUPS_JSON[i].nid) > -1) {
                        if (groups == "") {
                            groups = OBJECT_USER_GROUPS_JSON[i].title;
                        } else {
                            groups += "," + OBJECT_USER_GROUPS_JSON[i].title;
                        }
                    }
                }
                groups = encodeURIComponent(groups);
                filter = '[{"object_type":"groups","object_id":"' + groups + '","relation_object_type":"' + OBJECT_COURSE + '"}]';
            } else {
                groups = groupid;
                var filter = '[{"object_type":"group","object_id":"' + groups + '","relation_object_type":"' + OBJECT_COURSE + '"}]';
            }
            if (EXEC_MODE == "online") {
                if (typeof callback == "function") {
                    Platform.getRelatedObjectsFilter(OBJECT_CATEGORY, catid, OBJECT_COURSE, filter, function (courses) {
                        courses = $.parseJSON(courses);
                        return callback(courses);
                    });
                    return;
                } else {
                    var courses = Platform.getRelatedObjectsFilter(OBJECT_CATEGORY, catid, OBJECT_COURSE, filter);
                }
            } else {
                var courses = Platform.getRelatedObjectsFilterByGroup(groups,OBJECT_CATEGORY, catid, OBJECT_COURSE);
            }
        }else{
            var courses = Platform.getRelatedObjects(OBJECT_CATEGORY, catid, OBJECT_COURSE)
        }
        courses = $.parseJSON(courses);
        courses = this.getUniqueCoursesforCategory(courses);
        return courses;
    }
    catch(e){

    }
};

//if courses are tagged to the same category multiple times
MobileCategoryService.prototype.getUniqueCoursesforCategory = function (courses){
    var courses_for_category = [], courses_for_category_nid = [];
    if (courses) {
        for (var j = 0; j < courses[OBJECT_COURSE].length; j++) {
            if ($.inArray(courses[OBJECT_COURSE][j].nid, courses_for_category_nid) == -1) {
                courses_for_category_nid.push(courses[OBJECT_COURSE][j].nid);
                courses_for_category.push(courses[OBJECT_COURSE][j]);
            }
        }
    }
    courses[OBJECT_COURSE] = courses_for_category;
   
    return courses;
}
MobileCategoryService.prototype.getNotesForCategory = function(catid,groupid)
{
    try {
        if(EXEC_MODE == 'online'){
            var notes = this.getNotesofUserByCategory(catid, groupid);
            return notes;
        } else if(typeof NOTES_OLD != "undefined" && NOTES_OLD == true){
            if (typeof groupid != "undefined" && groupid != "") {
                var filter = '[{"object_type":"group","object_id":' + groupid + ',"relation_object_type":' + OBJECT_NOTES + '}]';
                var notes = Platform.getRelatedObjectsFilterByGroup(groupid,OBJECT_CATEGORY, catid, OBJECT_NOTES);
                notes = $.parseJSON(notes);
                return notes;
            }
            else
            {
                var courses = Platform.getRelatedObjects(OBJECT_CATEGORY, catid, OBJECT_COURSE)
                courses = $.parseJSON(courses);
                return courses;
            }
        } else {
        var courseService = CONTROLLER.getService("Course");
        var chapterService = CONTROLLER.getService("Chapter");
            if (typeof catid != 'undefined' && catid != '' && ( (typeof GROUP_SUBSCRIPTION != "undefined" && GROUP_SUBSCRIPTION != "true" ) || typeof GROUP_SUBSCRIPTION == "undefined")) {
                var courses = this.getCoursesForCategory(catid, groupid);
            } else {
                var group_service =  CONTROLLER.getService("Group");
                var groups = OBJECT_USER_DATA.groups;
                var courses = group_service.getCoursesForUserGroups(groups);
            }
        var chapters = "";
        var notesTemp = "";
        var videos = "";
        var category_notes = [];
        var new_notes_list = [];
        new_notes_list[OBJECT_COURSE] = [];
        new_notes_list['courseToRender'] = [];
        category_notes[OBJECT_NOTES] = [];
        var groupid = CONTROLLER.getVariable("activegroupid");
            if (EXEC_MODE != "online") {
                for (a in courses[OBJECT_COURSE]) {
                    chapters = courseService.getChaptersForCourse(courses[OBJECT_COURSE][a].nid, groupid);
                    for (j in chapters[OBJECT_CHAPTER]) {
	                    if (typeof CHECK_CONTENT_BELONGS_TO_USERGROUPS != "undefined" && CHECK_CONTENT_BELONGS_TO_USERGROUPS == "true") {
	                        var videos = chapterService.getResourcesForChapterByGroups(chapters[OBJECT_CHAPTER][j].nid);
	                    } else {
	                        videos = chapterService.getResourcesForChapter(chapters[OBJECT_CHAPTER][j].nid);
	                    }
	                	for (k in videos[OBJECT_VIDEO]) {
	                    notesTemp = Platform.getAllNotesByUserID(OBJECT_USER_ID, videos[OBJECT_VIDEO][k].nid);
	                    try {
	                        notesTemp = $.parseJSON(notesTemp);
	                        if (!$.isEmptyObject(notesTemp[OBJECT_NOTES])) {
	                            for (m in notesTemp[OBJECT_NOTES]) {
	                                try {
	                                    notesTemp[OBJECT_NOTES][m].chapterid = chapters[OBJECT_CHAPTER][j].nid;
	                                    notesTemp[OBJECT_NOTES][m].courseid = courses[OBJECT_COURSE][a].nid;
	                                        if (typeof catid != 'undefined' && catid != '') {
	                                            notesTemp[OBJECT_NOTES][m].catid = catid;
	                                        } else {
	                                            notesTemp[OBJECT_NOTES][m].catid = courses[OBJECT_COURSE][a][OBJECT_CATEGORY][0];
	                                        }
	                                    notesTemp[OBJECT_NOTES][m].groupid = groupid;
	                                    notesTemp[OBJECT_NOTES][m].note = notesTemp[OBJECT_NOTES][m].note.replace(/^\s+|\s+$/g, '').replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
	                                    notesTemp[OBJECT_NOTES][m].originalDuration = notesTemp[OBJECT_NOTES][m].duration;
	                                    notesTemp[OBJECT_NOTES][m].duration = getVideoDurationFromSeconds(parseInt(notesTemp[OBJECT_NOTES][m].duration, 10)) + "m";
	                                    category_notes[OBJECT_NOTES].push(notesTemp[OBJECT_NOTES][m]);
	                                } catch (e) {
	                                    console.log("notes loop " + e.message);
	                                }
	                            }
	                        }
	                    } catch (e) {
	                        console.log("get notes " + e.message);
	                    }
	                }
	            }
	        }
        } else {
            category_notes = this.getNotesofUserByCategory(catid);
            for (var i = 0; i < category_notes[OBJECT_NOTES].length; i++) {
                category_notes[OBJECT_NOTES][i]['catid'] = catid;
                category_notes[OBJECT_NOTES][i]['groupid'] = groupid;
                category_notes[OBJECT_NOTES][i].originalDuration = category_notes[OBJECT_NOTES][i].duration;
                category_notes[OBJECT_NOTES][i].note = category_notes[OBJECT_NOTES][i].note.replace(/^\n+|\n+$/g, '').replace(/^\t+|\t+$/g, '').replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
                category_notes[OBJECT_NOTES][i].duration = getVideoDurationFromSeconds(parseInt(category_notes[OBJECT_NOTES][i].duration, 10)) + "m";
                var content = Platform.getObjectProperty(OBJECT_VIDEO, category_notes[OBJECT_NOTES][i]['resourceid'], "content");
                content = $.parseJSON(content);
                var chapterid = content[OBJECT_CHAPTER][0];
                category_notes[OBJECT_NOTES][i]['chapterid'] = chapterid;
                var chaptercontent = Platform.getObjectProperty(OBJECT_CHAPTER, chapterid, "content");
                chaptercontent = $.parseJSON(chaptercontent);
                category_notes[OBJECT_NOTES][i]['courseid'] = chaptercontent[OBJECT_COURSE][0];
            }
        }
        if(category_notes[OBJECT_NOTES].length > 0){
            for (l = 0; l < courses[OBJECT_COURSE].length; l++) {
                new_notes_list[OBJECT_COURSE][l] = [];
                new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER] = [];
                new_notes_list['courseToRender'][l] = new_notes_list[OBJECT_COURSE][l];
                new_notes_list['courseToRender'][l]['chapterToRender'] = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER];
            var chapters = courseService.getChaptersForCourse(courses[OBJECT_COURSE][l].nid);
            for (i = 0; i < chapters[CLIENT_OBJECT_CHAPTER].length; i++) {
                new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i] = {};
                new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i].title = chapters[OBJECT_CHAPTER][i].title;
                new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO] = [];
                var chapterid = chapters[OBJECT_CHAPTER][i].nid;
                 if (typeof CHECK_CONTENT_BELONGS_TO_USERGROUPS != "undefined" && CHECK_CONTENT_BELONGS_TO_USERGROUPS == "true") {
                       var resources = chapterService.getResourcesForChapterByGroups(chapterid);
                    } else {
                       var resources = chapterService.getResourcesForChapter(chapterid);
                    }
                for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = {};
                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k].title = resources[OBJECT_VIDEO][k].title;
                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES] = [];
                }
            }

            for (i = 0; i < chapters[CLIENT_OBJECT_CHAPTER].length; i++) {
                for (var j = 0; j < category_notes[OBJECT_NOTES].length; j++) {
                    if (chapters[OBJECT_CHAPTER][i].nid == category_notes[OBJECT_NOTES][j].chapterid) {
                        var chapterid = chapters[OBJECT_CHAPTER][i].nid;
                         if (typeof CHECK_CONTENT_BELONGS_TO_USERGROUPS != "undefined" && CHECK_CONTENT_BELONGS_TO_USERGROUPS == "true") {
                            resources = chapterService.getResourcesForChapterByGroups(chapterid);
                         } else {
                            resources = chapterService.getResourcesForChapter(chapterid);
                         }
                        for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                            if (resources[OBJECT_VIDEO][k].nid == category_notes[OBJECT_NOTES][j].resourceid) {
                                new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].push(category_notes[OBJECT_NOTES][j]);
                            }
                        }
                    }
                }
            }
        }
        for (l = courses[OBJECT_COURSE].length - 1; l >= 0; l--) {
            topicsnotescount = 0;
                var chapters = courseService.getChaptersForCourse(courses[OBJECT_COURSE][l].nid, groupid);
                for (i = chapters[CLIENT_OBJECT_CHAPTER].length - 1; i >= 0; i--) {
                      notescount = 0;
                    for (var k = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO].length - 1; k >= 0; k--) {
                        notescount = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO].length;
                        count = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length;
                        if (new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length == 0) {
                            new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = "";
                            new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO].splice(k, 1);
                            notescount = notescount - 1;
                        }
                    }
                    topicsnotescount = topicsnotescount + notescount;
                    if (notescount == 0) {
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i] = "";
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER].splice(i, 1);
                    }
                }
                if (topicsnotescount == 0) {
                    new_notes_list[OBJECT_COURSE][l] = "";
                    new_notes_list[OBJECT_COURSE].splice(l, 1);
                }
            }

        }


        return new_notes_list;
        }

    } catch (e) {
        console.log("function :- getNotesByCategory message :- " + e.message);
    }
};

MobileCategoryService.prototype.getResourcesCount = function(catid,courseid,groupid,filetype,contenttype, callback){
    try{
        if(courseid){
             var filter = '[{"object_type":"'+OBJECT_COURSE+'","object_id":"'+courseid+'"},{"object_type":"'+OBJECT_CHAPTER+'"},{"object_type":"'+OBJECT_VIDEO+'"}]';
        }else{
             var filter = '[{"object_type":"'+OBJECT_COURSE+'"},{"object_type":"'+OBJECT_CHAPTER+'"},{"object_type":"'+OBJECT_VIDEO+'"}]';
        }
        if ((filetype) && (contenttype)) {
            var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
        } else {
            var ignore_params = "";
        }
        if (EXEC_MODE == "online") {
            return Platform.getRelationObjectCount(groupid,OBJECT_CATEGORY,catid,filter,ignore_params, "", callback);
        } else {
            var resourcescount = Platform.getRelationObjectCount(groupid,OBJECT_CATEGORY,catid,filter,ignore_params,true);
            return resourcescount;
        }
    }
    catch(e){

    }
}
MobileCategoryService.prototype.getVideosCount = function (catid, courseid, groupid) {
    try {
        var filter = '[{"object_type":"' + OBJECT_COURSE + '"},{"object_type":"' + OBJECT_CHAPTER + '"},{"object_type":"' + OBJECT_VIDEO + '"}]';
        var resourcescount = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid, filter);
        return resourcescount;
    }
    catch (e) {
        console.log("function : getVideosCount"
                + "\n message:" + e.message);
    }
}
MobileCategoryService.prototype.getEbookCount = function (catid, courseid, groupid) {
    try {
        var filter = '[{"object_type":"' + OBJECT_COURSE + '"},{"object_type":"' + OBJECT_BOOK + '"}]';
        var resourcescount = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid, filter);
        return resourcescount;
    }
    catch (e) {
        console.log("function : getEbookCount"
                + "\n message:" + e.message);
    }
}
MobileCategoryService.prototype.getQuizzesCount = function (catid, courseid, groupid) {
    try {
        var filter = '[{"object_type":"' + OBJECT_COURSE + '"},{"object_type":"' + OBJECT_QUIZ + '"}]';
        var resourcescount = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid, filter);
        return resourcescount;
    }
    catch (e) {
          console.log("function : getQuizzesCount"
                + "\n message:" + e.message);
    }
}
MobileCategoryService.prototype.getSupportDocsCount = function(catid, courseid, groupid, filetype, contenttype) {
    try {
            if (typeof GET_VVH_BY_USER_GROUPS == "undefined") {
                groupid = "";
            }
            if ((filetype) && (contenttype)) {
              var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
            } else {
              var ignore_params = "";
            }
            var filter = '[{"object_type":' + OBJECT_COURSE + ',"object_id":' + courseid + '},{"object_type":' + OBJECT_CHAPTER + '},{"object_type":' + OBJECT_SUPPORT_DOCS + '}]';
			if(EXEC_MODE == 'online'){
				var supportdocscount = Platform.getRelationObjectCount(groupid,OBJECT_CATEGORY,catid,filter,courseid,OBJECT_SUPPORT_DOCS);
			} else {
                if (COURSE_DETAILS.hasOwnProperty(courseid) == false) {
				    var supportdocscount = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid, filter, ignore_params);
                }else{
                    var coursedetails = COURSE_DETAILS[courseid];
                    var supportdocscount = 0;
                    for (var i = 0; i < coursedetails[OBJECT_CHAPTER].length; i++) {
                        supportdocscount = supportdocscount + coursedetails[OBJECT_CHAPTER][i]["support_docs"].length;
                    }
                }
			}
            return supportdocscount;
    }
    catch (e) {

    }
}
MobileCategoryService.prototype.getSupportdocsCount = function(catid,courseid,groupid,filetype,contenttype){
    try{
        if(courseid){
             var filter = '[{"object_type":"'+OBJECT_COURSE+'","object_id":"'+courseid+'"},{"object_type":"'+OBJECT_CHAPTER+'"},{"object_type":"'+OBJECT_SUPPORT_DOCS+'"}]';
        }else{
             var filter = '[{"object_type":"'+OBJECT_COURSE+'"},{"object_type":"'+OBJECT_CHAPTER+'"},{"object_type":"'+OBJECT_SUPPORT_DOCS+'"}]';
        }
        if ((filetype) && (contenttype)) {
            var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
        } else {
            var ignore_params = "";
        }
        var resourcescount = Platform.getRelationObjectCount(groupid,OBJECT_CATEGORY,catid,filter,ignore_params);
        return resourcescount;
    }
    catch(e){
        console.log(e.message);
    }
}
MobileCategoryService.prototype.getEbooksCount = function(catid,courseid,groupid,filetype,contenttype){
    try{
        if(courseid){
             var filter = '[{"object_type":"'+OBJECT_COURSE+'","object_id":"'+courseid+'"},{"object_type":"'+OBJECT_BOOK+'"}]';
        }else{
             var filter = '[{"object_type":"'+OBJECT_COURSE+'"},{"object_type":"'+OBJECT_BOOK+'"}]';
        }
        if ((filetype) && (contenttype)) {
            var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
        } else {
            var ignore_params = "";
        }
        var resourcescount = Platform.getRelationObjectCount(groupid,OBJECT_CATEGORY,catid,filter,ignore_params);
        return resourcescount;
    }
    catch(e){
        console.log(e.message);
    }
}

MobileCategoryService.prototype.downloadModifiedCategory = function(pendingobjectdata){
    if((pendingobjectdata.operation == 'modified' || pendingobjectdata.operation == 'created') ){
        DataManager.downloadSingleCategory(pendingobjectdata.nid,pendingobjectdata.changed);
    } else if(pendingobjectdata.operation == 'delete'){
        DataManager.removeObject(OBJECT_CATEGORY,pendingobjectdata.nid);
        Platform.deleteVideoViewHistoryByType(pendingobjectdata.nid,"subject");
    }
}

MobileCategoryService.prototype.getTitle = function (param) {
    if (typeof param == "object") {
        var catid = param.catid;
    } else {
        var catid = param;
    }
	if(EXEC_MODE == 'online'){
        var category = this.getCategoryFromid(catid);
        if(Array.isArray(category)){
            return category[0].display_name;
        }else{
            return category.display_name;
        }
	} else {
		var category = Platform.getObjectProperty(CLIENT_OBJECT_CATEGORY, catid, "content");
	    category = $.parseJSON(category);
	    return category.display_name;
	}
}

MobileCategoryService.prototype.getCompletedVideosOfSubjectCount = function (groupid, catid, filetype, contenttype, callback) {
    if ((filetype) && (contenttype)) {
        var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
    } else {
        var ignore_params = "";
    }
    if (EXEC_MODE == "online") {
        return Platform.getVideoViewHistoryCompletedCountByType(OBJECT_USER_ID, groupid, catid, 'subject', ignore_params, callback);
    }
    var count = Platform.getVideoViewHistoryCompletedCountByType(OBJECT_USER_ID,groupid,catid,'subject',ignore_params,true);
    return count;
}

MobileCategoryService.prototype.downloadAllResourcesOfCategory = function(catid)
{
    var resources = "";
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var chapters = this.getChaptersForCourse(courseid);
    var resourceStatus = "";
    CONTROLLER.setVariable("courseleveldownload","true");
    var all_quizzes = this.getQuizForCourse(courseid);
    if (!$.isEmptyObject(all_quizzes[OBJECT_QUIZ])) {
        for (var i in all_quizzes[OBJECT_QUIZ]) {
            var status = contentUtils.checkResourceDownloadStatus(all_quizzes[OBJECT_QUIZ][i]['nid'], OBJECT_QUIZ, courseid, all_quizzes[OBJECT_QUIZ][i]['changed']);
            if (status == 0) {
                contentUtils.fileDownload(all_quizzes[OBJECT_QUIZ][i], "", "", all_quizzes[OBJECT_QUIZ][i]['title'] + t(" downloaded successfully"), courseid, OBJECT_QUIZ, CONTROLLER.getVariable("activecatid"));
            }
        }
    }
    for(i in chapters[OBJECT_CHAPTER]){
        resources = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_VIDEO));
        var supportdocs = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_SUPPORT_DOCS));
        if (!$.isEmptyObject(supportdocs)) {
            for (var i in supportdocs[OBJECT_SUPPORT_DOCS]) {
                var status = contentUtils.checkResourceDownloadStatus(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid'], OBJECT_SUPPORT_DOCS, courseid);
                if (status == 0) {
                    if (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] != RESOURCE_TYPE_LINK || (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] == RESOURCE_TYPE_LINK && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['type'] == RESOURCE_TYPE_HTML && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                        contentUtils.fileDownload(supportdocs[OBJECT_SUPPORT_DOCS][i], DOCUMENT_FORMAT, "", supportdocs[OBJECT_SUPPORT_DOCS][i]['title'] + t(" downloaded successfully"), courseid, OBJECT_SUPPORT_DOCS, CONTROLLER.getVariable("activecatid"));
                    }
                }
            }
        }
        for (j in resources[OBJECT_VIDEO]) {
            var content = Platform.getObjectProperty(OBJECT_VIDEO, resources[OBJECT_VIDEO][j]["nid"], "content");
            content = $.parseJSON(content);
            if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                var quiz = quizService.getQuiz(content.url);
                if (typeof quiz != "undefined" ) {
                    resourceStatus = contentUtils.checkResourceDownloadStatus(quiz["nid"], OBJECT_QUIZ, courseid, quiz['changed']);
                }else{
                    continue;
                }
            }else{
                resourceStatus = contentUtils.checkResourceDownloadStatus(resources[OBJECT_VIDEO][j]["nid"], OBJECT_VIDEO, courseid);
            }
            if(resourceStatus == 0){
                if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                if(typeof quiz != "undefined" ){
                        quiz['contentid'] = resources[OBJECT_VIDEO][j]["nid"];
                        contentUtils.fileDownload(quiz, "", "", resources[OBJECT_VIDEO][j]['title'] + t(" downloaded successfully"), courseid, OBJECT_QUIZ,CONTROLLER.getVariable("activecatid"));
                }
            }else{
                if (resources[OBJECT_VIDEO][j]['source'] != RESOURCE_TYPE_LINK || (resources[OBJECT_VIDEO][j]['source'] == RESOURCE_TYPE_LINK && ((resources[OBJECT_VIDEO][j]['type'] == RESOURCE_TYPE_HTML && ((resources[OBJECT_VIDEO][j]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {

                    contentUtils.fileDownload(resources[OBJECT_VIDEO][j], RESOURCE_FORMAT, "", resources[OBJECT_VIDEO][j].title + t(" downloaded successfully"), courseid, OBJECT_VIDEO,CONTROLLER.getVariable("activecatid"));
                }
        }
            }
        }
    }
    CONTROLLER.setVariable("courseleveldownload","");
}

MobileCategoryService.prototype.getAllCategoriesOnline = function (groupid)
{
    var contentUtilsService = CONTROLLER.getService("ContentUtils");
    if (typeof groupid != "undefined" && groupid != "") {
        var group = Platform.getObjectProperty("group", groupid, "content");
        group = $.parseJSON(group);
        var grouptitle = group.title;
    }
    else if (typeof CLIENT_GROUP != "undefined")
    {
        grouptitle = CLIENT_GROUP;
    }
    else {
        grouptitle = "";
    }
    if (Platform.getNetworkState() == true) {
        var ts = new Date().getTime();
        var url = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + '&t=' + ts;
        var categories = Platform.makeHttpGetCall(url);
        try
        {
            if (!$.isEmptyObject(categories))
            {
                categories = $.parseJSON(categories);
                var status = categories['status'];
                if (status == 103) {
                    categories = this.retry(url);
                }
                if (status == 200 || categories != false)
                {
                    var data = $.parseJSON(categories['data']);
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].nid != null) {
                            if (typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP) {
                                delete data[i].description;
                                delete data[i].price;
                                delete data[i].external_id;
                            }
                            Platform.saveObject(OBJECT_CATEGORY, data[i].nid, "content", JSON.stringify(data[i]));
                            if (typeof groupid != "undefined" && groupid != "") {
                                Platform.addRelation("group", groupid, OBJECT_CATEGORY, data[i].nid, "");
                            }
                        }
                    }
                }
                else
                {
                    console.log("error status" + status + ", getAllCategoriesOnline ");
                    this.setBootstrapStatus("failed", group, status);
                }
            } else {
                console.log("Empty JSON data" + " getAllCategoriesOnline ");
                status = "Empty JSON data url :- " + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + '&t=' + ts;
                this.setBootstrapStatus("failed", group, status);
            }
        }
        catch (e)
        {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle)
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            +"\n getAllCategories for Aakash live ";
            console.log(error);
            this.setBootstrapStatus("failed", group, status);
        }
        ;
    }
    else
    {
        this.setBootstrapStatus("failed", group, status);
    }
};

MobileCategoryService.prototype.retry = function (url) {
    try {
        var data = "";
        var THIS = this;
        console.log("retrying for url - " + url);
        for (var i = 0; i <= 100; i++) {
            sleep(5000);
            data = Platform.makeHttpGetCall(url);
            if (!$.isEmptyObject(data))
            {
                data = $.parseJSON(data);
                var status = data['status'];
            }
            console.log(status);
            if (status == 200) {
                return data;
            }
            if (i == 100) {
                if (confirm(t('Unable to continue after multiple attempts to connect, do you want to retry again?')) == true) {
                    i = 0;
                }
            }
        }
        return false;
    }
    catch (e) {
        console.log("retry failed for url - " + url);
    }
}

MobileCategoryService.prototype.addCategoriestoUserGroups = function(groupid) {
    if (typeof groupid != "undefined" && groupid != "") {
        var group = Platform.getObjectProperty("group", groupid, "content");
        group = $.parseJSON(group);
        var grouptitle = group.title;
    }
    else if (typeof CLIENT_GROUP != "undefined")
    {
        grouptitle = CLIENT_GROUP;
    }
    else {
        grouptitle = "";
    }
    if (Platform.getNetworkState() == true) {

        var ts = new Date().getTime();
        var url = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + '&t=' + ts;
        var categories = Platform.makeHttpGetCall(url);
        try
        {
            if (!$.isEmptyObject(categories))
            {
                categories = $.parseJSON(categories);
                var status = categories['status'];
                if (status == 103) {
                    categories = this.retry(url);
                }
                if (status == 200 || categories != false)
                {
                    var data = $.parseJSON(categories['data']);
                    for (var i = 0; i < data.length; i++) {
                        if (typeof groupid != "undefined" && groupid != "") {
                            ADD_RELATION_ARRAY.push({"objecttype":"group","objectid":groupid,"relobjecttype":OBJECT_CATEGORY,"relobjectid":data[i].nid});
                        }
                    }
                }
                else
                {
                    console.log("error status" + status);
                }
            } else {
                console.log("Empty JSON data");
                status = "Empty JSON data url :- " + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + '&t=' + ts;
            }
        }
        catch (e)
        {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle)
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            console.log(error);
        }
        ;
    }
};

MobileCategoryService.prototype.getCompletedVideosOfCategoryCount = function (groupid,categoryid,filetype,contenttype) {
    if ((filetype) && (contenttype)) {
        var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
    } else {
        var ignore_params = "";
    }
    var count = Platform.getVideoViewHistoryCompletedCountByType(OBJECT_USER_ID,groupid,categoryid,"subject",ignore_params);
    return count;
}

MobileCategoryService.prototype.getSearchedChapters = function (groupid,categoryid,searchtext) {
    var filter = '[{"object_type":"group","object_id":' + groupid + ',"relation_object_type":' + OBJECT_CATEGORY+ ',"relation_object_id":'+ categoryid+'}]';
    var chapters = Platform.searchObject(searchtext,OBJECT_CHAPTER,100,0,filter);
    chapters = $.parseJSON(chapters);
    return chapters;
}

MobileCategoryService.prototype.getTagsForCategory = function(catid,callback){
    try{
        var url = OBJECT_SERVER_DOMAIN + "/api/download/filesbytag?cat_id=" + catid + "&uid=" + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED;
        if (Platform.getNetworkState() === true) {
            onGetTagsForCategoryComplete = function (tags_data) {
                var result = '';
                var status = tags_data['status'];
                if (status != 200) {
                    result = t("RESOURCE_ERROR",true);
                }else if (status == 403) {
                    result=onAccessDenied();
                }
                else {
                    var data = tags_data['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetTagsForCategoryComplete");
            } else {
                var tags_data = Platform.makeHttpGetCall(url);
                return onGetTagsForCategoryComplete($.parseJSON(tags_data));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }catch(e){
        Client.closeProgressBar();
        e.console.log(e.message);
    }
}

MobileCategoryService.prototype.getCategoryTagContents = function(catid, tagid, page, limit, callback){
    try{

        var url = OBJECT_SERVER_DOMAIN + "/api/download/filesbytag?cat_id=" + catid +"&tag_id="+ tagid + "&page="+ page +"&limit="+ limit +"&uid=" + OBJECT_USER_ID +"&auth=" + AUTH_ENABLED;
        if (Platform.getNetworkState() === true) {
            onGetCategoryTagContentsComplete = function (content_data) {
                var result = '';
                var status = content_data['status'];
                if (status != 200) {
                    result = t("RESOURCE_ERROR",true);
                }else if (status == 403) {
                    result=onAccessDenied();
                }
                else {
                    var data = content_data['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetCategoryTagContentsComplete");
            } else {
                var content_data = Platform.makeHttpGetCall(url);
                return onGetCategoryTagContentsComplete($.parseJSON(content_data));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }catch(e){
        Client.closeProgressBar();
        e.console.log(e.message);
    }
}

MobileCategoryService.prototype.downloadSingleContent = function(resourceid){
    try{
        if (Platform.getNetworkState() === true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/' + OBJECT_VIDEO + '/downloadurl?nid=' + resourceid + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED;
            var download_content = Platform.makeHttpGetCall(url);
            download_content = $.parseJSON(download_content);
            if (download_content.status == 200) {
                var download_content_data = JSON.parse(download_content.data);
                var download_path = download_content_data.url;
                return download_path;
            }else if (download_content.status == 403) {
                onAccessDenied();
                Client.closeProgressBar();
                return false;
            }else{
                Client.closeProgressBar();
                alert(t("RESOURCE_ERROR",true));
                return false;
            }

        } else {
            Client.closeProgressBar();
            alert( t("INTERNET_ERROR",true));
            return false;
        }
    }catch(e){
        Client.closeProgressBar();
        e.console.log(e.message);
    }
}

MobileCategoryService.prototype.getCategoryTags = function(nid){
    return Platform.getRelatedObjects(OBJECT_CATEGORY, nid , "tag")
}

MobileCategoryService.prototype.getNotesofUserByCategory = function (catid, groupid)
{
    try {
        if(typeof NOTES_OF_CATEGORY != "undefined" && NOTES_OF_CATEGORY.length > 0){
            for (var i = 0; i < NOTES_OF_CATEGORY.length; i++) {
                if(typeof NOTES_OF_CATEGORY[i].catid != "undefined" && NOTES_OF_CATEGORY[i].catid == catid && NOTES_OF_CATEGORY[i].groupid == groupid)
                    return NOTES_OF_CATEGORY[i].notes_data;
            }
        }

        var courseService = CONTROLLER.getService("Course");
        var category = Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content");
        category = $.parseJSON(category);
        var i = 0;
        var courseid = CONTROLLER.getVariable("activecourseid");
        var catid = CONTROLLER.getVariable("activecatid");
        var groupid = CONTROLLER.getVariable("activegroupid");
        var categoryNotes = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getnotesofuserby' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&nid=' + category.tid);
        categoryNotes = $.parseJSON(categoryNotes);

        var notes = {};
        var status = categoryNotes['status'];
        if (status == 200) {
           var data = categoryNotes['data'];
           notes[OBJECT_NOTES] = $.parseJSON(data);
           notes[OBJECT_NOTES] = sortByDuration(notes[OBJECT_NOTES])
            for (var i = 0; i < notes[OBJECT_NOTES].length; i++) {
                notes[OBJECT_NOTES][i]['catid'] = catid;
                notes[OBJECT_NOTES][i]['groupid'] = groupid;
                notes[OBJECT_NOTES][i].originalDuration = notes[OBJECT_NOTES][i].duration;
                notes[OBJECT_NOTES][i].note = notes[OBJECT_NOTES][i].note.replace(/^\n+|\n+$/g, '').replace(/^\t+|\t+$/g, '').replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
                notes[OBJECT_NOTES][i].duration = getVideoDurationFromSeconds(parseInt(notes[OBJECT_NOTES][i].duration, 10)) + "m";

                var content = Platform.getObjectProperty(OBJECT_VIDEO, notes[OBJECT_NOTES][i]['resourceid'], "content");
                content = $.parseJSON(content);
                var chapterid = content[OBJECT_CHAPTER][0];
                notes[OBJECT_NOTES][i]['chapterid'] = chapterid;
            }

            var new_notes_list = this.getNotesofCategoryWithTopicDetails(notes, catid);

            var notesCategory ={};
            notesCategory.catid = catid;
            notesCategory.groupid = groupid;
            notesCategory.notes_data = new_notes_list;
            NOTES_OF_CATEGORY.push(notesCategory);

            return new_notes_list;
        } else {
            return false;
        }
    } catch (e) {
        console.log("function : getCourseById"
                + "\n message:" + e.message);
    }
};

MobileCategoryService.prototype.getNotesofCategoryWithTopicDetails = function(category_notes, catid)
{
    try {
        var courseService = CONTROLLER.getService("Course");
        var chapterService = CONTROLLER.getService("Chapter");
        var groupid = CONTROLLER.getVariable("activegroupid");
        var user_groups = OBJECT_USER_GROUPS;
        if (typeof catid != 'undefined' && catid != '' && ((typeof GROUP_SUBSCRIPTION != "undefined" && GROUP_SUBSCRIPTION != "true") || typeof GROUP_SUBSCRIPTION == "undefined")) {
            if(HIDE_GROUPS_FILTER == "true"){
                var allcourses = []; allcourses[OBJECT_COURSE] = []; var allcourses;
                var courses = []; courses[OBJECT_COURSE] = [];
                for(var k = 0; k < user_groups.length; k++){
                     var allcourses = this.getCoursesForCategory(catid, user_groups[k]);
                     for(var l = 0; l < allcourses[OBJECT_COURSE].length; l++){
                         if(jsonArrayHasId(courses[OBJECT_COURSE], allcourses[OBJECT_COURSE][l].nid) == false){
                             courses[OBJECT_COURSE].push(allcourses[OBJECT_COURSE][l]);
                         }
                     }
                }

            }
            else{
                var courses = this.getCoursesForCategory(catid, groupid);
            }
        } else {
            var group_service = CONTROLLER.getService("Group");
            var groups = OBJECT_USER_DATA.groups;
            var courses = group_service.getCoursesForUserGroups(groups);
        }
        var chapters = "";
        var new_notes_list = [];
        new_notes_list[OBJECT_COURSE] = [];

        if (category_notes[OBJECT_NOTES].length > 0) {
            for (var l = 0; l < courses[OBJECT_COURSE].length; l++) {
                var courseid = courses[OBJECT_COURSE][l].nid;
                new_notes_list[OBJECT_COURSE][l] = [];
                new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER] = [];
                var chapters = courseService.getChaptersForCourse(courses[OBJECT_COURSE][l].nid, groupid);
                for (var i = 0; i < chapters[CLIENT_OBJECT_CHAPTER].length; i++) {
                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i] = {};
                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i].title = chapters[OBJECT_CHAPTER][i].title;
                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO] = [];
                    var chapterid = chapters[OBJECT_CHAPTER][i].nid;
                    var resources = chapterService.getResourcesForChapter(chapterid, courses[OBJECT_COURSE][l].nid);
                    for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = {};
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k].title = resources[OBJECT_VIDEO][k].title;
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES] = [];
                    }
                }

                for (var i = 0; i < chapters[CLIENT_OBJECT_CHAPTER].length; i++) {
                    for (var j = 0; j < category_notes[OBJECT_NOTES].length; j++) {
                        if (chapters[OBJECT_CHAPTER][i].nid == category_notes[OBJECT_NOTES][j].chapterid) {
                            var chapterid = chapters[OBJECT_CHAPTER][i].nid;
                            var resources = chapterService.getResourcesForChapter(chapterid, courses[OBJECT_COURSE][l].nid);
                            for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                                if (resources[OBJECT_VIDEO][k].nid == category_notes[OBJECT_NOTES][j].resourceid) {
                                    category_notes[OBJECT_NOTES][j].courseid = courseid;
                                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].push(category_notes[OBJECT_NOTES][j]);
                                }
                            }
                        }
                    }
                }
            }
            for (var l = courses[OBJECT_COURSE].length - 1; l >= 0; l--) {
                topicsnotescount = 0;
                var chapters = courseService.getChaptersForCourse(courses[OBJECT_COURSE][l].nid, groupid);
                for (var i = chapters[CLIENT_OBJECT_CHAPTER].length - 1; i >= 0; i--) {
                    notescount = 0;
                    for (var k = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO].length - 1; k >= 0; k--) {
                        notescount = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO].length;
                        count = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length;
                        if (new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length == 0) {
                            new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = "";
                            new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO].splice(k, 1);
                            notescount = notescount - 1;
                        }
                    }
                    topicsnotescount = topicsnotescount + notescount;
                    if (notescount == 0) {
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i] = "";
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER].splice(i, 1);
                    }
                }
                if (topicsnotescount == 0) {
                    new_notes_list[OBJECT_COURSE][l] = "";
                    new_notes_list[OBJECT_COURSE].splice(l, 1);
                }
            }
        }
        return new_notes_list;
    } catch (e) {
        console.log("function : getCourseById"
                + "\n message:" + e.message);
    }
}

MobileCategoryService.prototype.getAllProducts = function (callback) {
    try {
        var url = OBJECT_SERVER_DOMAIN + "/api/get/product/module?uid=" + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED;
        if (Platform.getNetworkState() === true) {
            onGetAllProductsComplete = function (content_data) {
                var result = '';
                var status = content_data['status'];
                if (status != 200) {
                    result = RESOURCE_ERROR;
                } else if (status == 403) {
                    result = onAccessDenied();
                } else {
                    var data = content_data['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetAllProductsComplete");
            } else {
                var content_data = Platform.makeHttpGetCall(url);
                return onGetAllProductsComplete($.parseJSON(content_data));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    } catch (e) {
        Client.closeProgressBar();
        e.console.log(e.message);
    }
};

MobileCategoryService.prototype.getUserCategoryProgress = function(category_tids, callback) {
    try{
        var url = OBJECT_SERVER_DOMAIN + "/api/getusercategoryprogress?uid=" + OBJECT_USER_ID + "&tids=" + category_tids;
        if (Platform.getNetworkState() === true) {
            onGetUserCategoryProgress = function (response_data) {
                var result = '';
                var status = response_data['status'];
                if (status != 200) {
                    result = RESOURCE_ERROR;
                } else if (status == 403) {
                    result = onAccessDenied();
                } else {
                    var data = response_data['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetUserCategoryProgress");
            } else {
                var response_data = Platform.makeHttpGetCall(url);
                return onGetUserCategoryProgress($.parseJSON(response_data));
            }
        }else{
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }catch(e){
        console.log("function : getUserCategoryProgress" + "\n message:" + e.message);
    }
};

MobileCategoryService.prototype.getCoursesWithFlashCard = function(groupId){
    try{
        if(typeof groupid == "undefined" || groupid == ""){
            groupid = CONTROLLER.getVariable("activegroupid");
        }
        var ts = new Date().getTime();
        var url = OBJECT_SERVER_DOMAIN + "/api/get/flashcardcourses?active_group=" + groupId + "&filter=all&ts=" + ts + "&auth=" + AUTH_ENABLED;
        //added filter=all so all categories (both subscribed and unsubscribed) are returned.
        if(CATEGORIES_WITH_FLASHCARD.hasOwnProperty(groupId) == true){
            return CATEGORIES_WITH_FLASHCARD[groupId];
        }else{
            if (Platform.getNetworkState() === true) {
                var content_data = $.parseJSON(Platform.makeHttpGetCall(url));
                var status = content_data['status'];
                if (status != 200) {
                    result = RESOURCE_ERROR;
                } else if (status == 403) {
                    result = onAccessDenied();
                } else {
                    var data = content_data['data'];
                    data = $.parseJSON(data);
                    result = data;
                    CATEGORIES_WITH_FLASHCARD[groupId] = result['data'];
                }
                return result['data'];
            }else {
                alert(INTERNET_ERROR);
                return;
            }
        }
    }catch(e){
        console.log("function : getCoursesWithFlashCard: " + "\n message:" + e.message);
    }
}

MobileCategoryService.prototype.getObjectCategoryProperties = function(catid){
    try{
        if(CATEGORY_PROPERTIES.hasOwnProperty(catid) == false){
            var data = Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content");
            CATEGORY_PROPERTIES[catid] = data;
            return data;
        }else{
            return CATEGORY_PROPERTIES[catid];
        }
    }catch(e){
        console.log("function : getObjectCategoryProperties " + e.message);
    }
};

MobileCategoryService.prototype.getCourseLengthForCategory = function(groupid, catid){
    try{

        var filter = '[{"object_type":"'+OBJECT_COURSE+'"}]';
        var ignore_params = ""
        var course_length = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid , filter, ignore_params);
        return course_length;

    }catch(e){
        console.log("function : getObjectCategoryProperties " + e.message);
    }
}

MobileCategoryService.prototype.getUserAdaptiveCategories = function(cat_nid){
    try{

        var cat_params = ""
        if(typeof cat_nid != 'undefined'){
            cat_params = "&cat_nid=" + cat_nid
        }
        var url = OBJECT_SERVER_DOMAIN + "/api/adaptive/getusercategory?preset=video_thumbnail_medium" + cat_params + "&auth=" + AUTH_ENABLED;
        
        if (Platform.getNetworkState() === true) {
            var content_data = $.parseJSON(Platform.makeHttpGetCall(url));
            var status = content_data['status'];
            if (status == 200) {
                var data = content_data['data'];
                data = $.parseJSON(data);
                result = data;
                return result['data'];
            } else {
                return ERROR_MESSAGE
            }
        }else {
            alert(INTERNET_ERROR);
            return;
        }
    }catch(e){
        console.log("function : getCoursesWithFlashCard: " + "\n message:" + e.message);
    }
}
function MobileChapterService(){};

MobileChapterService.prototype.downloadSingleChapter = function(chapterid,changed){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'fromid?nid=' + chapterid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&changed=' + changed + "&version=" + API_VERSION, OBJECT_CHAPTER+"-"+chapterid);
    }
    catch(e){
        console.log("function : downloadSingleChapter"
            + " message" + e.message);
    }
};

MobileChapterService.prototype.downloadChaptersForCourseAsync = function(courseid){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'sfor' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET, OBJECT_CHAPTER+"-"+courseid);
    }
    catch(e){
        console.log("function : downloadChaptersForCourseAsync"
            + " message" + e.message);
    }
};

MobileChapterService.prototype.getResourcesForChapter = function(chapterid, courseid, group)
{
    try{
        var courseid = courseid;
        if(EXEC_MODE == "online"){
            if(typeof courseid != "undefined" && COURSE_DETAILS.hasOwnProperty(courseid)){
                var chapter = getJSONByKey(COURSE_DETAILS[courseid][OBJECT_CHAPTER],"nid",chapterid);
                var videos = [];
                videos[OBJECT_VIDEO] = chapter[0][OBJECT_VIDEO];
                return videos;
            }
            var resources = Platform.getRelatedObjects(OBJECT_CHAPTER, chapterid, OBJECT_VIDEO);
        } else {
            var group = group; 
            if(typeof courseid !="undefined" && courseid != ""){
                var courseid = courseid;
            }else{
                var courseid =CONTROLLER.getVariable("activecourseid");
            }
            if (COURSE_DETAILS.hasOwnProperty(courseid) == false) {
                if (group) {
                    var filter = '[{"object_type":"group","object_id":"' + group + '" ,"relation_object_type":"' + OBJECT_VIDEO + '"}]';
                    var resources = Platform.getRelatedObjectsFilterByGroup(group,OBJECT_CHAPTER, chapterid, OBJECT_VIDEO);
                } else {
                    var resources = Platform.getRelatedObjects(OBJECT_CHAPTER, chapterid, OBJECT_VIDEO);
                }
            }else{
                var coursedetails = COURSE_DETAILS[courseid];
                for (var i = 0; i < coursedetails[OBJECT_CHAPTER].length; i++) {
                    var resources = {};
                    resources[OBJECT_VIDEO] = [];
                    if (coursedetails[OBJECT_CHAPTER][i]["nid"] == chapterid) {
                        resources[OBJECT_VIDEO] = coursedetails[OBJECT_CHAPTER][i][OBJECT_VIDEO];
                        break;
                    }
                }
            }
        }
        if(EXEC_MODE == "online" || typeof resources == "string"){
            resources = $.parseJSON(resources);
        }
        return resources;
    }
    catch(e){
        console.log( "function : getResourcesForChapter"
            + "\n message:" + e.message);
    }
};

MobileChapterService.prototype.getResourcesCount = function(catid,courseid,groupid,chapterid){
    try{
        var filter = '[{"object_type":"'+OBJECT_COURSE+'","object_id":"'+courseid+'"},{"object_type":"'+OBJECT_CHAPTER+'","object_id":"'+chapterid+'"},{"object_type":"'+OBJECT_VIDEO+'"}]';
        var resourcescount = Platform.getRelationObjectCount(groupid,OBJECT_CATEGORY,catid,filter);
        return resourcescount;
    }
    catch(e){
        
    }
}

MobileChapterService.prototype.getCompletedVideosOfChapterCount = function (groupid,chapterid) {
    groupid = "";
    var count = Platform.getVideoViewHistoryCompletedCountByType(OBJECT_USER_ID,groupid,chapterid,"chapter");
    return count;
}

MobileChapterService.prototype.getSupportDocsForChapter = function(chapterid)
{
    var groups = ""; var resources = "";
    if (HIDE_GROUPS_FILTER == "true") {
        groups = OBJECT_USER_GROUPS.join();
    } else {
        groups = CONTROLLER.getVariable("activegroupid");
    }
    if(EXEC_MODE == "online"){
        resources = Platform.getRelatedObjects(OBJECT_CHAPTER, chapterid, OBJECT_SUPPORT_DOCS, groups);
    }else{
        resources = Platform.getRelatedObjects(groups, OBJECT_CHAPTER, chapterid, OBJECT_SUPPORT_DOCS);
    }
    resources = $.parseJSON(resources);
    return resources;
}


MobileChapterService.prototype.getChapterById = function(chapterid)
{
    try{
        var chapter = Platform.getObjectProperty(OBJECT_CHAPTER, chapterid, "content");
        chapter = $.parseJSON(chapter);
        return chapter;
    }
    catch(e){
        console.log( "function : getChapterById"
            + "\n message:" + e.message);
    }
};


MobileChapterService.prototype.getResourcesForChapterByGroups = function(chapterid) {
    try {
        var chapter_resources = [];
        var content_groups_array = this.getContentGroupsForVideViedwedHistory();
        var content_groups = content_groups_array.length ? ('"' + content_groups_array.join('","') + '"') : "";
        var content_groups = CONTROLLER.getVariable("contentGroups");
        for (var i = 0; i < content_groups.length; i++) {
            var resources = this.getResourcesForChapter(chapterid,'', content_groups[i].nid);
            for (var j = 0; j < resources[OBJECT_VIDEO].length; j++) {
                chapter_resources.push(resources[OBJECT_VIDEO][j]);
            }
        }
        resources[OBJECT_VIDEO] = chapter_resources;
        return resources;

    } catch (e) {
        Client.closeProgressBar();
        console.log("function :- getResourcesForChapterByGroups, message :- " + e.message);
    }
}

MobileChapterService.prototype.getContentGroupsForVideViedwedHistory = function() {
    try{
        var contentGroups = CONTROLLER.getVariable("contentGroups");
        var user_content_groups = [];
        for (var i = 0; i < contentGroups.length; i++) {
            user_content_groups.push(contentGroups[i].nid)
        }
        return user_content_groups;
    }catch(e){
        console.log("function :- getContentGroupsForVideViedwedHistory, message :- " + e.message);
    }
}

MobileChapterService.prototype.getObjectChapterProperties = function(chapterid){
    try{
        if(CHAPTER_PROPERTIES.hasOwnProperty(chapterid) == false){
            var data = Platform.getObjectProperty(OBJECT_CHAPTER, chapterid, "content")
            CHAPTER_PROPERTIES[chapterid] = data;
            return data;
        }else{
            return CHAPTER_PROPERTIES[chapterid];
        }
    }catch(e){
        console.log("getObjectChapterProperties " + e.message);
    }
};
function MobileGroupService(){

}

MobileGroupService.prototype.getAllGroups = function()
{
    try{
        var groups = Platform.getObjectsOfType("group");
        if(groups != ""){
            return $.parseJSON(groups);
        }
        else
        {
            return "";
        }
    }
    catch(e){
        console.log( "function : getAllGroups"
            + "\n message:" + e.message);
    }

};

MobileGroupService.prototype.getAllGroupsOnline = function () {
    var groups = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_GROUPS + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET);
    try {
        groups = $.parseJSON(groups);
        var status = groups['status'];
        if (status == 200)
        {
            var data = $.parseJSON(groups['data']);
            return data;
        }
    }
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_GROUPS + '?uid=' + OBJECT_USER_ID
                + "\n error_code :" + status
                + "\n response_data :" + data
                + "\n message :" + e.message;
        +"\n function:" + 'getAllGroupsOnline';
        console.log(error);
    }
    ;
};

MobileGroupService.prototype.getGroupIdByName = function(group){
    var groups = this.getAllGroups();
    if(groups != ""){
        for(var i= 0;i<groups.group.length;i++){
            if(groups.group[i].title == group){
                var groupid = groups.group[i].nid;
            }
        }
    }
    else{
        groupid = '';
    }

    return groupid;
}

MobileGroupService.prototype.getCoursesForUserGroups = function (groups)
{
    try {
        var coursesForGroup=[];
        coursesForGroup[OBJECT_COURSE]=[];
        for(var i=0; i<groups.length;i++){
            var courses = Platform.getRelatedObjects(OBJECT_GROUP, groups[i], OBJECT_COURSE);
            courses = $.parseJSON(courses);
            for(var j=0; j<courses[OBJECT_COURSE].length;j++){
                if(jsonArrayHasId(coursesForGroup[OBJECT_COURSE], courses[OBJECT_COURSE][j].nid) == false){
                        coursesForGroup[OBJECT_COURSE].push(courses[OBJECT_COURSE][j])
                     }

            }

        }
        return coursesForGroup;
    } catch (e) {
        console.log("function : getChaptersForCourse"
                + "\n message:" + e.message);
    }
};

MobileGroupService.prototype.getGroupFromId = function (groupId) {
    var groups = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_GROUP + 'fromid?group=' + groupId + '&auth=' + AUTH_ENABLED);
    try {
        groups = $.parseJSON(groups);
        var status = groups['status'];
        if (status == 200) {
            var data = $.parseJSON(groups['data']);
            return data;
        } else {
            return false;
        }
    }
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_GROUPS + '?uid=' + OBJECT_USER_ID
                + "\n error_code :" + status
                + "\n response_data :" + data
                + "\n message :" + e.message;
        +"\n function:" + 'getAllGroupsOnline';
        console.log(error);
    }
    ;
};

MobileGroupService.prototype.getObjectGroupProperties = function(groupid){
    try{
        if(GROUP_PROPERTIES.hasOwnProperty(groupid) == false){
            var data = Platform.getObjectProperty(OBJECT_GROUP, groupid, "content");
            GROUP_PROPERTIES[groupid] = data;
            return data;
        }else{
            return GROUP_PROPERTIES[groupid];
        }
    }catch(e){
        console.log("getObjectGroupProperties " + e.message);
    }
};
function CalenderService() {

};


CalenderService.prototype.getAllCalenderEvents =  function(startDate, endDate, callback){
    if(typeof ENABLE_LOCALIZATION != "undefined" && ENABLE_LOCALIZATION == "true") {
        var langCode = Platform.getPreference("lang");
        var url = OBJECT_SERVER_DOMAIN + '/api/getcalendarevents?start=' + startDate + '&end=' + endDate + '&lid=' + langCode + '&auth=' + AUTH_ENABLED;
    }else{
        var url = OBJECT_SERVER_DOMAIN + '/api/getcalendarevents?start=' + startDate + '&end=' + endDate + '&auth=' + AUTH_ENABLED;
    }
    try{
        if (Platform.getNetworkState() == true) {
            onGetAllCalenderEvents = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200) {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                } else if (status == 403) {
                    onAccessDenied();
                } else {
                    result = t("ERROR_MESSAGE",true);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetAllCalenderEvents");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return onGetAllCalenderEvents($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
   
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getcalendarevents '+ start + '&end=' + end
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}   


function WebinarService() {
}
;

WebinarService.prototype.getAllWebinars = function(page, limit, groups, category)
{
    try {
        var groupsparams="",categoryparams = "",limitparams = "",pageparams="";
        if(groups){
            groupsparams = "&groups=" + groups;
        }
        if(category){
            categoryparams = "&category=" + category;
        }
        if(limit){
            limitparams = "&limit=" + limit;
        }
        if(page){
            pageparams = "&page=" + page;
        }
        
        if (Platform.getNetworkState() == true) {
            if(typeof ENABLE_LOCALIZATION != "undefined" && ENABLE_LOCALIZATION == "true"){
                var langCode = Platform.getPreference("lang");
                var langid = "&lid=" + langCode;
                var url = OBJECT_SERVER_DOMAIN + '/api/getall'+OBJECT_WEBINAR+'?uid=' + OBJECT_USER_ID + groupsparams + categoryparams + limitparams + pageparams + langid;
            }else{
                var url = OBJECT_SERVER_DOMAIN + '/api/getall'+OBJECT_WEBINAR+'?uid=' + OBJECT_USER_ID + groupsparams + categoryparams + limitparams + pageparams;
            }
             var webinars_data = Platform.makeHttpGetCall(url);
            webinars_data = $.parseJSON(webinars_data);
            var status = webinars_data['status'];
            var webinars_json = {};
            if (status == 200) {
                var webinarsdata = webinars_data['data'];
                webinarsdata = webinarsdata.trim();
                if(webinarsdata != ""){
                     webinarsdata = $.parseJSON(webinarsdata);
                }
                webinars_json[OBJECT_WEBINAR] = webinarsdata;
                return webinars_json;
            }
            else if(status == 403){
                return status;
            }
            else {
                return t("ERROR_MESSAGE",true);
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : getAllWebinars"
                + "\n message:" + e.message);
    }
};

WebinarService.prototype.getWebinarStatus = function(nid)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/get'+OBJECT_WEBINAR+'status?uid=' + OBJECT_USER_ID + "&nid=" + nid;
             var webinars_data = Platform.makeHttpGetCall(url);
            webinars_data = $.parseJSON(webinars_data);
            var status = webinars_data['status'];
            if (status == 200) {
                var webinarsdata = webinars_data['data'];
                webinarsdata = webinarsdata.trim();
                if(webinarsdata != ""){
                     webinarsdata = $.parseJSON(webinarsdata);
                }
                return webinarsdata;
            }
            else if(status == 403){
                return status;
            }
            else {
                return t("ERROR_MESSAGE",true);
            }
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : getWebinarStatus"
                + "\n message:" + e.message);
    }
};

WebinarService.prototype.updateWebinarAttendance = function(nid)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/'+OBJECT_WEBINAR+'updateattendance?uid=' + OBJECT_USER_ID + "&nid=" + nid;
            onUpdateWebinarAttendanceComplete = function (result) {
                if (typeof result["status"] != 'undefined' && result["status"] == '200') {
                    console.log("function : updateWebinarAttendance "
                    + "\n Message: Attendance updated succssfully");
                }
                else {
                    console.log("function : updateWebinarAttendance"
                    + "\n Message: Attendance update Failed");
                }
            }
            makeAsyncTaskCall("","",url,"get","false","onUpdateWebinarAttendanceComplete");
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : getWebinarStatus"
                + "\n message:" + e.message);
    }
};

WebinarService.prototype.getAllCalenderEventsForWebinar =  function(startDate, endDate, callback){
    var url = OBJECT_SERVER_DOMAIN + '/api/getcalendarevents?start=' + startDate + '&end=' + endDate +  '&type='+OBJECT_WEBINAR+ '&uid=' + OBJECT_USER_ID;
    try{
        if (Platform.getNetworkState() == true) {
            onGetAllCalenderEvents = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200)
                {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                }else if(status == 403){
                    onAccessDenied();
                }else{
                    result = ERROR_MESSAGE;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetAllCalenderEvents");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return onGetAllCalenderEvents($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }

    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getcalendarevents '+ start + '&end=' + end
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
};

WebinarService.prototype.updateWebinar = function(nid,status)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/update/'+OBJECT_WEBINAR+'status?nid=' + nid + "&uid=" +
                    OBJECT_USER_ID + '&action=' + status + '&auth=' + AUTH_ENABLED;
            onUpdateWebinarComplete = function (result) {
                if (typeof result["status"] != 'undefined' && result["status"] == '200') {
                    console.log("function : updateWebinarStatus "
                    + "\n Message: Webinar status updated successfully");
                }
                else {
                    console.log("function : updateWebinarStatus"
                    + "\n Message: Webinar status update failed");
                }
            }
            makeAsyncTaskCall("","",url,"get","false","onUpdateWebinarComplete");
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : getWebinarStatus"
                + "\n message:" + e.message);
    }
};

WebinarService.prototype.createNewMeeting = function(nid){
    try{
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/create/'+OBJECT_WEBINAR+'meetingid?uid=' + OBJECT_USER_ID + "&nid=" +
                    nid + '&auth=' + AUTH_ENABLED;
            var meeting_data = Platform.makeHttpGetCall(url);
            meeting_data = $.parseJSON(meeting_data);
            var status = meeting_data['status'];
            if (status == 200) {
                var meetingData = meeting_data['data'];
                if(meetingData != ""){
                     meetingData = $.parseJSON(meetingData);
                }
                return meetingData.meetingid;
            }
            else if (status == 405 || status == 403 || status == 401){
                return status;
            }
            else {
                return ERROR_MESSAGE;
            }
        }else{
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log("function : createNewMeeting"
                + "\n message:" + e.message);
    }
};

WebinarService.prototype.getWebinarById = function(nid)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/get'+OBJECT_WEBINAR+'bynid?nid=' + nid;
            var webinars_data = Platform.makeHttpGetCall(url);
            webinars_data = $.parseJSON(webinars_data);
            var status = webinars_data['status'];
            if (status == 200) {
                var webinarsdata = webinars_data['data'];
                webinarsdata = webinarsdata.trim();
                if(webinarsdata != ""){
                     webinarsdata = $.parseJSON(webinarsdata);
                }
                return webinarsdata;
            }
            else if(status == 403){
                return status;
            }
            else {
                return ERROR_MESSAGE;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : getWebinarById"
                + "\n message:" + e.message);
    }
};

WebinarService.prototype.joinWebinar = function(meetingid,password,displayname,email,account){
    try{
        Webinar.join(JSON.stringify({
            'meetingid': meetingid,
            'password':password,
            'displayname':displayname,
            'email':email,
            "account":account
        }));
    }
    catch(e){
        console.log("Function : joinWebinar, error message : " + e.message);
    }
}

WebinarService.prototype.startWebinar = function(meetingid,password,displayname,email,account){
    try{
        Webinar.start(JSON.stringify({
            'meetingid': meetingid,
            'password':password,
            'displayname':displayname,
            'email':email,
            "account":account
        }));
    }
    catch(e){
        console.log("Function : startWebinar, error message : " + e.message);
    }
}

WebinarService.prototype.getWebinarUserInfo = function(account, webinarNid){
    try{
        return Webinar.getWebinarUserInfo(account, webinarNid);
    }
    catch(e){
        console.log("Function : getWebinarUserInfo, error message : " + e.message);
    }
}

WebinarService.prototype.getMyWebinars = function(type){
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getmywebinars?type=' + type + '&preset=' + OBJECT_PRESET + '&auth=' + AUTH_ENABLED;
            var webinars_data = Platform.makeHttpGetCall(url);
            webinars_data = $.parseJSON(webinars_data);
            var status = webinars_data['status'];
            if (status == 200) {
                var webinarsdata = webinars_data['data'];
                webinarsdata = webinarsdata.trim();
                if(webinarsdata != ""){
                     webinarsdata = $.parseJSON(webinarsdata);
                }
                return webinarsdata;
            }
            else if(status == 403){
                return status;
            }
            else {
                return ERROR_MESSAGE;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : getMyWebinars"
                + "\n message:" + e.message);
    }
}

WebinarService.prototype.getFeedbackQuizzes = function (callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/get/webinarfeedback?uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            onGetFeedbackQuizzes = function (events) {
                var result = '';
                var status = events['status'];
                if (status == 200) {
                    var data = events['data'];
                    if(data == ""){
                       data = false;
                    }else{
                        data = $.parseJSON(data);
                    }
                    result = data;
                } else if (status == 403) {
                    result = onAccessDenied();
                } else {
                    result = ERROR_MESSAGE;
                }

                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetFeedbackQuizzes");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return onGetFeedbackQuizzes($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    } catch (e) {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get/webinarfeedback '
            + "\n error_code :" + status
            + "\n response_data :" + data
            + "\n message :" + e.message;
        console.log(error);
    }
}

WebinarService.prototype.updateFeedback = function (data, callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/set/webinarfeedback';
    var postdata = encodeURIComponent(data);
    try {
        if (Platform.getNetworkState() == true) {
            onUpdateFeedback = function (events) {
                var result = '';
                var status = events['status'];
                if (status == 200) {
                    //no data returned data will always be "" so check only status
                    result = true;
                } else if (status == 403) {
                    result = onAccessDenied();
                } else {
                    result = ERROR_MESSAGE;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onUpdateFeedback");
            } else {
                //change to makeHttpPostCall
                var user = Platform.makeHttpGetCall(url);
                return onUpdateFeedback($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    } catch (e) {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/set/webinarfeedback '
            + "\n error_code :" + status
            + "\n response_data :" + data
            + "\n message :" + e.message;
        console.log(error);
    }
}
function ChallengeService() {
}

ChallengeService.prototype.getFastestAttempts = function (nid, limit, start_time, callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/' + OBJECT_QUIZ + '/getfastestattempts?nid=' + nid + '&limit=' + limit + '&start_time=' + start_time + '&uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            onGetFastestAttemptsComplete = function (attemptDetails) {
                var result = '';
                var status = attemptDetails['status'];
                if (status == 403) {
                    result = onAccessDenied();
                } else if (status != 200) {
                    result = t("ERROR_MESSAGE",true);
                } else {
                    var data = attemptDetails['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetFastestAttemptsComplete");
            } else {
                var quiz = Platform.makeHttpGetCall(url);
                return onGetFastestAttemptsComplete($.parseJSON(quiz));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    } catch (e) {
        console.log("function : getFastestAttempts"
            + "\n message:" + e.message);
    }
}
ChallengeService.prototype.getCompletedAttempts = function (nid, limit, start_time, callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/' + OBJECT_QUIZ + '/getcompletedattempts?nid=' + nid + '&limit=' + limit + '&start_time=' + start_time + '&uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            onGetCompletedAttemptsComplete = function (attemptDetails) {
                var result = '';
                var status = attemptDetails['status'];
                if (status == 403) {
                    result = onAccessDenied();
                } else if (status != 200) {
                    result = t("ERROR_MESSAGE",true);
                } else {
                    var data = attemptDetails['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetCompletedAttemptsComplete");
            } else {
                var quiz = Platform.makeHttpGetCall(url);
                return onGetCompletedAttemptsComplete($.parseJSON(quiz));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    } catch (e) {
        console.log("function : getCompletedAttempts"
            + "\n message:" + e.message);
    }
}

ChallengeService.prototype.createUserChallenge = function (invitedUid, quizId, callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/create/user' + OBJECT_CHALLENGE + '?invited_uid=' + invitedUid + '&challenged_uid=' + OBJECT_USER_ID + "&quiz_id=" + quizId + "&state=invited&auth=" + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            onCreateUserChallengeComplete = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 403) {
                    result = onAccessDenied();
                } else if (status != 200) {
                    result = t("ERROR_MESSAGE",true);
                } else {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onCreateUserChallengeComplete");
            } else {
                var quiz = Platform.makeHttpGetCall(url);
                return onCreateUserChallengeComplete($.parseJSON(quiz));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    } catch (e) {
        console.log("function : createUserChallenge"
            + "\n message:" + e.message);
    }
};

ChallengeService.prototype.updateUserChallenge = function (challengeId, invitedUid, quizId, state, callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/update/user' + OBJECT_CHALLENGE + '?challenge_id=' + challengeId + '&challenged_uid=' + OBJECT_USER_ID + '&invited_uid=' + invitedUid + '&quiz_id=' + quizId + '&state=' + state + '&auth=' + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            onUpdateUserChallengeCompelete = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 403) {
                    result = onAccessDenied();
                } else if (status != 200) {
                    result = t("ERROR_MESSAGE",true);
                } else {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onUpdateUserChallengeCompelete");
            } else {
                var quiz = Platform.makeHttpGetCall(url);
                return onUpdateUserChallengeCompelete($.parseJSON(quiz));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    } catch (e) {
        console.log("function : updateUserChallenge"
            + "\n message:" + e.message);
    }
};

ChallengeService.prototype.getUserChallenge = function (uid, groups, callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/get/user' + OBJECT_CHALLENGE + '?uid=' + OBJECT_USER_ID + "&groups=" + groups + "&auth=" + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            onGetUserChallengeComplete = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 403) {
                    result = onAccessDenied();
                } else if (status != 200) {
                    result = t("ERROR_MESSAGE",true);
                } else {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetUserChallengeComplete");
            } else {
                var quiz = Platform.makeHttpGetCall(url);
                return onGetUserChallengeComplete($.parseJSON(quiz));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    } catch (e) {
        console.log("function : getUserChallenge"
            + "\n message:" + e.message);
    }
};

ChallengeService.prototype.getInvitesReceived = function (groups, callback) {
    var groupFilter = groups ? ("&groups=" + groups) : "";
    var url = OBJECT_SERVER_DOMAIN + '/api/get/invitesreceived?uid=' + OBJECT_USER_ID + groupFilter + "&auth=" + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            onGetInvitesReceivedComplete = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 403) {
                    result = onAccessDenied();
                } else if (status == 404) {
                    result = [];
                } else if (status != 200) {
                    result = t("ERROR_MESSAGE",true);
                } else {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetInvitesReceivedComplete");
            } else {
                var data = Platform.makeHttpGetCall(url);
                return onGetInvitesReceivedComplete($.parseJSON(data));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    } catch (e) {
        console.log("function : getInvitesReceived"
            + "\n message:" + e.message);
    }
};

ChallengeService.prototype.getAcceptedChallenge = function (groups, search_text, callback) {
    var groupFilter = groups ? ("&groups=" + groups) : "";
    var searchText = search_text ? ("&search_text=" + encodeURI(search_text)) : "";
    var url = OBJECT_SERVER_DOMAIN + '/api/get/createdchallenge?uid=' + OBJECT_USER_ID + groupFilter + searchText + "&auth=" + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            onGetAcceptedChallengeComplete = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 403) {
                    result = onAccessDenied();
                } else if (status == 404) {
                    result = [];
                } else if (status != 200) {
                    result = t("ERROR_MESSAGE",true);
                } else {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetAcceptedChallengeComplete");
            } else {
                var data = Platform.makeHttpGetCall(url);
                return onGetAcceptedChallengeComplete($.parseJSON(data));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    } catch (e) {
        console.log("function : getCreatedChallenge"
            + "\n message:" + e.message);
    }
};

ChallengeService.prototype.getAttemptedChallenge = function (startTime, endTime, groups, callback) {
    var groupFilter = groups ? ("&groups=" + groups) : "";
    var url = OBJECT_SERVER_DOMAIN + '/api/get/attemptedchallenge?uid=' + OBJECT_USER_ID + '&start_time=' + startTime + '&end_time=' + endTime + groupFilter + '&auth=' + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            onGetAttemptedChallengeComplete = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 403) {
                    result = onAccessDenied();
                } else if (status == 404) {
                    result = [];
                } else if (status != 200) {
                    result = t("ERROR_MESSAGE",true);
                } else {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetAttemptedChallengeComplete");
            } else {
                var data = Platform.makeHttpGetCall(url);
                return onGetAttemptedChallengeComplete($.parseJSON(data));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    } catch (e) {
        console.log("function : getAttemptedChallenge"
            + "\n message:" + e.message);
    }
};

ChallengeService.prototype.getChallengeParticipants = function (quizId, callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/get/challengeparticipants?uid=' + OBJECT_USER_ID + '&quiz_id=' + quizId + '&auth=' + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            onGetChallengeParticipantsComplete = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 403) {
                    result = onAccessDenied();
                } else if (status == 404) {
                    result = [];
                } else if (status != 200) {
                    result = t("ERROR_MESSAGE",true);
                } else {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetChallengeParticipantsComplete");
            } else {
                var data = Platform.makeHttpGetCall(url);
                return onGetChallengeParticipantsComplete($.parseJSON(data));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    } catch (e) {
        console.log("function : getAttemptedChallenge"
            + "\n message:" + e.message);
    }
};

ChallengeService.prototype.assignChallengePoints = function (quizId, startTime, callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/assign/gamepoints?uid=' + OBJECT_USER_ID + '&quizid=' + quizId + '&start_time=' + startTime + '&auth=' + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            onAssignChallengePointsComplete = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 403) {
                    result = onAccessDenied();
                } else if (status == 401) {
                    result = INVALID_GAME_TYPE;
                } else if (status == 405) {
                    result = CHALLENGE_IN_PROGRESS;
                } else if (status != 200) {
                    result = t("ERROR_MESSAGE",true);
                } else {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onAssignChallengePointsComplete");
            } else {
                var data = Platform.makeHttpGetCall(url);
                return onAssignChallengePointsComplete($.parseJSON(data));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    } catch (e) {
        console.log("function : sssignChallengePoints"
            + "\n message:" + e.message);
    }
};
function BlogService() {
}
;

BlogService.prototype.getAllBlogs = function(json, page)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getallblog?page=' + page + "&auth=" + AUTH_ENABLED ;
             var postdata = encodeURIComponent(json);
             var blogs_data = Platform.makeHttpPostCall(url, postdata);
            blogs_data = $.parseJSON(blogs_data);
            var status = blogs_data['status'];
            var blogs_json = {};
            if (status == 200) {
                var blogsdata = blogs_data['data'];
                blogsdata = blogsdata.trim();
                if(blogsdata != ""){
                     blogsdata = $.parseJSON(blogsdata);
                }
                blogs_json[BLOGS] = blogsdata;
                return blogs_json;
            }
            else if(status == 403){
                return status;
            }
            else {
                return t("ERROR_MESSAGE",true);
            }
        }
        else {
            return t("INTERNET_ERROR",true);;
        }
    }
    catch (e) {
        console.log("function : getAllBlogs"
                + "\n message:" + e.message);
    }
};

BlogService.prototype.getblogsCommentData = function (json, callback)
{
    try {
        if (Platform.getNetworkState() == true) {
            var postdata = encodeURIComponent(json);
            var url = OBJECT_SERVER_DOMAIN + '/api/getblogcomments?auth=' + AUTH_ENABLED;
            onGetBlogsCommentData = function (blogcommentdata) {
                var status = blogcommentdata['status'];
                var comment_json = {};
                if (status == 200) {
                    var commentdata = blogcommentdata['data'];
                    commentdata = $.parseJSON(commentdata);
                    comment_json[BLOGS_WITH_COMMENTS] = commentdata;
                    result = comment_json;
                }
                else if(status == 403){
                    var data = this.onAccessDenied();
                    result = data;
                }
                else {
                    result = t("ERROR_MESSAGE",true);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onGetBlogsCommentData");
            } else {
                var data = Platform.makeHttpPostCall(url, postdata);
                return onGetBlogsCommentData($.parseJSON(data));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
    catch (e) {
        console.log("function : getCommentData"
                + "\n message:" + e.message);
    }
};


BlogService.prototype.createBlog = function(json)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/blog/create?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(json);
            var blogdata = Platform.makeHttpPostCall(url, postdata);
            blogdata = $.parseJSON(blogdata);
            var status = blogdata['status'];
            if (status == 200) {
                return "true";
            }
            else if (status == 400 || status == 205) {
                return t("LIMIT_OF_QUESTIONS",true);
            }
            else if(status == 403){
                return status;
            }
            else {
                return t("ERROR_MESSAGE",true);
            }
        }
        else {
            return t("INTERNET_ERROR",true);;
        }
    }
    catch (e) {
        console.log("function : postQuestion"
                + "\n message:" + e.message);
    }
};

BlogService.prototype.addblogComment = function(json, callback)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/update/blogcomment?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(json);
            onAddblogComment = function (commentdata) {
                var status = commentdata['status'];
                if (status == 200) {
                    result = "true";
                }
                else if(status == 403){
                    result = status;
                }
                else {
                    result = t("ERROR_MESSAGE",true);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            };
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onAddblogComment");
            } else {
                var data = Platform.makeHttpPostCall(url, postdata);
                return onAddblogComment($.parseJSON(data));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
    catch (e) {
        console.log("function : addComment"
                + "\n message:" + e.message);
    }
};


function TaskService() {
}
;

TaskService.prototype.getAllTasks = function(courseid)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/gettaskofuser?uid=' + OBJECT_USER_ID + "&cid=" + courseid + "&auth=" + AUTH_ENABLED;
            var postdata = encodeURIComponent(OBJECT_USER_ID);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            var task_json = {};
            if (status == 200) {
                var taskdata = user['data'];
                taskdata = $.parseJSON(taskdata);
                task_json[ALL_TASKS] = taskdata;
                return task_json;
            }
            else if(status == 403){
                return status;
            }
            else {
                alert(t("ERROR_MESSAGE",true));
                return;
            }
        }
        else {
           alert(t("INTERNET_ERROR",true))
           return;
        }
    }
    catch (e) {
        console.log("function : getAllTasks"
                + "\n message:" + e.message);
    }
};
TaskService.prototype.getAssignmentById = function(nid)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + "/api/assignment/" + nid + "/details" +"?auth=" + AUTH_ENABLED;
            var postdata = encodeURIComponent(OBJECT_USER_ID);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            var task_json = {};
            if (status == 200) {
                var taskdata = user['data'];
                taskdata = $.parseJSON(taskdata);
                task_json[ALL_TASKS] = taskdata;
                return task_json;
            }
            else if(status == 403){
                return status;
            }
            else {
                alert(t("ERROR_MESSAGE",true));
                return;
            }
        }
        else {
           alert(t("INTERNET_ERROR",true))
           return;
        }
    }
    catch (e) {
        console.log("function : getAllTasks"
                + "\n message:" + e.message);
    }
};

TaskService.prototype.getAllTasksonStatus = function(status, courseid, searchtext, callback)
{
    if(typeof searchtext!="undefined" && searchtext !=""){
        var searchparam = "&name=" +searchtext;
    }else{
        searchparam=""
    }
    var url = OBJECT_SERVER_DOMAIN + '/api/gettaskofuser?uid=' + OBJECT_USER_ID + '&status=' + status + "&cid=" + courseid +"&auth=" + AUTH_ENABLED + searchparam;
    try {
        if (Platform.getNetworkState() == true) {
            var postdata = encodeURIComponent(OBJECT_USER_ID);
            onGetAllTaskOnStatus = function(get_all_task){
                var result = '';
                var task_json = {};
                var status = get_all_task['status'];
                if (status == 200) {
                    var taskdata = get_all_task['data'];
                    taskdata = $.parseJSON(taskdata);
                    task_json[ALL_TASKS] = taskdata;
                    result = task_json;
                }
                else if(status == 403){
                    result = status;
                }
                else {
                    result = ERROR_MESSAGE;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onGetAllTaskOnStatus");
            } else {
                var user = Platform.makeHttpPostCall(url, postdata);
                return onGetAllTaskOnStatus($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
    catch (e) {
        console.log("function : getAllTasksonStatus"
                + "\n message:" + e.message);
    }
};

TaskService.prototype.updateTask = function(taskid)
{
    try {
        if (Platform.getNetworkState() == true) {
            
            var json = "{";
            var temp = "";
            temp = ' "uid" : "' + OBJECT_USER_ID + '",';
            temp = temp + ' "nid" : "' + taskid + '"';
            json = json + temp + "}";
            
            var url = OBJECT_SERVER_DOMAIN + '/api/task/update?&auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(json);
            var data = Platform.makeHttpPostCall(url, postdata);
            data = $.parseJSON(data);
            var status = data['status'];
            if (status == 200) {
                return 200;
            }
            else if(status == 403){
                return status;
            }
            else {
                alert(t("ERROR_MESSAGE",true));
                return;
            }
        }
        else {
            alert( t("INTERNET_ERROR",true));
                return;
        }
    }
    catch (e) {
        console.log("function : updateTask"
                + "\n message:" + e.message);
    }
};

TaskService.prototype.getAllMyActivities = function(groups, callback)
{
    if(typeof ENABLE_LOCALIZATION != "undefined" && ENABLE_LOCALIZATION == "true") {
        var langCode = Platform.getPreference("lang");
        var url = OBJECT_SERVER_DOMAIN + '/api/getactivitiesofuser?uid=' + OBJECT_USER_ID + '&groups=' + groups+'&lid='+langCode+"&auth=" + AUTH_ENABLED;
    }else{
        var url = OBJECT_SERVER_DOMAIN + '/api/getactivitiesofuser?uid=' + OBJECT_USER_ID + '&groups=' + groups+"&auth=" + AUTH_ENABLED;
    }
    try {
        if (Platform.getNetworkState() == true) {
            var postdata = encodeURIComponent(OBJECT_USER_ID);
            onGetAllMyActivities = function(get_all_activities){
                var result = '';
                var activities = '';
                var status = get_all_activities['status'];
                if (status == 200) {
                    activities = get_all_activities['data'];
                    activities = $.parseJSON(activities);
                    result = activities;
                }
                else if(status == 403){
                    result = status;
                }
                else {
                    result = ERROR_MESSAGE;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onGetAllMyActivities");
            } else {
                var user = Platform.makeHttpPostCall(url, postdata);
                return onGetAllMyActivities($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
    catch (e) {
        console.log("function : getAllMyActivities"
                + "\n message:" + e.message);
    }
};

TaskService.prototype.getTaskDataFromId = function(nid)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/task/' + nid  + '/details?auth=' + AUTH_ENABLED ;
            var taskdata =  Platform.makeHttpGetCall(url);
            taskdata = $.parseJSON(taskdata);
            var status = taskdata['status'];
            if (status == 200) {
                return taskdata['data'];
            }else {
                return ERROR_MESSAGE;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : getTaskDataFromId"
                + "\n message:" + e.message);
    }
};
function AskanExpertService() {
    this.samlLogin = Platform.getPreference('samlLogin');
    this.emailid = Platform.getPreference('emailid');
    this.user = CONTROLLER.getService("User");
    this.action = '';
}
;

AskanExpertService.prototype.getForumTopics = function(json, page, callback)
{
    var url = "";
    var THIS = this;
    var showLogs = CONTROLLER.getVariable("show_logs");
    if(typeof showLogs != 'undefined' && showLogs){
        url = OBJECT_SERVER_DOMAIN + '/api/get/userinteractionlogs?uid=' + OBJECT_USER_ID + '&page=' + page + "&auth=" + AUTH_ENABLED;
        json = {};
        json['uid'] = OBJECT_USER_ID;
    }else{
        url = OBJECT_SERVER_DOMAIN + '/api/getforumtopics?page=' + page + "&auth=" + AUTH_ENABLED;
    }
    try {
        if (Platform.getNetworkState() == true) {
            this.action = 'forum';
            if(CONTROLLER.getVariable('forum_activity') == 'true'){
                var assg_id =  CONTROLLER.getVariable('assg_id');
                json = JSON.parse(json);
                json.assg_id = assg_id;
                json = JSON.stringify(json);
            }
            var postdata = encodeURIComponent(json);
            onGetForumTopics = function (forumQuestionData) {
                THIS.action = 'forum';
                var result = '';
                var forum_topic_json = {};
                var status = forumQuestionData['status'];
                if (status == 200) {
                    var forumtopicdata = forumQuestionData['data'];
                    if(typeof forumtopicdata.trim() != 'undefined' && forumtopicdata.trim() != ''){
                        forumtopicdata = $.parseJSON(forumtopicdata);
                    }else{
                        forumtopicdata = "";
                    }
                    forum_topic_json[ASKANEXPERT_QUESTIONS] = forumtopicdata;
                    result = forum_topic_json;
                }
                else if (status == 403) {
                    var data = THIS.onForumAccessDenied(json, page);
                    result = data;
                }
                else {
                    result = t("ERROR_MESSAGE",true);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onGetForumTopics");
            } else {
                var user = Platform.makeHttpPostCall(url, postdata);
                return onGetForumTopics($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
    catch (e) {
        console.log("function : getForumTopics"
            + "\n message:" + e.message);
    }
};

AskanExpertService.prototype.getCommentData = function (nid, page, limit, callback) {
    var url = "";
    var showLogs = CONTROLLER.getVariable("show_logs");
    if(typeof showLogs != 'undefined' && showLogs){
        url = OBJECT_SERVER_DOMAIN + '/api/getinteractionlogfromid?nid=' + nid + "&auth=" + AUTH_ENABLED;
    } else {
        if (typeof page != "undefined") {
            page = "&page=" + page;
        }
        else {
            page = "";
        }
        if (typeof limit != "undefined") {
            limit = "&limit=" + limit;
        }
        else {
            limit = "";
        }
        url = OBJECT_SERVER_DOMAIN + '/api/getforumtopicfromid?nid=' + nid + "&auth=" + AUTH_ENABLED + '&uid=' + OBJECT_USER_ID + page + limit;
    }
    try {
        var postdata = encodeURIComponent(nid);
        if (Platform.getNetworkState() == true) {
            var THIS = this;
            onGetForumComment = function (forum_comment_data) {
                THIS.action = 'getcomment';
                var result = '';
                var forum_comment_json = {};
                var status = forum_comment_data['status'];
                if (status == 200) {
                    var forumcommentdata = forum_comment_data['data'];
                    forumcommentdata = $.parseJSON(forumcommentdata);
                    forum_comment_json[QUESTIONS_WITH_COMMENTS] = forumcommentdata;
                    result = forum_comment_json;
                }
                else if (status == 403) {
                    var data = THIS.onForumAccessDenied(json, page);
                    result = data;
                }
                else {
                    result = t("ERROR_MESSAGE",true);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onGetForumComment");
            } else {
                var user = Platform.makeHttpPostCall(url, postdata);
                return onGetForumComment($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
    catch (e) {
        console.log("function : getCommentData"
            + "\n message:" + e.message);
    }
};
AskanExpertService.prototype.getUserForumConfig = function (callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/get/userforum/config?uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            var THIS = this;
            onGetUserForumConfig = function (userForumConfig) {
                var result = '';
                var status = userForumConfig['status'];
                if (status == 200) {
                    var questioninfo = userForumConfig['data'];
                    questioninfo = $.parseJSON(questioninfo);
                    result = questioninfo;
                }
                else if (status == 403) {
                    var data = THIS.onForumAccessDenied();
                    result = data;
                }
                else {
                    result =  t("ERROR_MESSAGE",true);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetUserForumConfig");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return onGetUserForumConfig($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
    catch (e) {
        console.log("function : getUserForumConfig"
            + "\n message:" + e.message);
    }
};


AskanExpertService.prototype.postQuestion = function(json, callback)
{
    var url = "";
    var showLogs = CONTROLLER.getVariable("show_logs");
    if(typeof showLogs != 'undefined' && showLogs){
        url = OBJECT_SERVER_DOMAIN + '/api/update/interactionlog?auth=' + AUTH_ENABLED;
        json = $.parseJSON(json);
        json['type'] = 'interaction';
        json['log'] = json['forumtopic']
        json = JSON.stringify(json);
    }else{
        url = OBJECT_SERVER_DOMAIN + '/api/update/forumtopic?auth=' + AUTH_ENABLED;
    }
    try {
        if (Platform.getNetworkState() == true) {
            if(CONTROLLER.getVariable('forum_activity') == 'true'){
                var assg_id =  CONTROLLER.getVariable('assg_id');
                json = JSON.parse(json);
                json.assg_id = assg_id;
                json = JSON.stringify(json);
            }
            var postdata = encodeURIComponent(json);
            if (typeof ALLOWED_NO_OF_QUESTIONS != "undefined") {
                LIMIT_OF_QUESTIONS = "Only " + ALLOWED_NO_OF_QUESTIONS + " question(s) can be asked per day.";
            }
            var THIS = this;
            onPostQuestion = function (questiondata) {
                THIS.action = 'postquestion';
                var result = '';
                var status = questiondata['status'];
                if (status == 200) {
                    result = "true";
                }else if(typeof showLogs != 'undefined' && showLogs && status == 401){
                    result =  SUBSCRIPTION_MESSAGE_ASK_A_DOUBT;
                }else if(typeof showLogs != 'undefined' && showLogs && status == 405){
                    result =  ASK_A_DOUBT_QUESTIONS_LIMIT;
                }
                else if (status == 400 || status == 205 || status == 401) {
                    result = LIMIT_OF_QUESTIONS;
                }
                else if (status == 403) {
                    var data = THIS.onForumAccessDenied(json);
                    result = data;
                }
                else {
                    result = t("ERROR_MESSAGE",true);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onPostQuestion");
            } else {
                var user = Platform.makeHttpPostCall(url, postdata);
                return onPostQuestion($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
    catch (e) {
        console.log("function : postQuestion"
            + "\n message:" + e.message);
    }
};

AskanExpertService.prototype.addComment = function(json,callback)
{
    var url = "";
    var showLogs = CONTROLLER.getVariable("show_logs");
    if(typeof showLogs != 'undefined' && showLogs){
        url = OBJECT_SERVER_DOMAIN + '/api/update/interactionlogcomment?auth=' + AUTH_ENABLED;
    }else{
        url = OBJECT_SERVER_DOMAIN + '/api/update/forumcomment?auth=' + AUTH_ENABLED;
    }
    try {
        if (Platform.getNetworkState() == true) {
            var postdata = encodeURIComponent(json);
            var THIS = this;
            onAddComment = function (commentData) {
                THIS.action = 'addcomment'
                var result = '';
                var status = commentData['status'];
                if (status == 200) {
                    result = "true";
                }
                else if (status == 403) {
                    var data = THIS.onForumAccessDenied(json);
                    result = data;
                }
                else {
                    result = ERROR_MESSAGE;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onAddComment");
            } else {
                var user = Platform.makeHttpPostCall(url, postdata);
                return onAddComment($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
    catch (e) {
        console.log("function : addComment"
            + "\n message:" + e.message);
    }
};

AskanExpertService.prototype.onForumAccessDenied = function (json, page, nid) {
    try {
        if (typeof this.samlLogin != 'undefined' && this.samlLogin == 'true') {
            var status = this.user.loginRedirect(this.emailid);
            if (typeof this.action != '' && this.action == 'forum') {
                var data = this.getForumTopics(json, page);
            }
            else if (typeof this.action != '' && this.action == 'getcomment') {
                var data = this.getCommentData(nid);
            }
            else if (typeof this.action != '' && this.action == 'addcomment') {
                var data = this.addComment(json);
            }
            else if (typeof this.action != '' && this.action == 'postquestion') {
                var data = this.postQuestion(json);
            }
        }
        else {
            data = 403;
        }
        return data;
    }
    catch (e) {
        console.log("function : onForumAccessDenied"
            + "\n message:" + e.message);
    }
};



function BookmarksService() {

};


BookmarksService.prototype.getAllBookmarks =  function(params,callback){
    var url = OBJECT_SERVER_DOMAIN + '/api/getuserbookmarks?uid=' + params.uid + '&auth=' + AUTH_ENABLED;
    try{
        if (Platform.getNetworkState() == true) {

            var json = "{";
            var temp = "";
            temp = ' "uid" : "' + params.uid + '"';
            json = json + temp + "}";
            
            var postdata = encodeURIComponent(json);
            onGetAllBookmarks = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200)
                {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onGetAllBookmarks");
            } else {
                var user = Platform.makeHttpPostCall(url, postdata);
                return onGetAllBookmarks($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
   
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/onGetAllBookmarks '
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}   


BookmarksService.prototype.getAllGlobalBookmarks =  function(params,callback){
    var url = OBJECT_SERVER_DOMAIN + '/api/get/all/global/bookmarks' + '&auth=' + AUTH_ENABLED;
    try{
        if (Platform.getNetworkState() == true) {

            var json = "{";
            var temp = "";
            temp = ' "uid" : "' + params.uid + '"';
            json = json + temp + "}";
            
            var postdata = encodeURIComponent(json);
            onGetAllGlobalBookmarks = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200)
                {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onGetAllGlobalBookmarks");
            } else {
                var user = Platform.makeHttpPostCall(url, postdata);
                return onGetAllGlobalBookmarks($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
   
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/onGetAllGlobalBookmarks '
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}   


BookmarksService.prototype.updateBookmark =  function(params,callback){
    var url = OBJECT_SERVER_DOMAIN + '/api/update/userbookmarks?' + '&auth=' + AUTH_ENABLED;
    try{
        if (Platform.getNetworkState() == true) {

            if(params.type == 'quiz'){
                if(params.action == 'add'){
                    var json = "{";
                    var temp = "";
                    temp = ' "uid" : "' + OBJECT_USER_ID + '",';
                    temp = temp + ' "quiz_nid" : "' + params.quiz_nid + '",';
                    temp = temp + ' "nid" : "' + params.question_nid + '",';
                    temp = temp + ' "global_pin" : "' + params.global_pin + '",';
                    temp = temp + ' "category_id" : "' + params.category_id + '",';
                    temp = temp + ' "course_id" : "' + params.course_id + '",';
                    temp = temp + ' "chapter_id" : "' + params.chapter_id + '",';
                    temp = temp + ' "title" : "' + params.quiz_title + '",';
                    temp = temp + ' "op" : "' + params.action + '"';
                    json = json + temp + "}";
                }else if(params.action == 'delete'){
                    var json = "{";
                    var temp = "";
                    temp = ' "uid" : "' + OBJECT_USER_ID + '",';
                    temp = temp + ' "nid" : "' + params.question_nid + '",';
                    temp = temp + ' "favourite_id" : "' + params.bookmarked_quiz_nid + '",';
                    temp = temp + ' "title" : "' + params.quiz_title + '",';
                    temp = temp + ' "op" : "' + params.action + '"';
                    json = json + temp + "}";
                }
            }else{
                if(params.action == 'add'){
                    var json = "{";
                    var temp = "";
                    temp = ' "uid" : "' + OBJECT_USER_ID + '",';
                    temp = temp + ' "nid" : "' + params.nid + '",';
                    temp = temp + ' "global_pin" : "' + params.global_pin + '",';
                    temp = temp + ' "category_id" : "' + params.category_id + '",';
                    temp = temp + ' "course_id" : "' + params.course_id + '",';
                    temp = temp + ' "chapter_id" : "' + params.chapter_id + '",';
                    temp = temp + ' "duration" : "' + params.duration + '",';
                    temp = temp + ' "title" : "' + params.title + '",';
                    temp = temp + ' "op" : "' + params.action + '"';
                    json = json + temp + "}";
                }
                else if(params.action == 'delete'){
                    var json = "{";
                    var temp = "";
                    temp = ' "uid" : "' + OBJECT_USER_ID + '",';
                    temp = temp + ' "nid" : "' + params.nid + '",';
                    temp = temp + ' "favourite_id" : "' + params.bookmark_id + '",';
                    temp = temp + ' "op" : "' + params.action + '"';
                    json = json + temp + "}";
                }
                else if(params.action == 'update'){
                    var json = "{";
                    var temp = "";
                    temp = ' "uid" : "' + OBJECT_USER_ID + '",';
                    temp = temp + ' "title" : "' + params.title + '",';
                    temp = temp + ' "favourite_id" : "' + params.bookmark_id + '",';
                    temp = temp + ' "op" : "' + params.action + '"';
                    json = json + temp + "}";
                }
            }
            var postdata = encodeURIComponent(json);
            onUpdateBookmark = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200)
                {
                    var data = 'success';
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onUpdateBookmark");
            } else {
                var user = Platform.makeHttpPostCall(url, postdata);
                return onUpdateBookmark($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/onUpdateBookmark '
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}   

BookmarksService.prototype.getQuizFavorites = function(params){
    var quiz_nid = params.quiz_nid;
    var url = OBJECT_SERVER_DOMAIN + GET_USER_QUESTIONS + OBJECT_USER_ID + '&type=myfavorites' + '&quiz_nid=' + quiz_nid + '&auth=' + AUTH_ENABLED;
    try{
        if (Platform.getNetworkState() == true) {

            var json = "{";
            var temp = "";
            temp = ' "uid" : "' + OBJECT_USER_ID+ '"';
            json = json + temp + "}";
            
            var postdata = encodeURIComponent(json);
            onGetQuizFavorites = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200)
                {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onGetQuizFavorites");
            } else {
                var user = Platform.makeHttpPostCall(url, postdata);
                return onGetQuizFavorites($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }
   
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/ongetQuizFavorites '
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}
function AttendanceService() {

};

AttendanceService.prototype.postAttendance = function(nid,status, callback){
    var url = OBJECT_SERVER_DOMAIN + "/api/mark/classroomsession/attendance"+ "?auth=" + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            json = {};
            json['uid'] = OBJECT_USER_ID;
            json['nid'] = nid;
            json['status'] =status;
            json = JSON.stringify(json);
            var postdata = encodeURIComponent(json);
            postAttendance = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200) {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                } else if (status == 403) {
                    onAccessDenied();
                } else {
                    result = t("ERROR_MESSAGE",true);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "postAttendance");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return postAttendance($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
   
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/mark/classroomsession/'+ start + '&end=' + end
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}   

AttendanceService.prototype.getClassroomSessions = function(startdate,enddate, callback){
    var url = OBJECT_SERVER_DOMAIN + "/api/get/user/classroomsessions?uid="+ OBJECT_USER_ID +"&startdate=" + startdate +"&enddate=" + enddate +"&auth=" + AUTH_ENABLED + "&nocache=" + true;
    try {
        if (Platform.getNetworkState() == true) {
            getClassroomSessions = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200) {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                } else if (status == 403) {
                    onAccessDenied();
                } else {
                    result = t("ERROR_MESSAGE",true);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "post", "false", "getClassroomSessions");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return getClassroomSessions($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
   
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get/user/classroomsessions '+ start + '&end=' + end
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}   

AttendanceService.prototype.getClassroomSessionDetails = function(nid, callback){
    var url = OBJECT_SERVER_DOMAIN + "/api/get/classroomsessiondetails?uid="+OBJECT_USER_ID +"&nid=" + nid + "&auth=" + AUTH_ENABLED;
    try {
        if (Platform.getNetworkState() == true) {
            getClassroomSessionDetails = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200) {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                } else if (status == 403) {
                    onAccessDenied();
                } else {
                    result = t("ERROR_MESSAGE",true);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "getClassroomSessionDetails");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return getClassroomSessionDetails($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(t("INTERNET_ERROR",true));
            } else {
                return t("INTERNET_ERROR",true);
            }
        }
    }
   
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get/classroomsessiondetails'+ start + '&end=' + end
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}   
